import appConfig from "../config";
//about team members
import aboutAmit from "../components/AboutOurTeam/AmitAg";
import aboutAmitMun from "../components/AboutOurTeam/AmitMun";
import aboutAnup from "../components/AboutOurTeam/AnupSingh";
import aboutAtul from "../components/AboutOurTeam/AtulChh";
import aboutAmitKh from "../components/AboutOurTeam/AmitKh";
import blogPng from "../static/images/homepage/Blog.svg";
import pressnmedia from "../static/images/homepage/Vector.svg"; 
//header
export const partnerWithUsRoute = { link: `${appConfig.route[appConfig.environment]}/partnerwithus`, target: "_self" };
export const createYourTradeRoute = { link: `${appConfig.route[appConfig.environment]}/createtrade`, target: "_self" };
export const registerRoute = { link: `${appConfig.route[appConfig.environment]}/login`, target: "_blank" };
export const loginBtnRoute = { link: `${appConfig.route[appConfig.environment]}/login`, target: "" };
export const farmerScoreCardloginBtnRoute = { link: `${appConfig.farmerScoreCardRoute[appConfig.environment]}`, target: "" };

const inputMarketplaceNewRouteUrl = appConfig.marketPlace[appConfig.environment];
const pvtAgribazaarRoutes=appConfig.pvtAgribazaar[appConfig.environment];

export const aboutAgribazaarNavigation = [
    { name: "Founder's Message", href: "/founder-message", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/navbar/foundersMessage.svg" },
    { name: "Our Team", href: "/our-team", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/navbar/ourTeam.svg" },
    { name: "Our Vision", href: "/our-vision", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/navbar/ourVision.svg" },
    { name: "Our Story", href: "/our-story", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/navbar/ourStory.svg" },
];
export const ourProductsNavigation = [
    { name: "AgriBhumi", href: "/our-products/agribhumi", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/agribhumi.svg" },
    {
        name: "Input Marketplace",
        href: inputMarketplaceNewRouteUrl,
        imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/input-marketplace.svg"
    },
    { name: "Online Auction Engine", href: "/our-products/online-auction-engine", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/auction-engine.svg" },
    { name: "e-Mandi", href: "/our-products/e-mandi", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/e-mandi.svg" },
    { name: "AgriKnow (GAP)", href: "/our-products/agriknow", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/agriknow.svg" },
    { name: "AgriPay", href: "/our-products/agripay", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/agripay.svg" },
    { name: "Marketplace", href: "/our-products/marketplace", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/marketplace.svg" },
    { name: "Kisan Safalta Card", href: "/our-products/kisan-credit-card", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/kcc.svg" },
];
export const ourServicesNavigation = [
    { name: "IoT Based Farm Management", href: "/our-services/iot-based-farm-management", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageServices/farmManagement.svg" },
    { name: "Crop Assessment using AI, ML, GIS", href: "/our-services/crop-assessment", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageServices/cropAssesment.svg" },
    { name: "Intelligent Goods & Settlement Services", href: "/our-services/goods-and-settlement", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageServices/intelligentGoods.svg" },
    { name: "Input & Output Marketplace", href: "/our-services/input-output-marketplace", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageServices/inputOutputMarketplace.svg" },
    { name: "Agri Fintech Services", href: "/our-services/agri-fintech-services", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageServices/agriFintech.svg" },
    { name: "Farmer Scorecard Services", href: "/our-services/farmer-scorecard-service", imgSrc: "https://abazaar.s3.ap-south-1.amazonaws.com/images/ourServices/farm_report_requests.svg" }
];
export const InsightsNavigation = [
    { name: "Blog", href: `${appConfig.blog[appConfig.environment]}/category/blog/`, imgSrc: blogPng },
    { name: "Press & Media", href: `${appConfig.blog[appConfig.environment]}/category/press-release/`, imgSrc: pressnmedia },
]
export const staticNavigation = [
    {
        name: "Careers",
        // href: "/careers/career-opportunities"
        href: `${appConfig.route[appConfig.environment]}/careeropportunities`
    },
    {
        name: "Trades",
        // href: "/careers/career-opportunities"
        href: `${appConfig.route[appConfig.environment]}/searchresult`
    },
    {
        name: "Contact us",
        href: "/contact-us"
    },
];
//home page
export const backgroundSVGs = {
    "ourServicesBG": "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageServices/ourServices-bg.svg",
    "whatPeopleSayAboutAgribazaarBG": "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/peopleSayAboutAgribazaar-bg.svg",
    "theBazaarStoriesBG": "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageBazaarStories/theBazaarStories-bg.png"
};
export const productList = [
    {
        imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/input-marketplace.svg",
        whiteImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/input-marketplace-white.svg",
        name: "Input Marketplace",
        content: "500+ best quality farm inputs to support India grow better, faster and healthier!",
        prodImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProducts/inputMarketplace.svg",
        route: inputMarketplaceNewRouteUrl,
        blackSvg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProductsBlack/input-marketplace_blk.svg",
    },
    {
        imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/agribhumi.svg",
        whiteImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/agribhumi-white.svg",
        name: "AgriBhumi",
        content: "Power of Satellite Imagery and Machine Learning taking Indian Agriculture ecosystem to new frontiers.",
        prodImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProducts/agribhumi.svg",
        route: "our-products/agribhumi",
        blackSvg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProductsBlack/agribhumi_blk.svg"
    },
    {
        imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/auction-engine.svg",
        whiteImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/auction-engine-white.svg",
        name: "Online Auction Engine",
        content: "State-of-art online auction mechanism that gained trust of 2 lakh + traders, FPOs, corporates!",
        prodImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProducts/onlineAuction.svg",
        route: "our-products/online-auction-engine",
        blackSvg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProductsBlack/auction-engine_blk.svg"
    },
    {
        imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/e-mandi.svg",
        whiteImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/e-mandi-white.svg",
        name: "e-Mandi",
        content: "10,000+ Happy Farmers getting the best prices for their produce.",
        prodImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProducts/eMandi.svg",
        route: "our-products/e-mandi",
        blackSvg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProductsBlack/e-mandi_blk.svg"
    },
    {
        imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/agriknow.svg",
        whiteImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/agriknow-white.svg",
        name: "AgriKnow (GAP)",
        content: "India’s emerging crop advisory platform for 700+ agri commodities across country!",
        prodImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProducts/agriknow.svg",
        route: "our-products/agriknow",
        blackSvg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProductsBlack/agriknow_blk.svg"
    },
    {
        imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/agripay.svg",
        whiteImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/agripay-white.svg",
        name: "AgriPay",
        content: "Transaction worth $1 billion USD! Our award-winning payment mechanism.",
        prodImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProducts/agripay.svg",
        route: "our-products/agripay",
        blackSvg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProductsBlack/agripay_blk.svg"
    },
    {
        imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/marketplace.svg",
        whiteImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/marketplace-white.svg",
        name: "Marketplace",
        content: "With 1,00,000+ onboard users, we are India’s leading e-marketplace.",
        prodImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProducts/marketplace.svg",
        route: "our-products/marketplace",
        pvtAgribazaarRoutes: pvtAgribazaarRoutes,
        blackSvg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProductsBlack/marketplace_blk.svg"
    },
    {
        imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/kcc.svg",
        whiteImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/kcc-white.svg",
        name: "Kisan Safalta Card",
        content: "Kisan Safalta Card by agribazaar. A simple way to make your farming easier and better.",
        prodImgSrc: "https://abazaar.s3.ap-south-1.amazonaws.com/images/homepageProducts/Group+13798.png",
        route: "our-products/kisan-credit-card",
        blackSvg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProductsBlack/kisanCC_blk.svg"
    },
];
export const servicesList = [
    {
        // imgSrc: ,
        name: "IoT Based Farm Management",
        content: "An easy-to-use farm management platform that assists agricultural stakeholders across the value chain.",
        route: "our-services/iot-based-farm-management",
    },
    {
        // imgSrc: ,/
        name: "Crop Assessment using AI, ML, GIS",
        content: "Transform your plantation with effective crop-level insights using the strength of AI, ML & so much more!",
        route: "our-services/crop-assessment",
    },
    {
        // imgSrc: ,
        name: "Intelligent goods & settlement services",
        content: "Smooth trading with simple & secure payments and dispute-free settlement mechanism.",
        route: "our-services/goods-and-settlement",
    },
    {
        // imgSrc: ,
        name: "Input & Output marketplace",
        content: "One-stop solution to buy inputs. Trade your quality crops at the best prices.",
        route: "our-services/input-output-marketplace",
    },
    {
        // imgSrc: ,
        name: "Agri fintech services",
        content: "Envisioning a world free of financial burden by democratizing lending credits for farmers.",
        route: "our-services/agri-fintech-services",
    },
];
export const testimonials = [
    {
        vidUrl: "https://youtu.be/LyLRlPM1ZEE",
        subHead: `“agribazaar has kept me updated with live fluctuations in mandi price and all current business updates.”`,
        name: " Suneel Kumar Agarwal",
        desig: "Trader"
    },
    {
        vidUrl: "https://youtu.be/BEHwbxmYfD8",
        subHead: `“Even during the pandemic, agribazaar has been working great and provided me convenience and ease in trading.”`,
        name: "Kamlesh Kumar Bansal",
        desig: "Trader"
    },
    {
        vidUrl: "https://youtu.be/zIzcxLyABaQ",
        subHead: `“agribazaar team has managed timely calls and ZOOM meetings to help me grow my business.”`,
        name: "Sanath Kumar Jain",
        desig: "Trader"
    }
];
export const bazaarStories = [
    {
        title: "Traceability and transparency are as important in the agri supply chain as in the food chain!",
        content: "Are you also becoming more and more conscious about the origins and quality of your food? To tell you the truth, the demand for food traceability and transparency has never been higher.",
        date: "February 16, 2024",
        route: `${appConfig.blog[appConfig.environment]}/traceability-and-transparency-are-as-important-in-the-agri-supply-chain-as-in-the-food-chain/`
    },
    {
        title: "Did you know about Agricultural Robots in India?",
        content: "The Indian agricultural equipment market size reached INR 1,129.6 Bn in 2023. Looking forward, IMARC Group expects the market to reach INR 2,527.4 Billion by 2032, exhibiting a growth rate (CAGR) of 9.1% during 2024-2032.",
        date: "January 31, 2024",
        route: `${appConfig.blog[appConfig.environment]}/did-you-know-about-agricultural-robots-in-india/`
    },
    {
        title: "Agri finance in India: An evolving sector",
        content: "Credit growth in agriculture and allied activities improved to 16.6%  annually in August 2023, and the loan outstanding neared Rs 18 lakh crore, revealed the latest Reserve Bank data.",
        date: "January 17, 2024",
        route: `${appConfig.blog[appConfig.environment]}/agri-finance-in-india-an-evolving-sector/`
    },
];
export const ourTeamLinks = [
    {
        title: "Our Team",
        href: "/our-team",
        target: "_self"
    },
    {
        // title: "Press Release",
        title: "Farm Management",
        // href: "/press-release",
        href: `${appConfig.blog[appConfig.environment]}/category/farm-management/`,
        target: "_blank"
    },
    {
        title: "Commodity Outlook",
        // href: "/commodity-outlook",
        href: `${appConfig.blog[appConfig.environment]}/category/commodity-outlook/`,
        target: "_blank"
    },
    // {
    //     title: "Farm Management",
    //     href: "https://blog.agribazaar.com/category/farm-management/",
    //     target: "_blank"
    // },
    {
        title: "Newsletters",
        // href: "/newsletters",
        href: `${appConfig.blog[appConfig.environment]}/category/agritalk/`,
        target: "_blank"
    },
];
export const ourTeamList = [
    {
        imgUrl: "https://abazaar.s3.ap-south-1.amazonaws.com/images/homepageTeam/AmitMundawala.png",
        name: "Amit Goyal",
        desig: "Co-Founder & Managing Director",
        text: aboutAmitMun,
        linkedInUrl: "https://www.linkedin.com/in/amit-mundawala-977b304b/"
    },
    {
        imgUrl: "https://abazaar.s3.ap-south-1.amazonaws.com/images/homepageTeam/Amith_Agarwal.png",
        name: "Amith Agarwal",
        desig: "Co-Founder & CEO",
        text: aboutAmit,
        linkedInUrl: "https://www.linkedin.com/in/amith-agarwal-ab19848/"
    },
    {
        imgUrl: "https://abazaar.s3.ap-south-1.amazonaws.com/images/homepageTeam/Amit_khandelwal.png",
        name: "Amit Khandelwal",
        desig: "Co-Founder & Director",
        text: aboutAmitKh,
        linkedInUrl: "https://in.linkedin.com/in/amit-khandelwal-75580823"
    },
    {
        imgUrl: "https://abazaar.s3.ap-south-1.amazonaws.com/images/homepageTeam/Anup_Singh.png",
        name: "Anup Singh",
        desig: "Chief Product & Technology Officer",
        text: aboutAnup,
        linkedInUrl: "https://www.linkedin.com/in/anup-singh-7193941/"
    },
    {
        imgUrl: "https://abazaar.s3.ap-south-1.amazonaws.com/images/homepageTeam/Atul_Chhura.png",
        name: "Atul Chhura",
        desig: "Chief Business Officer",
        text: aboutAtul,
        linkedInUrl: "https://www.linkedin.com/in/atulchhura"
    }
];
export const partners = [
    "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepagePartners/nafed.webp", 
    "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepagePartners/britannia.webp", 
    "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepagePartners/adani.webp", 
    "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepagePartners/kirti.webp", 
    "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepagePartners/cargill.webp"
];
export const theBazaarStoriesViewMoreBtnRoute = `${appConfig.blog[appConfig.environment]}/category/blog/`;
//footer
export const downloadApp = [
    {
        imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/footer/google-play.svg",
        route: "https://play.google.com/store/apps/details?id=com.agribazaarapp"
    },
    {
        imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/footer/app-store.svg",
        route: "https://apps.apple.com/in/app/agribazaar/id1589192238"
    },
];
export const connectWithUs = [
    {
        imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/footer/facebook.webp",
        route: "https://www.facebook.com/agribazaar.official/"
    },
    {
        imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/footer/linkedin.webp",
        route: "https://www.linkedin.com/company/25067493/"
    },
    {
        imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/footer/twitter.webp",
        route: "https://twitter.com/AgribazaarA"
    },
    {
        imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/footer/youtube.webp",
        route: "https://www.youtube.com/channel/UCu0K5EmaRgZfT0ZW55s8NdA/videos"
    },
    {
        imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/footer/instagram.png",
        route: "https://www.instagram.com/agribazaar?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
    },
];
export const quickLinks = [
    {
        name: "Steps",
        link: "Steps",
        href: "/quick-links/steps",
        // href: `${appConfig.route[appConfig.environment]}/downloadappsteps`
        target: "_self"
    },
    {
        name: "Press & Media",
        link: "Press & Media",
        // href: "/quick-links/press-and-media",
        href: `${appConfig.blog[appConfig.environment]}/category/press-release/`,
        target: "_blank"
    },
    {
        name: "FAQs",
        link: "FAQs",
        href: "/quick-links/faq",
        // href: `${appConfig.route[appConfig.environment]}/faq`,
        target: "_self"
    },
    {
        name: "Terms of Use",
        link: "Terms of Use",
        href: "/quick-links/terms-of-use",
        // href: `${appConfig.route[appConfig.environment]}/terms`,
        target: "_self"
    },
    {
        name: "SaaS agreement",
        link: "SaaS agreement",
        href: "https://agribazarpro.s3-ap-southeast-1.amazonaws.com/agribazaar+-+SaaS+-+final+-+21+Oct+2020.pdf",
        target: "_blank"
    },
];
export const agriInsights = [
    {
        name: "Blog",
        link: "Blog",
        // href: "/agri-insights/blog",
        href: `${appConfig.blog[appConfig.environment]}/category/blog/`,
        target: "_blank"
    },
    {
        name: "agriTalk Magazine",
        link: "agriTalk Magazine",
        // href: "/agri-insights/agriTalk",
        href: `${appConfig.blog[appConfig.environment]}/category/agritalk/`,
        target: "_blank"
    },
    {
        name: "agri Insights",
        link: "agri Insights",
        // href: "/agri-insights/agriInsights",
        href: "https://blog.agribazaar.com/category/commodity-outlook/",
        target: "_blank"
    },
    {
        name: "Sale of Nafed PSS Stock",
        link: "Sale of Nafed PSS Stock",
        // href: "/agri-insights/sale-of-nafed-pss",
        href: `${appConfig.blog[appConfig.environment]}/category/sale-of-nafed-pss-stock/`,
        target: "_blank"
    },
];
export const careers = [
    {
        name: "Why agribazaar",
        link: "Why agribazaar",
        href: "/careers/why-agribazaar",
        // href: `${appConfig.route[appConfig.environment]}/whyagribazaar`,
        target: "_self"
    },
    {
        name: "People Philosophy",
        link: "People Philosophy",
        href: "/careers/people-philosophy",
        // href: `${appConfig.route[appConfig.environment]}/peoplephilosophy`,
        target: "_self"
    },
    {
        name: "Career Opportunities",
        link: "Career Opportunities",
        // href: "/careers/career-opportunities",
        href: `${appConfig.route[appConfig.environment]}/careeropportunities`,
        target: "_self"
    },
    {
        name: "Join Us",
        link: "Join Us",
        href: "/contact-us",
        target: "_self"
        // href: `${appConfig.route[appConfig.environment]}/careeropportunities`
    }
];
export const footerNavs = [
    {
        title: "QUICK LINKS",
        arrLink: quickLinks
    },
    {
        title: "AGRI INSIGHTS",
        arrLink: agriInsights
    },
    {
        title: "CAREERS",
        arrLink: careers
    },
];
export const contactUsList = {
    mumbaiOffcImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/contactUs/Mumbai-office.png",
    delhiOfficeImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/contactUs/Delhi-office.png"
};
//blogs and articles