import React from 'react'

const CircleRectCard = ({
    icon,
    head = "",
    description = ""
}) => {
    return (
        <div id="parent" className='pl-12 sm:pl-0 px-4 py-2 flex flex-row' style={{ display: "block" }}>
            <div className='py-2 bg-[#DEF7E5] w-100 h-auto sm:h-28'>
                <div className='py-4 bg-[#B4E4C1] absolute w-16 sm:w-24 h-16 sm:h-24 flex justify-center -ml-8 sm:-ml-12' style={{ borderRadius: "50%"}}>
                    <img src={icon} alt="icon" width={55} />
                </div>
                <div class="pl-8 sm:pl-16 px-4 flex flex-col sm:flex-row gap-6 list-disc list-inside marker:text-[#4165B3] text-base leading-loose list-none"
                    style={{ height: "100%" }}>
                    <li class="text-xs sm:text-base m-auto">
                        {head && <span class="font-bold">{head}:</span>}
                        <br /> {description && description}
                    </li>
                </div>
            </div>
        </div>
    )
}

export default CircleRectCard