const aboutAmitKh = () => {
    return (
        <div>
            <div>
            Amit Khandelwal, Co-Founder and Director at agribazaar is a pragmatic agri-tech leader. He is responsible for business growth & streamlining all processes and risk functions, and translating strategies into high-performance business action. 
            </div>
            <div>
            Amit leads performance management and nationwide & international business growth for the company. With over two decades of experience in agriculture and commodities, he's pioneered custom products for farmers to increase their profitability.
            </div>
            <div>
            His visionary leadership has made agribazaar a beacon among Indian agri-tech start-ups, fostering agriculture transparency and prosperity, bridging urban-rural gaps, and inspiring sustainable innovation with a digital-first approach.
            </div>

        </div>
    )
}

export default aboutAmitKh();