import React, { useState } from "react";
import { Disclosure, Popover } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import Agrilogo from "../static/icons/agriHeadLogo.svg";
import { ChevronUpIcon } from '@heroicons/react/solid'
import Partner from "./Partner";
import { loginBtnRoute, aboutAgribazaarNavigation, ourProductsNavigation, ourServicesNavigation, staticNavigation, InsightsNavigation } from "./Constants";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const divider = () => {
  return (
    <div className="relative flex py-1 items-center">
      <div className="flex-grow border-t border-gray-400"></div>
      <div className="flex-grow border-t border-gray-400"></div>
    </div>
  )
}
const dividerLight = () => {
  return (
    <div className="relative flex py-1 items-center">
      <div className="flex-grow border-t border-gray-300"></div>
      <div className="flex-grow border-t border-gray-300"></div>
    </div>
  )
}
const renderMobileNavbar = () => {
  return (
    <Disclosure.Panel className="top-14 overflow-y-scroll fixed w-[80%] h-[95vh] right-0 rounded-l-sm sm:hidden bg-[#f5f5f5] z-10 transform px-2 max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2 shadow-2xl drop-shadow-2xl ring-1 ring-black ring-opacity-5">
      <div className="grid px-2 pt-2 pb-3 space-y-1">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="text-[#3B3939] flex justify-between w-full px-4 py-2 text-lg font-medium text-left rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
                <span>About agribazaar</span>
                <ChevronUpIcon
                  className={`${!open ? 'transform rotate-180' : ''
                    } w-5 h-5`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-1 pb-2 text-sm text-gray-800">
                {aboutAgribazaarNavigation.map((item, index) => (
                  <Disclosure.Button
                    key={`_${item.name}__${index}`}
                    as="a"
                    href={item.href}
                    className={classNames(
                      "text-[#3B3939]-300  hover:cursor-pointer",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                  >
                    {item.name}
                    {index < aboutAgribazaarNavigation.length - 1 && dividerLight()}
                  </Disclosure.Button>
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        {divider()}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="text-[#3B3939] flex justify-between w-full px-4 py-2 text-lg font-medium text-left rounded-lg  focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
                <span>Our Products</span>
                <ChevronUpIcon
                  className={`${!open ? 'transform rotate-180' : ''
                    } w-5 h-5`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-1 pb-2 text-sm text-gray-800">
                {ourProductsNavigation.map((item, index) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      "text-[#3B3939]-300  hover:cursor-pointer",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                  >
                    {item.name}
                    {index < ourProductsNavigation.length - 1 && dividerLight()}
                  </Disclosure.Button>
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        {divider()}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="text-[#3B3939] flex justify-between w-full px-4 py-2 text-lg font-medium text-left rounded-lg  focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
                <span>Our Services</span>
                <ChevronUpIcon
                  className={`${!open ? 'transform rotate-180' : ''
                    } w-5 h-5`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-1 pb-2 text-sm text-gray-800">
                {ourServicesNavigation.map((item, index) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      "text-[#3B3939]-300  hover:cursor-pointer",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                  >
                    {item.name}
                    {index < ourServicesNavigation.length - 1 && dividerLight()}
                  </Disclosure.Button>
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        {divider()}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="text-[#3B3939] flex justify-between w-full px-4 py-2 text-lg font-medium text-left rounded-lg  focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
                <span>Insights</span>
                <ChevronUpIcon
                  className={`${!open ? 'transform rotate-180' : ''
                    } w-5 h-5`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-1 pb-2 text-sm text-gray-800">
                {InsightsNavigation.map((item, index) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      "text-[#3B3939]-300  hover:cursor-pointer",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                  >
                    {item.name}
                    {index < InsightsNavigation.length - 1 && dividerLight()}
                  </Disclosure.Button>
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        {divider()}
        <Disclosure.Button
          as="a"
          href={staticNavigation[0].href}
          className={classNames(
            "text-[#3B3939] pt-16 hover:cursor-pointer",
            "block px-3 py-2 rounded-md text-lg font-medium"
          )}
        >
          {staticNavigation[0].name}
        </Disclosure.Button>
        {divider()}
        <Disclosure.Button
          as="a"
          href={staticNavigation[1].href}
          className={classNames(
            "text-[#3B3939] hover:cursor-pointer",
            "block px-3 py-2 rounded-md text-lg font-medium"
          )}
        >
          {staticNavigation[1].name}
        </Disclosure.Button>
      </div>
    </Disclosure.Panel>
  )
}
export default function Navbar() {
  const [showAbout, setshowAbout] = useState(false);
  const [showProducts, setshowProducts] = useState(false);
  const [showServices, setshowServices] = useState(false);
  const [showInsights, setshowInsights] = useState(false);

  const renderAboutPopover = () => {
    return (
      <Popover className="relative">
        <Popover.Button className={classNames(
          "text-[#3B3939] ",
          "px-3 py-2 rounded-md text-md font-medium"
        )}
          onMouseEnter={() => {
            setshowAbout(true);
            setshowProducts(false);
            setshowServices(false);
            setshowInsights(false);
          }}
        >
          About agribazaar
        </Popover.Button>
        {showAbout && (<div
          onMouseLeave={() => setshowAbout(false)}
          onClick={() => setshowAbout(false)}
        >
          <Popover.Panel static className="transition duration-500 pt-2 absolute z-10 w-max sm:max-w-xl mt-3 transform -translate-x-1/2 left-2/4 lg:max-w-3xl">
            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="relative grid gap-6 bg-white p-10 grid-cols-4 justify-items-center">
                {aboutAgribazaarNavigation.map((navItem, index) => (
                  <a
                    key={`_${navItem.name}_${index}`}
                    href={navItem.href}
                    className="flex items-center transition duration-150 ease-in-out rounded-lg hover:bg-[#fbf4e9] focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                  >
                    <div className="p-2 flex flex-row items-center">
                      {/* shadow-md drop-shadow-sm rounded-lg border-2 border-gray-100 */}
                      <div>
                        <img src={navItem.imgSrc} width="80%" alt="" />
                      </div>
                      <p className="pl-2 text-sm font-medium text-gray-900">
                        {navItem.name}
                      </p>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </Popover.Panel>
        </div>)}
      </Popover>
    )
  }
  const renderProductsPopover = () => {
    return (
      <Popover className="relative">
        <Popover.Button className={classNames(
          "text-[#3B3939] ",
          "px-3 py-2 rounded-md text-md font-medium"
        )}
          onMouseEnter={() => {
            setshowProducts(true);
            setshowAbout(false);
            setshowServices(false);
            setshowInsights(false);
          }}
        >
          Our Products
        </Popover.Button>
        {showProducts && (
          <div
            onMouseLeave={() => setshowProducts(false)}
            onClick={() => setshowProducts(false)}
          >
            <Popover.Panel static className="pt-2 absolute z-10 w-max sm:max-w-xl mt-3 transform -translate-x-1/2 left-1/4 lg:max-w-3xl">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="relative grid gap-6 bg-white p-10 grid-cols-4 justify-items-left">
                  {ourProductsNavigation.map((navItem, index) => (
                    <a
                      key={`_${navItem.name}_${index}`}
                      href={navItem.href}
                      className="w-fit flex items-center transition duration-150 ease-in-out rounded-lg hover:bg-[#fbf4e9] focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                    >
                      <div className="p-2 flex flex-row items-center">
                        <div className="w-auto">
                          <img src={navItem.imgSrc} width="80%" alt="" />
                        </div>
                        <div className="w-max">
                          <p className="pl-2 text-sm font-medium text-gray-900">
                            {navItem.name}
                          </p>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </div>
        )}
      </Popover>
    )
  }
  const renderInsightsPopover = () => {
    return (
      <Popover className="relative">
        <Popover.Button className={classNames(
          "text-[#3B3939] ",
          "px-3 py-2 rounded-md text-md font-medium"
        )}
          onMouseEnter={() => {
            setshowInsights(true);
            setshowAbout(false);
            setshowProducts(false);
            setshowServices(false);
          }}
        >
          Insights
        </Popover.Button>
        {showInsights && <div
          onMouseLeave={() => setshowInsights(false)}
          onClick={() => setshowInsights(false)}
        >
          <Popover.Panel static className="pt-2 absolute z-10 w-max sm:max-w-xl mt-3 transform -translate-x-1/2 left-1/4 lg:max-w-3xl">
            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="relative grid gap-6 bg-white p-5 grid-cols-2 justify-items-left">
                {InsightsNavigation.map((navItem, index) => (
                  <a
                    key={`_${navItem.name}_${index}`}
                    href={navItem.href}
                    className="flex items-center transition duration-150 ease-in-out rounded-lg hover:bg-[#fbf4e9] focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                  >
                    <div className="p-2 flex flex-row items-center">
                      <div>
                        <img src={navItem.imgSrc} width="80%" />
                      </div>
                      <p className="pl-2 text-sm font-medium text-gray-900">
                        {navItem.name}
                      </p>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </Popover.Panel>
        </div>}
      </Popover>
    )
  }
  const renderServicesPopover = () => {
    return (
      <Popover className="relative">
        <Popover.Button className={classNames(
          "text-[#3B3939] ",
          "px-3 py-2 rounded-md text-md font-medium"
        )}
          onMouseEnter={() => {
            setshowServices(true);
            setshowAbout(false);
            setshowProducts(false);
            setshowInsights(false);
          }}
        >
          Our Services
        </Popover.Button>
        {showServices && <div
          onMouseLeave={() => setshowServices(false)}
          onClick={() => setshowServices(false)}
        >
          <Popover.Panel static className="pt-2 absolute z-10 w-max sm:max-w-xl mt-3 transform -translate-x-1/2 left-1/4 lg:max-w-3xl">
            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="relative grid gap-6 bg-white p-10 grid-cols-3 justify-items-left">
                {ourServicesNavigation.map((navItem, index) => (
                  <a
                    key={`_${navItem.name}_${index}`}
                    href={navItem.href}
                    className="flex items-center transition duration-150 ease-in-out rounded-lg hover:bg-[#fbf4e9] focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                  >
                    <div className="p-2 flex flex-row items-center">
                      <div>
                        <img src={navItem.imgSrc} width="80%" alt="" />
                      </div>
                      <p className="pl-2 text-sm font-medium text-gray-900">
                        {navItem.name}
                      </p>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </Popover.Panel>
        </div>}
      </Popover>
    )
  }
  const toggleAllNestedNavsClose = () => {
    if (showAbout || showProducts || showServices || showInsights) {
      setshowAbout(false);
      setshowProducts(false);
      setshowServices(false);
      setshowInsights(false);
    }
  }
  return (
    <div className="fixed w-[100%] h-fit max-w-auto z-10 bg-white drop-shadow-xl font-poppins" id="header">
      <div className="flex justify-end pr-0 w-auto" onMouseEnter={() => toggleAllNestedNavsClose()}>
        <Partner navbarToggleFunctions={toggleAllNestedNavsClose} />
      </div>
      <div className="flex-1 max-w-auto pb-2">
        <Disclosure>
          {
            ({ open }) => (
              <div className="w-auto flex justify-between items-center">
                <div className="flex  items-center justify-start float-left pl-2">
                  <a href="/">
                    <img
                      className="block h-12 w-auto ml-0 pt-2 sm:h-14"
                      src={Agrilogo}
                      alt="agribazaar"
                    />
                  </a>
                </div>
                <div className="w-auto">
                  <div className="hidden sm:flex justify-end mr-0">
                    <div className=" flex mr:0 sm:space-x-2 md:space-x-2">
                      {/* navbar menu for desktop */}
                      {renderAboutPopover()}
                      {renderProductsPopover()}
                      {renderServicesPopover()}
                      {renderInsightsPopover()}
                      {staticNavigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            "text-[#3B3939] ",
                            "px-3 py-2 rounded-md text-md font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                          onMouseEnter={() => {
                            setshowAbout(false);
                            setshowProducts(false);
                            setshowServices(false);
                            setshowInsights(false);
                          }}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="flex items-center justify-end w-max pl-2 pr-2 flex-row">
                      {/* login button */}
                      <button
                        onClick={() => window.open(loginBtnRoute.link, loginBtnRoute.target)}
                        className="whitespace-nowrap inline-flex justify-center px-6 py-1 border border-transparent rounded-md shadow-sm text-sm text-white bg-red-600 hover:bg-red-700"
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </div>
                <div className=" inset-y-0 pr-2 flex items-center sm:hidden align-self-center">
                  <div className="pt-2 justify-end items-center z-10">
                    <Disclosure.Button className=" rounded-md text-gray-400 hover:text-white  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" stroke="black" />
                      ) : (
                        <MenuIcon className="block h-6 w-6" aria-hidden="true" stroke="black" />
                      )}
                    </Disclosure.Button>
                    {renderMobileNavbar()}
                  </div>
                </div>
              </div>
            )
          }
        </Disclosure>
      </div>
    </div>
  );
}