import React from 'react';
import AboutAgribazaarTemp from '../../components/AboutAgribazaarTemplate/AboutAgribazaarTemp';
import { ourVisionObj } from "./AboutAgriConstants";

export default function OurVision() {
    const headerContent = () => {
        return (
            <div className='flex flex-col sm:flex-row gap-12 sm:gap-4 justify-between'>
                <div className="wrapper  antialiased text-gray-900 w-full sm:w-1/2">
                    <div>
                        <img src={ourVisionObj.topLeftImg} alt="our vision" className="bg-contain w-full h-56 sm:h-72 object-center  shadow-md" />
                        <div className="relative px-4 -mt-16  flex justify-center text-center sm:text-left">
                            <div className="bg-white p-4 w-80 sm:w-96 sm:h-44 shadow-lg border-4">
                                <h4 className=" text-2xl font-bold uppercase leading-tight truncate text-[#ed1849]">Vision</h4>
                                <div className="pt-2 text-md italic text-[#696969]">
                                    <p>“To empower <span className='text-[#ed1849] font-bold'>FARMERS</span> by delivering efficiency, <span className='text-[#4165b3] font-bold'>TRANSPARENCY</span> and <span className='text-[#4165b3] font-bold'>TRACEABILITY</span> in the agri value chain through technology.”</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper  antialiased text-gray-900 w-full sm:w-1/2">
                    <div>
                        <img src={ourVisionObj.topRightImg} alt="our value" className="bg-contain w-full h-56 sm:h-72  object-center shadow-md " />
                        <div className="relative px-4 -mt-16  flex justify-center text-center sm:text-left">
                            <div className="bg-white p-4 w-80 sm:w-96 sm:h-44 shadow-lg border-4">

                                <h4 className=" text-2xl font-bold uppercase leading-tight truncate text-[#ed1849]">Values</h4>

                                <div className="pt-2 text-md italic text-[#696969]">
                                    <p>Our values help us <span className='text-[#ed1849] font-bold'>R-E-A-P</span> what we sow.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div id="body-data" className='w-full py-2 sm:py-8'>
                <div className='max-w-full sm:flex flex-col justify-center items-center px-8 sm:px-0 pt-6 sm:pt-0'>
                    <img className='hidden sm:block' src={ourVisionObj.bottomImg} alt="harvest" width="90%" />
                    <div className='flex flex-col sm:flex-row gap-4 sm:gap-4 text-[#d51a35] font-semibold text-xl text-center sm:px-20 items-start'>
                        {
                            ourVisionObj.reapList.map((item, index) => (
                                <div key={`_${item.heading}_${index}`} className='flex flex-col place-content-evenly items-center '>
                                    <h4 className='whitespace-nowrap font-semibold sm:font-medium text-md sm:text-lg sm:h-8'>{item.heading}</h4>
                                    <p className='text-[#696969] font-normal text-md sm:text-lg'>{item.content}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        )
    }
    return (
        <AboutAgribazaarTemp currentPageTitle={ourVisionObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} />
    );
}

