import React from 'react';
import AboutAgribazaarTemp from '../../components/AboutAgribazaarTemplate/AboutAgribazaarTemp';
import CardTeamWModal from '../../components/ReusableGeneric/CardTeamWModal';
import { ourTeamObj } from "./AboutAgriConstants";

export default function OurTeam() {
    const headerContent = () => {
        return (
            <div className='text-[#3B3939] font-medium text-3xl sm:text-4xl text-center sm:text-left sm:px-20 py-6 pb-0'>
                <p>Leadership team</p>
            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div id="body-data" className='w-full'>
                <div id="wrapper" className='px-12 sm:px-20 items-center justify-center'>
                    <div className='sm:px-20 py-2 grid-rows-1 sm:grid-cols-3 w-full grid gap-12 sm:gap-20 align-baseline'>
                        {
                            ourTeamObj.teamList.map((item, index) => (
                                <CardTeamWModal key={`_${item.name}_${index}`} memberObj={item} />
                            ))
                        }
                    </div>
                </div>
            </div>
        )
    }
    return (
        <AboutAgribazaarTemp currentPageTitle={ourTeamObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} />
    );
}

