import React from 'react';
import QuickLinksTemp from '../../components/QuickLinksTemplate/QuickLinksTemp';
import { PressAndMediaObj } from "./QuickLinksConstants";
import PaginatedCards from "../../components/ReusableGeneric/PaginatedCards";

export default function PressAndMedia() {
    const headerContent = () => {
        return (
            <div className='text-[#3B3939] font-bold text-3xl sm:text-4xl text-center sm:text-left px-5 sm:px-24 pt-6 sm:pt-10 pb-0 sm:pb-4'>
                <p>Press Release</p>
            </div>
           
        )
    }
    const bodyContent = () => {
        return (
            <div id="body-data" className='w-full pt-8 pb-12'>
                <div id="wrapper" className='px-12 sm:px-8 items-center justify-center'>
                    <PaginatedCards arrayList={PressAndMediaObj.cardsList} title="Press Release"/>
                </div>
            </div>
        )
    }

    return (
        <div>
            <QuickLinksTemp currentPageTitle={PressAndMediaObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} />
        </div>
    );
}

