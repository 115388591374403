import React from 'react';
import OurProductsTemp from '../../components/OurProductsTemplate/OurProductsTemp';
import { OnlineAuctionEngineObj } from "./OurProductsConstants";
import CardArrowText from "../../components/ReusableGeneric/CardArrowText";
import DiscloseWSvg from "../../components/ReusableGeneric/DiscloseWSvg";

export default function OnlineAuctionEngine() {
    const headerContent = () => {
        return (
            <div id="top-data" style={{ backgroundImage: 'url(' + OnlineAuctionEngineObj.topImgUrl + ')' }} className={`h-auto sm:h-64 w-full relative bg-cover bg-center`} >
                <div className='p-20 text-center flex items-center place-items-center sm:hidden' />
            </div>
        )
    }
    const disclose1Content = () => {
        return (
            <div className='py-2 grid grid-cols-1 gap-4 px-2 sm:px-0'>
                <CardArrowText content="We give traders and corporates their customized auction portal to carry out trades and deliveries." />
                <CardArrowText content="The traders & corporates can buy agricultural products from anywhere across the country using our auction engine." />
                <CardArrowText content="The engine provides buyers, processors, and exporters access to integrated logistics services in the larger market and direct participation, which reduces the intermediary cost." />
                <CardArrowText content="This engine offers more options for buying agricultural produce and making competitive returns." />
                <CardArrowText content="The buyers make payments via AgriPay directly into sellers’ bank accounts." />
                <CardArrowText content="It provides a single platform where farmers, FPOs, PSUs, suppliers, and buyers can trade agricultural products under one roof." />
                <CardArrowText content="It aims for uniformity in agriculture marketing by streamlining procedures across the integrated markets." />
            </div>
        )
    }
    const disclose2Content = () => {
        return (
            <div className='py-2 grid grid-cols-1 gap-4 px-2 sm:px-0'>
                <CardArrowText content="OTP based login for completely secure authentication." />
                <CardArrowText content="128-bit encryption for the platform." />
                <CardArrowText content="Hosted on STQC compliant server - highest security standard for government procurement hosted on Tier III data center." />
                <CardArrowText content="SSL global signature enabled." />
                <CardArrowText content="Secure bid processing(~<600ms)." />
                <CardArrowText content="Wishlist and multi-bidding." />
                <CardArrowText content="Supports PQT and PTQ winner decision." />
                <CardArrowText content="Multi-currency and quantity units supported." />
                <CardArrowText content="Real-time fund allocation to auction." />
            </div>
        )
    }
    const disclose3Content = () => {
        return (
            <div className='py-2 grid grid-cols-1 gap-4 px-2 sm:px-0'>
                <CardArrowText content="Paperless registration eKYC to authenticate data." />
                <CardArrowText content="Reduces form time from 15 min to <1 min while running 1,000 concurrent auctions." />
                <CardArrowText content="Customized auctions as per your requirements & convenience." />
                <CardArrowText content="Encrypted data transfers & controlled logging." />
                <CardArrowText content="The engine introduces real-time availability of auction results." />
                <CardArrowText content="Creates opportunity to increase trading windows, increase buyer/producer participation, and volumes of agri commodity traded at the auction." />
                <CardArrowText content="Improves traceability and supply chain visibility with time to make better decisions." />
                <CardArrowText content="Reduces time to dispatch /receive agri commodities." />
                <CardArrowText content="Using real-time bidding and price discovery allows the stakeholders to efficiently bid for lots as per their requirements." />
                <CardArrowText content="The system also allows for splitting of lots, anonymous bidding, and generates auction information, including auction sales data, including out lots and sale price of each lot and buyer details." />
            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div id="body-data" className='px-2 sm:px-4 pb-4'>
                <div className='flex flex-col sm:flex-row justify-center items-start gap-10 sm:pl-3 sm:pr-3 text-[#3B3939]'>
                    <div id="left" className='w-full sm:w-2/4 max-w-2/4 text-center sm:text-left'>
                        <div className='w-full top-0'>
                        <div className='font-bold text-3xl sm:text-4xl m-auto pt-2'>Online Auction Engine</div>
                        <div className='pt-4 text-lg sm:text-xl font-medium'>State-of-art online auction mechanism that gained trust of 2 lakh+ traders, FPOs, corporates!</div>
                            <div className='pt-4 sm:pr-32 text-lg sm:text-xl font-semibold'>Trade, negotiate, and settle at best deals!</div>
                            <div className='pt-2 sm:pr-2 text-md sm:text-md font-normal'>Get your panel with agribazaar online auction engine to perform and manage your trades at your convenience.</div>
                            <img className='hidden sm:block pt-12' src={OnlineAuctionEngineObj.bottomLeftImg} alt="online Auction Engine" />
                        </div>
                    </div>
                    <div id="right" className='w-full sm:w-2/4 max-w-2/4 text-center sm:text-left'>
                        <div className='pt-4 px-2 sm:px-0'>
                            <DiscloseWSvg svgUrl={OnlineAuctionEngineObj.disclosuresSVGsList[0]} title="Why choose our auction engine?" content={disclose1Content()} />
                            <DiscloseWSvg svgUrl={OnlineAuctionEngineObj.disclosuresSVGsList[1]} title="Your security is our priority!" content={disclose2Content()} />
                            <DiscloseWSvg svgUrl={OnlineAuctionEngineObj.disclosuresSVGsList[2]} title="What makes us different from the rest?" content={disclose3Content()} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <OurProductsTemp currentPageTitle={OnlineAuctionEngineObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} />
        </div>
    );
}

