import React,{Fragment} from 'react';
import QuickLinksTemp from '../../components/QuickLinksTemplate/QuickLinksTemp';
import { FAQsObj } from "./QuickLinksConstants";
import DiscloseWText from "../../components/ReusableGeneric/DiscloseWText";

export default function FAQs() {
    const headerContent = () => {
        return (
            <div className='text-[#3B3939] font-medium text-3xl sm:text-4xl text-center sm:text-left px-5 sm:px-8 py-8'>
                <p>Frequently Asked Questions</p>
            </div>
        )
    }
    const renderOrderedList = (arr) => {
        return (
            <Fragment>
                <ol className='list-decimal px-4'>
                    {arr.map((str, index) => (
                        <li key={`${str}__${index}`}>
                            {str}
                        </li>
                    ))}
                </ol>
            </Fragment>
        )
    }
    const bodyContent = () => {
        return (
            <div id="body-data" className='w-full px-4 sm:px-8 text-[#3B3939] text-center sm:text-left pb-4'>
                <div className="w-full flex flex-col text-center sm:text-left">
                    <h4 className=" text-2xl font-bold uppercase leading-tight truncate text-[#ed1849] pb-4">AGRIBAZAAR</h4>
                    <div className='sm:pl-3 sm:pr-3'>
                        <DiscloseWText title="What is agribazaar.com?" content={() => (<p className='px-4'>{FAQsObj.whatIsAgribazaarStr}</p>)} />
                    </div>
                </div>
                <h4 className=" text-2xl font-bold uppercase leading-tight truncate text-[#ed1849] pt-4 pb-4">REGISTRATION</h4>
                <div className='flex flex-col sm:flex-row justify-center items-start gap-2 sm:gap-10 sm:pl-3 sm:pr-3'>
                    <div id="left" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div className='grid grid-cols-1 gap-2 items-baseline'>
                            <DiscloseWText title="How can I register on agribazaar.com?" content={() => (<p className='px-2'>{FAQsObj.registrationTL1}</p>)} />
                            <DiscloseWText title="Does it cost to get registered?" content={() => (<p className='px-2'>{FAQsObj.registrationTL2}</p>)} />
                            <DiscloseWText title="What are the documents required to register?" content={() => (<p className='px-2'>{FAQsObj.registrationTL3}</p>)} />
                        </div>
                    </div>
                    <div id="right" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div className='grid grid-cols-1 gap-2 items-baseline'>
                            <DiscloseWText title="Why do you need my bank account details?" content={() => (<p className='px-2'>{FAQsObj.registrationTR1}</p>)} />
                            <DiscloseWText title="How to apply for NAFED verification?" content={() => (<p className='px-2'>{FAQsObj.registrationTR2}</p>)} />
                        </div>
                    </div>
                </div>
                <h4 className=" text-2xl font-bold uppercase leading-tight truncate text-[#ed1849] pt-4 pb-4">TRADE</h4>
                <div className='flex flex-col sm:flex-row justify-center items-start gap-2 sm:gap-10 sm:pl-3 sm:pr-3'>
                    <div id="left" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div className='grid grid-cols-1 gap-2 items-baseline'>
                            <DiscloseWText title="How can buyer create a new order on the platform?" content={() => (<p className='px-2'>{FAQsObj.tradeTL1}</p>)} />
                            <DiscloseWText title="How can seller create a new order on the platform?" content={() => (<p className='px-2'>{FAQsObj.tradeTL2}</p>)} />
                            <DiscloseWText title="How can a buyer participate in existing offers?" content={() => (<p className='px-2'>{FAQsObj.tradeTL3}</p>)} />
                            <DiscloseWText title="As a seller, how can I be sure of my quality parameters?" content={() => (<p className='px-2'>{FAQsObj.tradeTL4}</p>)} />
                            <DiscloseWText title="What is negotiable trade?" content={() => (<p className='px-2'>{FAQsObj.tradeTL5}</p>)} />
                            <DiscloseWText title="When does EMD get refunded?" content={() => (<p className='px-2'>{renderOrderedList(FAQsObj.tradeTL6)}</p>)} />
                            <DiscloseWText title="How to participate in an auction?" content={() => (<p className='px-2'>{FAQsObj.tradeTL7}</p>)} />
                        </div>
                    </div>
                    <div id="right" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div className='grid grid-cols-1 gap-2 items-baseline'>
                            <DiscloseWText title="How can a seller participate in existing offers?" content={() => (<p className='px-2'>{FAQsObj.tradeTR1}</p>)} />
                            <DiscloseWText title="Will everyone come to know about my positions?" content={() => (<p className='px-2'>{FAQsObj.tradeTR2}</p>)} />
                            <DiscloseWText title="What are the quality norms for the platform?" content={() => (<p className='px-2'>{FAQsObj.tradeTR3}</p>)} />
                            <DiscloseWText title="What if there is a dispute on the quality?" content={() => (<p className='px-2'>{FAQsObj.tradeTR4}</p>)} />
                            <DiscloseWText title="What is EMD?" content={() => (<p className='px-2'>{FAQsObj.tradeTR5}</p>)} />
                            <DiscloseWText title="How to create an auction?" content={() => (<p className='px-2'>{FAQsObj.tradeTR6}</p>)} />
                        </div>
                    </div>
                </div>
                <h4 className=" text-2xl font-bold uppercase leading-tight truncate text-[#ed1849] pt-4 pb-4">PAYMENTS</h4>
                <div className='flex flex-col sm:flex-row justify-center items-start gap-2 sm:gap-10 sm:pl-3 sm:pr-3'>
                    <div id="left" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div className='grid grid-cols-1 gap-2 items-baseline'>
                            <DiscloseWText title="How will you make sure that i will receive the payment as seller?" content={() => (<p className='px-2'>{FAQsObj.paymentsTL1}</p>)} />
                            <DiscloseWText title="How can I withdraw my remaining fund from agribazaar account?" content={() => (<p className='px-2'>{FAQsObj.paymentsTL2}</p>)} />
                            <DiscloseWText title="How do I know my payment is secured?" content={() => (<p className='px-2'>{FAQsObj.paymentsTL1}</p>)} />
                        </div>
                    </div>
                    <div id="right" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div className='grid grid-cols-1 gap-2 items-baseline'>
                            <DiscloseWText title="When will i receive the payment?" content={() => (<p className='px-2'>{FAQsObj.paymentsTR1}</p>)} />
                            <DiscloseWText title="How can I keep a track on my agribazaar account transactions?" content={() => (<p className='px-2'>{FAQsObj.paymentsTR2}</p>)} />
                            <DiscloseWText title="What is agribazaar virtual account?" content={() => (<p className='px-2'>{FAQsObj.paymentsTR3}</p>)} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <QuickLinksTemp currentPageTitle={FAQsObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} />
        </div>
    );
}

