import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';

export default function DiscloseWText({ title, content, isDefaultOpen = false }) {
    return (
        <div className="w-full sm:px-4 pb-6">
            <div className="p-1 mx-auto rounded-md bg-[#FFFDF9] drop-shadow-xl shadow-inner border-2 border-[#F7D39D]">
                <Disclosure as="div" className="mt-2" defaultOpen={isDefaultOpen} >
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="items-center flex justify-between w-full px-4 py-4 text-sm font-medium text-left text-[#3B3939]  rounded-lg  focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
                                <div className='flex flex-row items-center place-items-center text-start p-0 gap-4 w-[90%] h-max sm:h-10'>
                                    <span className='items-start text-lg font-medium'>{title}</span>
                                </div>
                                <ChevronUpIcon
                                    className={`${!open ? 'transform rotate-180' : ''
                                        } w-5 h-5 text-[#3B3939] `}
                                />
                            </Disclosure.Button>
                            <Disclosure.Panel className="sm:px-0 pt-0 pb-2 px-4 text-left text-md font-normal text-[#3B3939]">
                                {content}
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            </div>
        </div>
    );
}

