import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { debounce } from "lodash";
import appConfig from "../config";
import { partnerWithUsRoute, createYourTradeRoute, registerRoute } from "./Constants";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const inputBoxSearchIcon = () => {
  return (
    <svg fill="black" viewBox="0 0 512 512" className="w-4 h-4">
      <path d="M479.6,399.716l-81.084-81.084-62.368-25.767A175.014,175.014,0,0,0,368,192c0-97.047-78.953-176-176-176S16,94.953,16,192,94.953,368,192,368a175.034,175.034,0,0,0,101.619-32.377l25.7,62.2L400.4,478.911a56,56,0,1,0,79.2-79.195ZM48,192c0-79.4,64.6-144,144-144s144,64.6,144,144S271.4,336,192,336,48,271.4,48,192ZM456.971,456.284a24.028,24.028,0,0,1-33.942,0l-76.572-76.572-23.894-57.835L380.4,345.771l76.573,76.572A24.028,24.028,0,0,1,456.971,456.284Z"></path>
    </svg>
  )
}
const searchIcon2 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
    </svg>
  )
}
const searchIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
    </svg>
  )
}
const loadSpinner = () => {
  return (
    <svg role="status" className="right-0 w-4 h-4 text-gray-200 animate-spin  fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
    </svg>
  )
}
export default function Partner({ navbarToggleFunctions }) {
  const [searchResultsList, setSearchList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getList = async (param) => {
    if (param.length > 0) {
      setIsLoading(true);
      var searchString = param.toUpperCase().replace(/ /g, "\\%20");
      var params =
        "facet.pivot=com_nameOrig,marketOrig,status&facet=on&facet.limit=10&facet.sort=status%20ASC&facet.offset=0&fq=(com_name:*" +
        searchString +
        "*%20market:*" +
        searchString +
        "*%20display_name:*" +
        searchString +
        "*)&fq=status:[100%20TO%20600]&omitHeader=true&q=*:*&rows=10&start=0&sort=status%20ASC,%20start_datetime%20DESC";
      const res = await axios.get(
        `${appConfig.domain[appConfig.environment].noderefresh
        }/get-list?c=coreAuctions&&qry=${encodeURIComponent(
          params
        )}&action=com_nameOrig,marketOrig,status&searchText=${searchString}`
      );
      setSearchList(res.data.data);
      setIsLoading(false);
    }
  };
  const debouncedSearch = React.useRef(
    debounce((e) => {
      getList(e);
    }, 300)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const gotoAuction = (item) => {
    if (item.trade_type) {
      window.location.href = `${appConfig.route[appConfig.environment]
        }/tradedetails?id=${item.list_id}&tradeType=${item.trade_type
        }&display_id=${item.display_name}`;
    } else {
      let view = item.front_id ? item.front_id : "nafed",
        engine = item.engine ? item.engine : "nafed",
        type = item.type === 1 ? "buy" : "sell";

      window.location.href = `${appConfig.route[appConfig.environment]
        }/tradedetails?id=${item.list_id}&type=${type}&client=${item.template_id
        }&view=${view}&engine=${engine}&display_id=${item.display_name}`;
    }
  };
  const showSearches = () => {
    return (
      <div id="search-results" className=" relative flex flex-col w-full h-auto overflow-y-scroll overflow-x-scroll">
        {isLoading ? (<div className="pl-2 pr-4 pt-2 pb-1 overflow-hidden"><Skeleton count={8} /></div>) :
          (<Fragment>
            {searchResultsList.facet?.map((item, index) =>
              item.pivot.map((item2, index2) =>
                <div
                  key={`_${item.value}_${index}_${item2.value}_${index2}`}
                  className="pl-2 pr-4 pt-2 pb-1" >
                  <a
                    href={`${appConfig.route[appConfig.environment]
                      }/searchresult?commodity=${item.value}&market=${item2.value}`}
                  >
                    <div className="flex flex-row items-center">
                      <div>
                        {searchIcon()}
                      </div>
                      <div className="pl-2 text-sm whitespace-nowrap"> {item.value}</div>
                      {item2.value !== " " && (<div className="text-[#4165b3] text-sm whitespace-nowrap"> &nbsp;in {item2.value}</div>)}
                    </div>
                  </a>
                </div>
              ))}
            {searchResultsList.docs?.map((item, index) =>
              <div key={`_${item.value}__${index}`} className="pl-2 pr-4 pt-2 pb-1">
                <div
                  className=" flex flex-row items-center cursor-pointer"
                  onClick={() => gotoAuction(item)}>
                  <div>
                    {searchIcon2()}
                  </div>
                  <div className="pl-2 text-sm text-[#3B3939] whitespace-nowrap"> {item.display_name} |&nbsp;</div>
                  <div className="text-sm whitespace-nowrap">{item.com_name}</div>
                  <div className="text-[#4165b3] text-sm whitespace-nowrap"> &nbsp;in {item.market}</div>
                </div>
              </div>
            )}
          </Fragment>)}
      </div>
    )
  }
  const renderSearchResults = () => {
    return (
      <Fragment>
        {(!searchResultsList.facet || !searchResultsList.docs) ?
          (
            <div className="pl-2 pr-4 pt-2 pb-1">Type something to search...</div>
          ) :
          (searchResultsList.facet?.length === 0 || searchResultsList.docs?.length === 0) ?
            (
              <div className="pl-2 pr-4 pt-2 pb-1">No results found.</div>
            ) :
            (
              <Fragment>{showSearches()}</Fragment>
            )
        }
      </Fragment>
    )
  }

  const testtingNewSearchBox = () => {
    return (
      <div className=" dropdown px-4  border-none active:border-0 text-[#3B3939]">
        <div className="relative py-2">

          <div class="relative">
            <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              {inputBoxSearchIcon()}
            </div>
            <input type="text" id="email-adress-icon" class="bg-transparent border-0 border-b-2 border-r-0 border-gray-300 text-gray-900 text-sm rounded-lg block w-full pl-10 p-2.5" placeholder="name@flowbite.com" />
          </div>

        </div>
        {/* <div tabIndex="0" className=" menu dropdown-content bg-gray-100 text-[#3B3939]-content rounded-b-lg w-96 h-96">
              {renderSearchResults()}
            </div> */}
      </div>
    )
  }
  return (
    <div className="hidden sm:block max-w-auto bg-white font-poppins">
      <div className="flex justify-end pr-2 w-auto">
        <div className="bg-[#4165b3] px-4 text-white flex items-center">
          <a href={partnerWithUsRoute.link} target={partnerWithUsRoute.target}> <div className="text-sm">PARTNER WITH US</div></a>

          <div className=" dropdown px-4  border-none active:border-0 text-[#3B3939]">
            <div className="relative py-2">
              <input type="search" name="Search" placeholder="Explore trades by commodities and more."
                className="h-7 placeholder:text-xs pr-8  pl-2  rounded-md sm:w-72 focus:outline-none text-sm"
                onChange={(e) => debouncedSearch(e.target.value)}
                onFocus={() => navbarToggleFunctions()}
              />
              <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                <button type="submit" title="Search" className="p-1 focus:outline-none focus:ring">
                  {inputBoxSearchIcon()}
                </button>
              </span>
            </div>
            <div tabIndex="0" className={` menu dropdown-content bg-gray-100 text-[#3B3939]-content rounded-b-lg w-max max-w-[32rem] ${isLoading ? 'h-auto' : 'h-96'}`}>
              {renderSearchResults()}
            </div>
          </div>
          {/* {testtingNewSearchBox()} */}

          <a href={createYourTradeRoute.link} target={createYourTradeRoute.target}> <div className="text-sm">Create Your Trade&nbsp;&nbsp;</div></a>
          <a href={registerRoute.link} target={registerRoute.target}><div className="underline text-sm">Register</div></a>
        </div>
      </div>
    </div>
  );
}


