import React from 'react';
import QuickLinksTemp from '../../components/QuickLinksTemplate/QuickLinksTemp';
import { StepsObj } from "./QuickLinksConstants";
import RightArrowShapedDiv from "../../components/ReusableGeneric/RightArrowShapedDiv";



export default function Steps() {
    const headerContent = () => {
        return (
            <div>

            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div id="body-data" className='w-full  text-[#3B3939] text-center sm:text-left pb-20 bg-white pt-8'>
                <div id="for-buyers" className='bg-[#f6fff1] w-full px-4 sm:px-8'>
                    <h4 className=" text-4xl font-bold uppercase leading-tight pb-8 pt-4">For Buyers</h4>
                    <div className='hidden sm:flex flex-row gap-6 text-center px-4 pb-8'>
                        <RightArrowShapedDiv step={1} svgUrl={StepsObj.buyerStep1} head="Tell us about your Requirement" content="Browse our catalogue and place a bid on a commodity you need. You can also create your own trade and set customised delivery terms." />
                        <RightArrowShapedDiv step={2} svgUrl={StepsObj.buyerStep2} head="Place an Order" content="Get real-time notifications on trade status. Place an order with a seller of your choice and check stock quality." />
                        <RightArrowShapedDiv step={3} svgUrl={StepsObj.buyerStep3} head="Make a secure Payment" content="Transfer payments instantly through AgriPay, our secure payment platform. Be rest assured that your money is in safe hands." />
                        <RightArrowShapedDiv step={4} svgUrl={StepsObj.buyerStep4} head="Receive goods at your Doorstep" content="Track delivery status with our transparent end-to-end settlement process. We’ll transfer your payments to the seller only after you confirm receipt of goods." />
                    </div>
                    <div className='flex flex-col text-center px-4 pb-8 sm:hidden'>
                        <div className='w-full'><img src={StepsObj.mobBuyerS1} alt="buyer-step1" width="100%" /></div>
                        <div className='w-full'><img src={StepsObj.mobBuyerS2} alt="buyer-step2" width="100%" /></div>
                        <div className='w-full'><img src={StepsObj.mobBuyerS3} alt="buyer-step3" width="100%" /></div>
                        <div className='w-full'><img src={StepsObj.mobBuyerS4} alt="buyer-step4" width="100%" /></div>
                    </div>
                </div>
                <br/>
                <div id="for-sellers" className='pt-4 bg-[#f6fff1] w-full px-4 sm:px-8'>
                    <h4 className=" text-4xl font-bold uppercase leading-tight pb-8">For Sellers</h4>
                    <div className='hidden sm:flex flex-row gap-6 text-center px-4 pb-8'>
                        <RightArrowShapedDiv step={1} svgUrl={StepsObj.sellerStep1} head="Tell us about your Commodity" content="Add details about the nature of your commodities, quantity and location on our app. Set prices and delivery terms in the trade." />
                        <RightArrowShapedDiv step={2} svgUrl={StepsObj.sellerStep2} head="Confirm sale to a buyer" content="Get real-time notifications on trade status. Confirm receipt of the order from a buyer and check their delivery terms." />
                        <RightArrowShapedDiv step={3} svgUrl={StepsObj.sellerStep3} head="Dispatch goods" content="Once we confirm that the buyer has made a payment on our platform, you can dispatch the commodities." />
                        <RightArrowShapedDiv step={4} svgUrl={StepsObj.sellerStep4} head="Recieve instant payment" content="That’s it! We’ll transfer the payment to your AgriPay account, as soon as the buyer confirms delivery of goods." />
                    </div>
                    <div className='flex flex-col text-center px-4 pb-8 sm:hidden'>
                        <div className='w-full'><img src={StepsObj.mobSellerS1} alt="seller-step1" width="100%" /></div>
                        <div className='w-full'><img src={StepsObj.mobSellerS2} alt="seller-step2" width="100%" /></div>
                        <div className='w-full'><img src={StepsObj.mobSellerS3} alt="seller-step3" width="100%" /></div>
                        <div className='w-full'><img src={StepsObj.mobSellerS4} alt="seller-step4" width="100%" /></div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <QuickLinksTemp currentPageTitle={StepsObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} />
        </div>
    );
}

