import React from 'react';

export default function CardTeamWCurvedBorder({ imgUrl, name, desig }) {
    return (
        <div class="w-full bg-white rounded-lg border-0 border-gray-200 shadow-md">
            {/* <a href="#"> */}
                <img class="w-full rounded-t-lg" src={imgUrl} alt={name} />
            {/* </a> */}
            <div class="p-5 items-center sm:items-start text-center flex flex-col">
                <p class="mb-2 text-xl  text-[#707070]">{name}</p>
                <p class="mb-3 font-normal text-[#696969] text-sm">{desig}</p>
            </div>
        </div>
    );
}

