import React from 'react';
import OurServicesTemp from '../../components/OurServicesTemplate/OurServicesTemp';
import { CropAssessmentObj } from "./OurServicesConstants";
import SimpleCardTextText from "../../components/ReusableGeneric/SimpleCardTextText";
import DiscloseWText from "../../components/ReusableGeneric/DiscloseWText";

export default function CropAssessment() {
    const headerContent = () => {
        return (
            <div id="top-data" style={{ backgroundImage: 'url(' + CropAssessmentObj.topImgUrl + ')', }} className={`w-full relative bg-cover bg-center`}>
                <div className='p-20 text-center flex items-center place-items-center sm:hidden' />
                <div className='py-20 px-6 text-start hidden sm:flex items-start text-white'>
                    <div className='flex flex-col gap-2 p-0'>
                        <p className='font-medium text-5xl'>Crop Assessment <br /> using AI, ML, GIS</p>
                        <div className='font-normal text-xl'>Transform your plantation with effective <br /> crop-level insights using the strength of <br /> AI, ML & so much more!</div>
                    </div>
                </div>
            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div id="body-data" className='px-4 sm:px-8'>
                 <div className='sm:hidden'>
                    <div className='text-center flex items-start  text-[#3B3939]'>
                        <div className='flex flex-col gap-2 p-0'>
                            <p className='font-semibold text-2xl'>Crop Assessment using AI, ML, GIS</p>
                            <div className='pt-2 font-normal text-lg '>Transform your plantation with effective crop-level insights using the strength of AI, ML & so much more!</div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col sm:flex-row justify-center items-start gap-10 sm:pl-3 sm:pr-3  text-[#3B3939]'>
                    <div id="left" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div className='grid grid-cols-1 gap-2 items-baseline'>
                            <div className='pt-4 text-xl sm:text-xl font-semibold  sm:h-20'>Detect crop health and predict harvest yields</div>
                            <div className='pt-0 text-md sm:text-md font-normal sm:h-32 sm:w-[94%]'>With contamination observations, pest and disease predictions, and nutrition recommendations, agribazaar helps ensure the optimal health of your crops. We provide weather-related insights and resource requirement predictions through artificial intelligence and machine learning.</div>
                            <img src={CropAssessmentObj.middleLeftImg} alt="Crop health" className='hidden sm:block h-60 pt-4' />
                        </div>
                    </div>
                    <div id="right" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div className='grid grid-cols-1 gap-2 items-baseline'>
                            <div className='pt-0 sm:pt-4 text-xl sm:text-xl font-semibold sm:h-20'>Precise crop mapping to set new productivity benchmarks</div>
                            <div className='pt-0 text-md sm:text-md font-normal sm:h-32 sm:w-[94%]'>Detecting pests, nutrient deficiencies, and crop diseases can considerably impact crop productivity. Satellite imagery and AI & ML can help successfully determine crop health and carry out yield estimations.</div>
                            <img src={CropAssessmentObj.middleRightImg} alt="Crop mapping" className='hidden sm:block h-60 pt-4' />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const bottomContent = () => {
        const contentCard1 = () => {
            return (
                <ul class="list-disc list-inside marker:text-[#4165B3] marker:text-xl leading-loose"  style={{height: "28vh"}}>
                    <li>Predictive analytics</li>
                    <li>Soil analysis and disease detection</li>
                    <li>Livestock management</li>
                    <li>Real-time visibility into agri supply chain</li>
                </ul>
            )
        }
        const contentCard2 = () => {
            return (
                <ul class="list-disc list-inside marker:text-[#4165B3] marker:text-xl leading-loose" style={{height: "28vh"}}>
                    <li>Constant visibility into the field</li>
                    <li>Precise crop-health monitoring</li>
                    <li>Improved yields</li>
                    <li>Efficient crop-growing practices</li>
                </ul>
            )
        }
        const contentCard3 = () => {
            return (
                <div className=' text-[#3B3939]' style={{height: "28vh"}}>
                    <p className='text-center sm:text-left'>Scouts do not get sent for a wild goose chase!</p>
                    <ul class="pt-4 list-disc list-inside marker:text-[#4165B3] marker:text-xl leading-loose">
                        <li>On-field changes notifications</li>
                        <li>Precise problem area detection</li>
                        <li>Downloadable scout reports</li>
                        <li>Mobile app to assist scouts</li>
                    </ul>
                </div>
            )
        }
        return (
            <div id="bottom-data" className='px-2 sm:px-8  text-[#3B3939] text-center sm:text-left'>
                <div className='hidden py-6 px-2 text-left sm:grid grid-cols-3 gap-8 items-baseline justify-start  text-[#3B3939]'>
                    <SimpleCardTextText title="Enhance crop disease and pest infestation monitoring" content={contentCard1()} bodyHeight={80} />
                    <SimpleCardTextText title="Improved soil health and crop trends monitoring" content={contentCard2()} bodyHeight={80} />
                    <SimpleCardTextText title="Problems with your crops go unnoticed until it's way too late?" content={contentCard3()} bodyHeight={80} />
                </div>
                <div className='sm:hidden pt-4 px-4'>
                    <DiscloseWText title="Enhance crop disease and pest infestation monitoring" content={contentCard1()}/>
                    <DiscloseWText title="Improved soil health and crop trends monitoring" content={contentCard2()}/>
                    <DiscloseWText title="Problems with your crops go unnoticed until it's way too late?" content={contentCard3()}/>
                </div>
            </div>
        )
    }
    return (
        <div>
            <OurServicesTemp currentPageTitle={CropAssessmentObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} bottomContent={bottomContent()} />
        </div>
    );
}