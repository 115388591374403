import React, { Fragment, useState, useEffect } from 'react';
import { careerOpportunitiesObj } from "../../pages/Careers/CareersConstants";
import { aws } from "../AWSbucket";


const config = {
    bucketName: aws.bucketName,
    dirName: aws.directoryName,
    accessKeyId: aws.accessKey,
    secretAccessKey: aws.secretAccessKey,
    region: aws.region
}
const loadSpinner = () => {
    return (
        <svg role="status" className="right-0 w-4 h-4 text-gray-200 animate-spin  fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
        </svg>
    )
}
const checkIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="blue">
            <path fill-rule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
        </svg>
    )
}
const crossIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="red">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
        </svg>
    )
}
export default function ApplyForJob({ jobObj }) {
    const [applyingForJobObj, setapplyingForJobObj] = useState({});
    const [isDisableSubmit, setDisableSubmit] = useState(false);
    const [formValues, setFormValues] = useState({
        fname: "",
        lname: "",
        email: "",
        mobile: "",
        resume: ""
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploadingResume, setisUploadingResume] = useState(false);
    const [isResumeUploaded, setisResumeUploaded] = useState(false);
    const [isResumeUploadFail, setisResumeUploadfail] = useState(false);
    useEffect(() => {
        if (localStorage.getItem("applyingFor")) {
            setapplyingForJobObj(JSON.parse(localStorage.getItem("applyingFor")));
            // setTimeout(() => localStorage.clear(), 0);
        }
    }, [])


    const handleSubmit = () => {
        // console.log("submit handler", formValues);
        // alert("submit handler");
        if (formValues.fname && formValues.lname && formValues.email && formValues.mobile || formValues.resume) {
            console.log("submit handler", formValues);
            submitJobApplyForm();
        } else {
            alert("fill all values before submitting!");
            return true;
        }
    }

    const submitJobApplyForm = async () => {
        setTimeout(() => localStorage.clear(), 0);
    }

    const handleFileInput = async (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        // setisUploadingResume(true);
        console.log("uploading file", file);
        // const ReactS3Client = new S3(config);
        // ReactS3Client.uploadFile(file)
        //     .then(data => {
        //         console.log("data", data);
        //     })
        //     .catch(err => {
        //         console.log("error", err);
        //     })

        // setTimeout(() => fileUploadSuccess(), 2000);
    }
    const fileUploadSuccess = () => {
        setisUploadingResume(false);
        setisResumeUploaded(true);
        setisResumeUploadfail(false);
        setDisableSubmit(false);
    }
    const fileUploadFailure = () => {
        setisUploadingResume(false);
        setisResumeUploaded(false);
        setisResumeUploadfail(true);
        setDisableSubmit(true);
    }
    const renderContent = () => {
        const {
            jobId,
            jobTitle,
            positionType,
            jobLocation,
            jobType,
            jobPost
        } = applyingForJobObj;
        return (
            <Fragment>
                <div id="bottom-wrapper" className="text-[#3B3939] py-2 sm:flex flex-col gap-8 w-full h-full items-center justify-center pt-14">
                    <div id="body-data" className='w-full sm:px-16'>
                        <div className="flex flex-wrap">
                            <div className='w-full'>
                                <p className='text-lg font-semibold'>Job ID: {jobId}</p>
                                <p className='pt-2 text-lg font-semibold'>Applying for: {jobTitle}</p>
                                <p className='pt-2 text-lg font-semibold'>Job type: {jobType}</p>
                                <p className='pt-2 pb-8 text-lg font-semibold'>Job location: {jobLocation}</p>
                                <p className='pb-4'>Please fill the below mentioned details and upload your latest resume.</p>
                                <form className="sm:space-y-6" action="#"
                                    onChange={(e) => setFormValues({ ...formValues, [e.target.id]: e.target.value })}
                                    onSubmit={() => handleSubmit()}
                                >
                                    <div className='flex flex-col sm:flex-row sm:gap-32'>
                                        <div className="w-full md:w-1/2 sm:mb-6 md:mb-0">
                                            <input value={formValues.fname} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-800" id="fname" type="text" placeholder="First Name*" pattern="[a-zA-Z]+" title="only alphabets allowed" />
                                        </div>
                                        <div className="w-full md:w-1/2">
                                            <input value={formValues.lname} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="lname" type="text" placeholder="Last Name*" pattern="[a-zA-Z]+" title="only alphabets allowed" />
                                        </div>
                                    </div>
                                    <div className='flex flex-col sm:flex-row sm:gap-32'>
                                        <div className="w-full md:w-1/2 sm:mb-6 md:mb-0">
                                            <input value={formValues.email} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="email" type="email" pattern="^[a-zA-Z0-9_.]+[@]{1}[a-z0-9]+[\.][a-z]+$" title="Enter a valid email id" placeholder="E-mail*" />
                                        </div>
                                        <div className="w-full md:w-1/2">
                                            <input value={formValues.mobile} maxLength="10" className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="mobile" type="tel" pattern="[6-9]{1}[0-9]{9}" title="Enter a valid mobile number" placeholder="Phone Number*" />
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-3 sm:mb-6">
                                        <div className="w-max px-3">
                                            <input onChange={handleFileInput} value={formValues.resume} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 sm:mb-3 leading-tight focus:outline-none focus:bg-white" id="resume" type="file" accept="image/png, image/jpeg, application/pdf" title="only png, jpeg/jpg and pdf allowed." placeholder="Subject" />
                                        </div>{isUploadingResume && loadSpinner()} {isResumeUploaded && checkIcon()}{isResumeUploadFail && crossIcon()}
                                    </div>
                                    <div className="w-1/2 sm:w-1/6 pt-8 sm:pt-0 pb-12">
                                        <input
                                            className={`${isDisableSubmit && 'cursor-not-allowed'}  w-full shadow bg-red-600 hover:bg-red-700 focus:shadow-outline focus:outline-none text-white font-bold py-3 sm:py-2 px-4 rounded`}
                                            type="submit"
                                            disabled={isDisableSubmit}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
    return (
        <div className='p-0 overflow-hidden pt-20 sm:pt-24 z-0 bg-white  sm:max-w-[1900px] m-auto w-[100%] font-poppins text-[#3B3939]'>
            <div id="top-wrapper" className='w-full'>
                <div id="top-data" style={{ backgroundImage: 'url(' + careerOpportunitiesObj.topImgUrl + ')', }} className={`w-full relative bg-cover bg-center`}>
                    <div className='p-20 text-center flex items-center place-items-center sm:hidden' />
                    <div className='py-20 px-60 text-center hidden sm:flex text-white'>
                        <p className='hidden sm:block font-medium text-5xl'>{careerOpportunitiesObj.topTitle}</p>
                    </div>
                </div>
            </div>
            {Object.keys(applyingForJobObj).length > 0 ? (
                <Fragment>
                    {renderContent()}
                </Fragment>
            ) : (
                <div className='px-12 py-20 text-center font-poppins font-medium text-xl text-[#3B3939]'>
                    Please select the appropriate job from career section. Click <button onClick={() => window.open("/careers/career-opportunities", "_self")} className='text-blue-500 underline'>here</button> to go to careers page.
                </div>
            )}
        </div>
    );
}

