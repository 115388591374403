
const aboutAnup = () => {
    return (
        <div>
            <div>
            Anup Singh is the Chief Product & Technology Officer at agribazaar with 20 years of experience. He believes in powering business objectives with disruptive and state-of-the-art technology to fulfill user needs. Anup is leading agribazaar’s differentiated and market-pioneering products like Agripay, Agribhumi, auction engine and marketplace. He is passionate about building technology solutions that drive revenue growth and enhance customer experience.
            </div>
            <div>
            Anup’s greatest asset is his 20 years of versatile experience in tech product development, which ranges from sectors like health tech to electronic design automation. He has been a successful leader at notable companies like Meddo Health, Mentor Graphics and Adobe Systems.
            </div>
            <div>
            Anup earned his Bachelor’s in Computer Science and engineering from the Indian Institute of Technology Roorkee, Uttarakhand, India.
            </div>

        </div>
    )
}

export default aboutAnup();