import React, { useState } from 'react';

const crossIcon = (fillColor) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill={fillColor}>
            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
    )
}
const linkedInIcon = () => {
    return (
        <svg
            className="w-6 h-6 text-blue-500 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512">
            <path
                d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
            ></path>
        </svg>
    )
}
export default function CardTeamWModal({ memberObj }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTeamMember, setSelectedTeamMember] = useState({});

    const modalContent = () => {
        const { imgUrl, name, desig, text, linkedInUrl } = selectedTeamMember;
        return (
            <div className='z-10 fixed'>
                <div className=" hidden sm:block">
                    <div className="p-8 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="w-[80%] 2xl:pt-12 2xl:pb-12 h-fit pt-0 pl-10 card card-side shadow-2xl bg-white text-[#3B3939] bg-opacity-95">
                            <figure className='w-[30%]'><img src={imgUrl} alt={name} /></figure>
                            <div className="card-body w-[70%] pt-0 opacity-90">
                                <div className='relative card-actions flex justify-end pt-6 pr-0 bg-white bg-opacity-5'
                                    onClick={() => { setSelectedTeamMember({}); setIsModalOpen(false); document.getElementsByTagName("BODY")[0].style.overflowY = 'scroll'; }}>
                                    <button className="btn btn-square btn-sm">
                                        {crossIcon('white')}
                                    </button>
                                </div>
                                <div className='text-[#3B3939]'>
                                    <h2 className="card-title text-2xl">{name}</h2>
                                    <h3 className="font-medium text-sm pt-1">{desig}</h3>
                                    <p className='mr-4 text-justify pt-4 text-sm'>{text}</p>
                                </div>
                                <a href={linkedInUrl}>{linkedInIcon()}</a>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </div>
                <div className='sm:hidden '>
                    {/* <div className="h-full pt-32 pb-[1rem] px-2 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div class="card card-compact w-full bg-white text-[#3B3939] shadow-2xl">
                            <div className='relative card-actions flex justify-end pt-6 pr-0 bg-white hover:bg-white'
                                onClick={() => { setSelectedTeamMember({}); setIsModalOpen(false); document.getElementsByTagName("BODY")[0].style.overflow = 'scroll';}}>
                                <button className="btn btn-square btn-sm">
                                    {crossIcon()}
                                </button>
                            </div>
                            <figure className='px-8'><img src={imgUrl} alt={name} width="100%" className='w-full pt-8' /></figure>
                            <div className="card-body">
                                <div className='text-[#3B3939]'>
                                    <h2 className="card-title text-2xl">{name}</h2>
                                    <h3 className="font-medium text-sm pt-1">{desig}</h3>
                                    <p className='mr-4 text-justify pt-4 text-sm'>{text}</p>
                                </div>
                                <a href={linkedInUrl}>{linkedInIcon()}</a>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
                    <div tabindex="-1" class=" text-[#3B3939] overflow-y-auto overflow-x-hidden fixed top-0  right-0 left-0 z-50 w-full h-full">
                        <div class="relative p-6 pt-16 w-full max-w-7xl h-full">
                            <div class="relative bg-white rounded-lg drop-shadow-2xl">
                                <div class="flex justify-between items-center p-1 rounded-t" onClick={() => {
                                    setSelectedTeamMember({});
                                    setIsModalOpen(false);
                                    document.getElementsByTagName("BODY")[0].style.overflowY = 'scroll';
                                }}>
                                    <button type="button" class=" bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="extralarge-modal">
                                        {crossIcon('black')}
                                    </button>
                                </div>
                                <div className='flex justify-center'>
                                    <img src={imgUrl} alt={name} className='w-80 px-4 flex justify-center' />
                                </div>
                                <div class="py-4 px-6 space-y-6 text-center">
                                    <div className='text-[#3B3939] pb-2'>
                                        <h2 className="font-semibold text-2xl">{name}</h2>
                                        <h3 className="font-medium text-sm pt-1">{desig}</h3>
                                        <p className='text-justify pt-4 text-sm'>{text}</p>
                                    </div>
                                    <a href={linkedInUrl}>{linkedInIcon()}</a>
                                </div>
                                <div class="flex items-center p-2 space-x-2 rounded-b" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className='w-full'>
            {isModalOpen && modalContent()}
            <div className="w-full border-b-2 bg-white card-side">
                <figure className='w-full cursor-pointer' onClick={() => {
                    setSelectedTeamMember(memberObj);
                    setIsModalOpen(true);
                    document.getElementsByTagName("BODY")[0].style.overflow = 'hidden';
                }}><img src={memberObj.imgUrl} alt={memberObj.name} /></figure>
                <div className="card-body text-[#3B3939] items-start text-center py-4 px-0">
                    <h2 className="card-title text-xl">{memberObj.name}</h2>
                    <p className='pt-0 font-medium text-sm text-gray-600'>{memberObj.desig}</p>
                </div>
            </div>
        </div>
    );
}

