import React from "react";

export default function Error() {
    return (
        <div
            className='p-0 overflow-hidden pt-20 sm:pt-40 py-10 px-2 item-center text-center z-0 sm:max-w-[1900px] m-auto w-[100%] font-poppins text-[#3B3939]'>
            <h1 class="font-medium leading-tight text-5xl mt-0 mb-2">Under Maintenance</h1>
            <h2 class="font-medium leading-tight text-4xl mt-0 mb-2">We are under planned maintenance, will be back soon.</h2>
        </div>
    );
}