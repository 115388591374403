import React from 'react';
import Breadcrumb from '../ReusableGeneric/Breadcrumb';
import {quickLinks} from "../Constants"

export default function QuickLinksTemp({ currentPageTitle, headerContent, bodyContent }) {
    return (
        <div className='p-0 overflow-hidden pt-20 sm:pt-32 bg-white sm:max-w-[1900px] m-auto w-[100%] font-poppins'>
            <Breadcrumb parent="Quick Links" navSequenceArray={quickLinks} currentPage={currentPageTitle} />
            <div id="top-wrapper" className='w-full'>
                {headerContent}
            </div>

            <div id="bottom-wrapper" className="py-2 sm:flex flex-col gap-8 w-full h-full items-center justify-center">
                {bodyContent}
            </div>
        </div>
    )
}