import React from 'react';

export default function CardSvgText({ imgUrl, content }) {
    return (
        <div class="card w-full bg-white sm:drop-shadow-xl h-auto sm:h-20 p-0 sm:shadow-inner text-[#707070]">
            <div class="card-body p-2 flex flex-col sm:flex-row items-center place-items-center">
                <img src={imgUrl} alt="text" className='w-28 sm:w-12' />
                <p className='items-start text-center sm:text-left text-md font-normal'>{content}</p>
            </div>
        </div>
    );
}

