import React from 'react';
import Breadcrumb from '../ReusableGeneric/Breadcrumb';
import {aboutAgribazaarNavigation} from "../Constants"

export default function AboutAgribazaarTemp({ currentPageTitle, headerContent, bodyContent }) {
    return (
        <div className='p-0 overflow-hidden w-auto pt-20 sm:pt-32 z-0 bg-white font-poppins'>
            <Breadcrumb parent="About agribazaar" navSequenceArray={aboutAgribazaarNavigation} currentPage={currentPageTitle} />
            <div id="top-wrapper" className='w-full'>
                {headerContent}
            </div>

            <div id="bottom-wrapper" className="py-10 sm:flex flex-col gap-8 w-full h-full items-center justify-center">
                {bodyContent}
            </div>
        </div>
    )
}