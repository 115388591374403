import React from 'react';
import OurServicesTemp from '../../components/OurServicesTemplate/OurServicesTemp';
import { InputOutputMarketplaceObj } from "./OurServicesConstants";
import SimpleCardTextText from "../../components/ReusableGeneric/SimpleCardTextText";
import DiscloseWText from "../../components/ReusableGeneric/DiscloseWText";

export default function InputOutputMarketplace() {
    const headerContent = () => {
        return (
            <div id="top-data" style={{ backgroundImage: 'url(' + InputOutputMarketplaceObj.topImgUrl + ')' }} className={`w-full relative bg-cover bg-center`}>
                <div className='p-20 text-center flex items-center place-items-center sm:hidden' />
                <div className='py-20 px-6 text-start hidden sm:flex items-start text-white'>
                    <div className='flex flex-col gap-2 p-0'>
                        <p className='font-medium text-5xl'>Input & Output marketplace</p>
                        <div className='font-normal text-xl'>One-stop solution to buy inputs. <br /> Trade your quality crops at the best prices.</div>
                    </div>
                </div>
            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div id="body-data" className='px-4 sm:px-8'>
                <div className='sm:hidden'>
                    <div className='text-center flex items-start text-[#3B3939]'>
                        <div className='flex flex-col gap-2 p-0'>
                            <p className='font-semibold text-2xl'>Input & Output marketplace</p>
                            <div className='pt-2 font-normal text-lg '>One-stop solution to buy inputs. Trade your quality crops at the best prices.</div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col sm:flex-row justify-center items-start gap-10 sm:pl-3 sm:pr-3 text-[#3B3939]'>
                    <div id="left" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div className='grid grid-cols-1 gap-2 items-baseline'>
                            <div className='pt-4 text-xl sm:text-xl font-semibold  sm:h-16'>Buy or sell at your terms!</div>
                            <div className='pt-0 text-md sm:text-md font-normal sm:h-20 sm:w-[94%]'>We’re establishing a modern ecosystem for commodity trading and redefining the value chain in commodities.</div>
                            <img src={InputOutputMarketplaceObj.middleLeftImg} alt="Buy or sell" className='hidden sm:block h-60' />
                        </div>
                    </div>
                    <div id="right" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div className='grid grid-cols-1 gap-2 items-baseline'>
                            <div className='pt-0 sm:pt-4 text-xl sm:text-xl font-semibold sm:h-16'>Enabling seamless purchases at your fingertips.</div>
                            <div className='pt-0 text-md sm:text-md font-normal sm:h-20 sm:w-[94%]'>Now buy stock at the best market price by creating your own trade and negotiating directly with the counter-party.</div>
                            <img src={InputOutputMarketplaceObj.middleRightImg} alt="seamless purchases at your fingertips" className='hidden sm:block h-60' />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const bottomContent = () => {
        const contentCard1 = () => {
            return (
                <ul class="list-disc list-inside marker:text-[#4165B3] marker:text-xl leading-loose" style={{height: "45.3vh"}}>
                    <li>We’re committed to delivering the best prices and services for commodity traders.</li>
                    <li>Our easy-to-use platform is designed to allow buyers to directly negotiate with farmers and realize higher transparency in their supply chain.</li>

                </ul>
            )
        }
        const contentCard2 = () => {
            return (
                <ul class="list-disc list-inside marker:text-[#4165B3] marker:text-xl leading-loose"  style={{height: "40vh"}}>
                    <li>Our laser-focused vision empowers farmers by delivering efficiency, transparency, and traceability in the agri value chain.</li>
                    <li>Farmers can create their trade terms and directly negotiate with buyers on our app. We also take care of transportation to drive higher efficiency in the agri value chain.</li>
                </ul>
            )
        }
        const contentCard3 = () => {
            return (
                <ul class="list-disc list-inside marker:text-[#4165B3] marker:text-xl leading-loose"  style={{height: "40vh"}}>
                    <li>Without intermediaries in our transparent agri-trade ecosystem, our stakeholders can achieve better.</li>
                    <li>We help our agri community get instant & secure digital payment solutions based on the quality and quantity of produce.</li>
                </ul>
            )
        }
        return (
            <div id="bottom-data" className='px-4 sm:px-8 text-[#3B3939]'>
                <div className='hidden py-6 px-2 text-left sm:grid grid-cols-3 gap-8 items-baseline justify-start text-[#3B3939]'>
                    <SimpleCardTextText title="Create your trade on your terms." content={contentCard1()} bodyHeight={80} />
                    <SimpleCardTextText title="Boosting sales with transparency and technology." content={contentCard2()} bodyHeight={80} />
                    <SimpleCardTextText title="Indisputable and easy access to better and fairer negotiations." content={contentCard3()} bodyHeight={80} />
                </div>
                <div className='sm:hidden pt-4 px-2'>
                    <DiscloseWText title="Create your trade on your terms." content={contentCard1()}/>
                    <DiscloseWText title="Boosting sales with transparency and technology." content={contentCard2()}/>
                    <DiscloseWText title="Indisputable and easy access to better and fairer negotiations." content={contentCard3()}/>
                </div>
            </div>
        )
    }
    return (
        <div>
            <OurServicesTemp currentPageTitle={InputOutputMarketplaceObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} bottomContent={bottomContent()} />
        </div>
    );
}

