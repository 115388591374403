
const aboutAmit = () => {
    return (
        <div>
            <div>
                Amith Agarwal is the Co-founder and CEO of agribazaar. He intimately understands technology’s potential to solve the challenges facing Indian agriculture. Always ahead of the curve, the startup marathoner recognised that the sector was poised for its next digital disruption and co-founded agribazaar in 2016. His work is focused on creating enduring value for the entire agri ecosystem.
            </div>
            <div>
                Amith’s passion to uplift the Indian farming community drives all his pursuits. It has since expanded into the vision to serve the entire farm-to-fork value chain. He has led some of the most transformative agri startups throughout his career, including StarAgri Warehousing & Collateral Management, which he co-founded in 2006. Amith studied MBA in Mumbai, completed his executive education at Harvard Business School, and figured in the Young Achievers 40 Under 40 list.
            </div>

        </div>
    )
}

export default aboutAmit();