import React from "react"


const homeIcon = () => {
    return (
        <svg className="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
    )
}
const rightArrow = () => {
    return (
        <svg className="w-5 h-5 text-gray-400" fill="gray" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
    )
}
export default function Breadcrumb({ parent, currentPage, navSequenceArray }) {
    const currentPageIndex = navSequenceArray?.findIndex(tar => tar.name === currentPage);

    return (
        <nav className="font-poppins flex pl-6 pb-4 pt-4 sm:pt-0" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 md:space-x-3">
                <li className="inline-flex items-center">
                    <a href="/" className="inline-flex items-center text-xs font-medium text-[#3B3939]">
                        {homeIcon()} Home
                    </a>
                </li>
                <li>
                    <div className="flex items-center text-xs font-medium text-[#3B3939]">
                        {rightArrow()}{parent}
                    </div>
                </li>
                {
                    navSequenceArray?.slice(0, currentPageIndex).map((val, index) => (
                        <a href={val.href} key={`_${val.name}_${index}`} className="hidden sm:flex">
                            <div className="flex items-center text-xs font-medium text-[#3B3939]">
                                {rightArrow()}{val.name}
                            </div>
                        </a>
                    ))
                }
                {currentPage && (<li aria-current="page">
                    <div className="flex items-center text-xs font-medium text-gray-500 cursor-not-allowed">
                        {rightArrow()} {currentPage}
                    </div>
                </li>)}
            </ol>
        </nav>
    );
}

