import React from 'react';
import CareersTemp from '../../components/CareersTemplate/CareersTemp';
import { whyagribazaarObj } from "./CareersConstants";
import ReadMoreReadLess from '../../components/ReusableGeneric/ReadMoreReadLess';


export default function WhyAgribazaar() {
    const headerContent = () => {
        return (
            <div className='flex flex-col sm:flex-row gap-12 sm:gap-4 justify-between'>
                <div className="wrapper  antialiased text-[#3B3939] w-full sm:w-1/2">
                    <div>
                        <img src={whyagribazaarObj.topLeftImg} alt="agriculture meets technology" className="bg-contain w-full h-56 sm:h-72 object-center  shadow-md" />
                        <div className="relative px-4 -mt-16  flex justify-center text-center sm:text-left">
                            <div className="bg-white p-4 w-80 sm:w-full mx-10 sm:h-64 shadow-lg border-4">
                                <h4 className=" text-2xl font-bold leading-tight text-[#ed1849]">Where agriculture meets technology</h4>
                                <div className="hidden sm:block pt-2 text-sm italic text-[#696969] sm:pr-2">
                                    <p>From the farm to the fork, crops have a long journey to undertake. At Agribazaar, we challenge ourselves every day to bring digital efficiency to this journey. Here’s how: by empowering farmers to track crop health remotely using a smartphone or checking soil quality before sowing or getting timely weather-based advisory to protect.</p>
                                </div>
                                <div className="sm:hidden pt-2 text-sm italic text-[#696969] sm:pr-2">
                                    
                                    <ReadMoreReadLess
                                        lessContent={<p>From the farm to the fork, crops have a long journey to undertake.</p>}
                                        moreContent={<p>From the farm to the fork, crops have a long journey to undertake. At Agribazaar, we challenge ourselves every day to bring digital efficiency to this journey. Here’s how: by empowering farmers to track crop health remotely using a smartphone or checking soil quality before sowing or getting timely weather-based advisory to protect.</p>}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper antialiased text-[#696969] w-full sm:w-1/2">
                    <div>
                        <img src={whyagribazaarObj.topRightImg} alt="transform agriculture through technology" className="bg-contain w-full h-56 sm:h-72  object-center shadow-md " />
                        <div className="relative px-4 -mt-16  flex justify-center text-center sm:text-left">
                            <div className="bg-white p-4 w-80 sm:w-full mx-10 sm:h-64 shadow-lg border-4 flex flex-col place-content-evenly">
                                <div className="pt-2 text-xl sm:text-2xl italic sm:pr-8">
                                    <p>We’re driven by a fundamental belief to transform agriculture through technology.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div className='w-full px-0 py-8 text-center sm:text-left text-[#3B3939]'>
                <div className="flex items-center w-full py-6 bg-cover card">
                    <div className='flex flex-col items-center  py-4'>
                        <div className=' text-lg sm:text-xl px-6 sm:px-20 font-medium'>
                            <p>It’s an opportunity we’re harnessing to change farming as we know it. And we’re nurturing the best talent to drive Agribazaar forward. If you’re excited about working with some of the sharpest technology minds and agribusiness visionaries to cultivate the best ideas for agriculture, Agribazaar is the right platform for you.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <CareersTemp currentPageTitle={whyagribazaarObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} />
        </div>
    );
}

