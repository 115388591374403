import React from 'react';
import OurServicesTemp from '../../components/OurServicesTemplate/OurServicesTemp';
import { IntelGoodSettlementServicesObj } from "./OurServicesConstants";
import SimpleCardTextText from "../../components/ReusableGeneric/SimpleCardTextText";
import DiscloseWText from "../../components/ReusableGeneric/DiscloseWText";

export default function IntelGoodSettlementServices() {
    const headerContent = () => {
        return (
            <div id="top-data" style={{ backgroundImage: 'url(' + IntelGoodSettlementServicesObj.topImgUrl + ')' }} className={`w-full relative bg-cover bg-center`}>
                <div className='p-20 text-center flex items-center place-items-center sm:hidden' />
                <div className='py-20 px-6 text-start hidden sm:flex items-start text-white'>
                    <div className='flex flex-col gap-2 p-0'>
                        <p className='font-medium text-5xl'>Intelligent goods & <br /> settlement services</p>
                        <div className='font-normal text-xl'>Smooth trading with simple & secure payments <br /> and dispute-free settlement mechanism.</div>
                    </div>
                </div>
            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div id="body-data" className='px-4 sm:px-8'>
                <div className='sm:hidden'>
                    <div className='text-center flex items-start text-[#3B3939]'>
                        <div className='flex flex-col gap-2 p-0'>
                            <p className='font-semibold text-2xl'>Intelligent goods & settlement services</p>
                            <div className='pt-2 font-normal text-lg '>Smooth trading with simple & secure payments and dispute-free settlement mechanism.</div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col sm:flex-row justify-center items-start gap-10 sm:pl-3 sm:pr-3 text-[#3B3939]'>
                    <div id="left" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div className='grid grid-cols-1 gap-2 items-baseline '>
                            <div className='pt-4 text-xl sm:text-xl font-semibold  sm:h-20'>Smart settlement procedure for convenience & confidence.</div>
                            <div className='pt-0 text-md sm:text-md font-normal sm:h-32 sm:w-[94%]'>agribazaar’s settlement procedure is unerring due to trained personnel at the delivery location for collecting the samples, sealing and marking them with utmost care.</div>
                            <img src={IntelGoodSettlementServicesObj.middleLeftImg} alt="smart settlement" className='hidden sm:block h-60' />
                        </div>
                    </div>
                    <div id="right" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div className='grid grid-cols-1 gap-2 items-baseline '>
                            <div className='pt-0 sm:pt-4 text-xl sm:text-xl font-semibold sm:h-20'>How does it work?</div>
                            <div className='pt-0 text-md sm:text-md font-normal sm:h-32 sm:w-[94%]'>The buyer acknowledges the quality, and it is reported back in case of a dispute. An independent lab tests the samples, and once the buyer acknowledges, payment is released to the seller or bank, depending on the arrangement.</div>
                            <img src={IntelGoodSettlementServicesObj.middleRightImg} alt="how it works" className='hidden sm:block h-60' />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const bottomContent = () => {
        const cardContent = () => {
            return (
                <div className='text-[#3B3939] pt-4 sm:pt-0'>
                    <p className='text-center sm:text-left'>At agribazaar, our platform offers transparent end-to-end settlement options for buyers and sellers.</p>
                    <ul class="pt-4 list-disc list-inside marker:text-[#4165B3] marker:text-xl leading-loose">
                        <li>We leverage technology to help our buyers track goods at every step of the way.</li>
                        <li>Our holistic approach to settlement ensures complete ownership of dispute handling, quick goods delivery, and quality assurance.</li>
                        <li>agribazaar brings secure payments and an integrated stock management mechanism to buyers' fingertips.</li>
                    </ul>
                    <p className='pt-4 text-center sm:text-left'>No more concern for your payments and stock deliveries. Our settlement procedure covers all your issues, including dispute mechanism, payment security, quality assurance, and speedy delivery of your stocks.</p>
                </div>
            )
        }
        return (
            <div id="bottom-data" className='px-2 sm:px-8 text-[#3B3939]'>
                <div className='hidden sm:block py-6 px-2 text-left justify-start'>
                    <SimpleCardTextText title="Augmenting value chain efficiencies with transparent, secure settlements." content={cardContent()} bodyHeight={80} />
                </div>
                <div className='sm:hidden pt-4 px-4'>
                    <DiscloseWText title="Augmenting value chain efficiencies with transparent, secure settlements." content={cardContent()} />
                </div>
            </div>
        )
    }
    return (
        <div>
            <OurServicesTemp currentPageTitle={IntelGoodSettlementServicesObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} bottomContent={bottomContent()} />
        </div>
    );
}

