import React from 'react';
import OurServicesTemp from '../../components/OurServicesTemplate/OurServicesTemp';
import { AgriFintechObj } from "./OurServicesConstants";
import SimpleCardTextText from "../../components/ReusableGeneric/SimpleCardTextText";
import DiscloseWText from "../../components/ReusableGeneric/DiscloseWText";

export default function AgriFintech() {
    const headerContent = () => {
        return (
            <div id="top-data" style={{ backgroundImage: 'url(' + AgriFintechObj.topImgUrl + ')' }} className={`w-full relative bg-cover bg-center`}>
                <div className='p-20 text-center flex items-center place-items-center sm:hidden' />
                <div className='py-20 px-6 text-start hidden sm:flex items-start text-white'>
                    <div className='flex flex-col gap-2 p-0'>
                        <p className='font-medium text-5xl'>Agri fintech services</p>
                        <div className='font-normal text-xl'>Envisioning a world free of financial burden <br /> by democratizing lending credits for farmers.</div>
                    </div>
                </div>
            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div id="body-data" className='px-4 sm:px-8'>
                <div className='sm:hidden'>
                    <div className='text-center flex items-start text-[#3B3939]'>
                        <div className='flex flex-col gap-2 p-0'>
                            <p className='font-semibold text-2xl'>Agri fintech services</p>
                            <div className='pt-2 font-normal text-lg '>Envisioning a world free of financial burden by democratizing lending credits for farmers.</div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col sm:flex-row justify-center items-start gap-6 sm:gap-10 sm:pl-3 sm:pr-3 text-[#3B3939]'>
                    <div id="left" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div className='grid grid-cols-1 gap-2 items-baseline'>
                            <div className='pt-4 text-xl sm:text-xl font-semibold  sm:h-14'>The Digital Financial Solution for farmers</div>
                            <div className='pt-0 text-md sm:text-md font-normal sm:h-24 sm:w-[94%]'>Integrated platform for financial institutions; facility for participants to avail customized finance. Seamlessly integrated products by NBFCs and banks to provide apt solutions and offer better-blended rates.</div>
                            <img src={AgriFintechObj.middleLeftImg} alt="Digital Financial Solution" className='hidden sm:block h-60' />
                        </div>
                    </div>
                    <div id="right" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div className='grid grid-cols-1 gap-2 items-baseline'>
                            <div className='pt-0 sm:pt-4 text-xl sm:text-xl font-semibold sm:h-14'>Agri Finance</div>
                            <div className='pt-0 text-md sm:text-md font-normal sm:h-24 sm:w-[94%]'>Correct input at the right time makes a massive difference in crop production. Our goal is to bring micro-financing for input purchase in partnership with leading agro financing institutions of the country.</div>
                            <img src={AgriFintechObj.middleRightImg} alt="Agri Finance" className='hidden sm:block h-60' />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const bottomContent = () => {
        const contentCard1 = () => {
            return (
                <div style={{height: "23vh"}}>
                    <p>A finance delivery mechanism to meet the financial needs of the farmers during the crop season in a timely and hassle-free manner by giving financial support to them.</p>
                </div>
            )
        }
        const contentCard2 = () => {
            return (
                <div style={{height: "23vh"}}>
                    <p>We are working closely with leading agro insurance companies in the country to bring the right insurance products to benefit the farmers in our network.</p>
                </div>
            )
        }
        const contentCard3 = () => {
            return (
                <div style={{height: "23vh"}}>
                    <p>We have built an ecosystem in which multiple players in the value chain benefit from improved productivity and profitability with banking services. </p>
                </div>
            )
        }
        return (
            <div id="bottom-data" className='px-2 sm:px-8 text-[#3B3939]'>
                <div className='hidden py-6 px-2 text-left sm:grid grid-cols-3 gap-8 items-baseline justify-start text-[#3B3939]'>
                    <SimpleCardTextText title="Kisan Safalta Card (KSC)" content={contentCard1()} bodyHeight={52} />
                    <SimpleCardTextText title="Crops Insurance" content={contentCard2()} bodyHeight={52} />
                    <SimpleCardTextText title="Agri Banking" content={contentCard3()} bodyHeight={52} />
                </div>
                <div className='sm:hidden pt-4 px-4'>
                    <DiscloseWText title="Kisan Safalta Card (KSC)" content={contentCard1()} />
                    <DiscloseWText title="Crops Insurance" content={contentCard2()} />
                    <DiscloseWText title="Agri Banking" content={contentCard3()} />
                </div>
            </div>
        )
    }
    return (
        <div>
            <OurServicesTemp currentPageTitle={AgriFintechObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} bottomContent={bottomContent()} />
        </div>
    );
}

