import React from 'react';
import OurProductsTemp from '../../components/OurProductsTemplate/OurProductsTemp';
import { AgriknowObj } from "./OurProductsConstants";
import CardArrowText from "../../components/ReusableGeneric/CardArrowText";

export default function Agriknow() {
    const headerContent = () => {
        return (
            <div id="top-data" style={{ backgroundImage: 'url(' + AgriknowObj.topImgUrl + ')' }} className={`h-auto sm:h-64 w-full relative bg-cover bg-center`}  >
                <div className='p-20 text-center flex items-center place-items-center sm:hidden' />
            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div id="body-data" className='px-2 sm:px-4 pb-4'>
                <div className='flex flex-col sm:flex-row justify-center items-start gap-10 sm:pl-3 sm:pr-3 text-[#3B3939]'>
                    <div id="left" className='w-full sm:w-2/4 text-center sm:text-left'>
                    <div className='font-bold text-3xl sm:text-4xl m-auto pt-2'>Agriknow(GAP)</div>
                        <div className='pt-4 text-lg sm:text-xl font-medium sm:pr-52'>India’s emerging crop advisory platform for 700+ agri commodities across country!</div>
                        <div className='pt-4 text-md sm:text-md font-normal sm:pr-4'>Our satellite-based crop health monitoring reports provide exhaustive inputs on commodity crops and crop management techniques.</div>
                        <div>
                            <div className='pt-4 text-lg sm:text-lg font-medium'>Worried about the state of crops when you're not looking?</div>
                            <div className='pt-2 text-md sm:text-md font-normal'>Based on satellite images, crop monitoring helps farmers to manage multiple fields, cut costs on resources and take reliable decisions. Big data covers big issues.</div>
                        </div>
                            <div className='pt-4 grid grid-rows-1 sm:grid-cols-2 gap-4 pl-4'>
                                <CardArrowText content="Easy-to-read maps" />
                                <CardArrowText content="Vegetation indices" />
                                <CardArrowText content="Data-driven decisions" />
                                <CardArrowText content="Field leaderboard" />
                                <CardArrowText content="AI cloud mask" />
                            </div>
                        <img className='hidden sm:block pt-12' src={AgriknowObj.bottomLeftImg} alt="agriknow(GAP)" />
                    </div>
                    <div id="right" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div>
                            <div className='pt-4 text-xl font-semibold'>The right amount of fertilizers & pesticides with timely irrigation</div>
                            <div className='pt-2 text-md sm:text-md font-normal'>AgriKnow provides you specific information on required soil type & climate, NPK & pH levels with the necessary knowledge regarding farm management and crop health.</div>
                        </div>
                        <div>
                            <div className='pt-4 text-xl font-semibold'>Proactive measures on disease control</div>
                            <div className='pt-2 text-md sm:text-md font-normal'>Powered by artificial intelligence, AgriKnow disseminates actionable insights on crop quality, pest infestations, and plant diseases.</div>
                        </div>
                        <div>
                            <div className='pt-4 text-xl font-semibold'>We take care of your crop health with you at every stage</div>
                            <div className='pt-2 text-md sm:text-md font-normal'>We develop advanced technologies to offer real-time crop advisory with farmers. Using big data, we analyze historical weather data and share real-time farming advice.</div>
                        </div>
                        <div>
                            <div className='pt-4 text-xl font-semibold'>Data-driven smart farming with AgriKnow</div>
                            <div className='pt-2 text-md sm:text-md font-normal'>We use farm-level data to predict ideal growth conditions and requirements - including irrigation, sprays, and other preventive measures and notify farmers to make informed decisions.</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <OurProductsTemp currentPageTitle={AgriknowObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} />
        </div>
    );
}
