import React from 'react';

export default function SimpleCardTextText({ title, content, bodyHeight }) {
    return (
        <div class={`rounded-lg border-0 border-gray-200 shadow-md bg-[#FBF4E9] h-${bodyHeight}`}>
            <div class="p-5 bg-[#FAEBD3]">
                <h5 class="tracking-tight text-xl font-medium text-left">{title}</h5>
            </div>
            <div class="p-5 h-max prose">
                <h5 class="tracking-tight text-md font-normal text-left">{content}</h5>
            </div>
        </div>
    );
}

