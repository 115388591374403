import { aboutAgribazaarNavigation, ourTeamList } from "../../components/Constants";

export const foundersMessageObj = {
    navSeq: aboutAgribazaarNavigation[0].name,
    topImgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/aboutAgribazaar/foundersMessage-top.png",
    firstParaImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/aboutAgribazaar/foundersMessage-secondPara.png",
    topTitle: `To quote Honorable Prime Minister Narendra Modi, “Agriculture has a key role to play in helping the country achieve its goal of becoming a $ 5 trillion economy.”`,
    teamMembersData: ourTeamList,
    svgCards: [
        {
            url: "https://abazaar.s3-ap-south-1.amazonaws.com/images/aboutAgribazaar/fm-1.svg",
            content: "World-class open source technologies and know-how to transform the agri value chain."
        },
        {
            url: "https://abazaar.s3-ap-south-1.amazonaws.com/images/aboutAgribazaar/fm-2.svg",
            content: "Focus on novel solutions in areas of farmers’ unmet needs."
        },
        {
            url: "https://abazaar.s3-ap-south-1.amazonaws.com/images/aboutAgribazaar/fm-3.svg",
            content: "Build partnerships with governments and farmer collectives to enable the widest possible access to our platform."
        }
    ]
};

export const ourTeamObj = {
    navSeq: aboutAgribazaarNavigation[1].name,
    teamList: ourTeamList
};

export const ourVisionObj = {
    navSeq:aboutAgribazaarNavigation[2].name,
    topLeftImg:"https://abazaar.s3-ap-south-1.amazonaws.com/images/aboutAgribazaar/ourVision-TL.png",
    topRightImg:"https://abazaar.s3-ap-south-1.amazonaws.com/images/aboutAgribazaar/ourVision-TR.png",
    bottomImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/aboutAgribazaar/ourVision-bottom.svg",
    reapList: [
        {
            heading:"R-adical innovation",
            content:"Harness future-tech to innovate and disrupt the way buyers and growers engage in the agri-marketplace"
        },
        {
            heading:"E-xecution",
            content:"Act with speed, internal mobilization and responsiveness to accelerate our time-to-market"
        },
        {
            heading:"A-gility",
            content:"Adapt decisively and efficiently to drive change in agriculture with next-gen technologies"
        },
        {
            heading:"P-urpose",
            content:"Empower stakeholders across the agri value chain to enhance small farmowners’ incomes in a responsible, resourceful and reliable"
        },
    ]
};

export const ourStoryObj = {
    navSeq:aboutAgribazaarNavigation[3].name,
    topLeftImg:"https://abazaar.s3-ap-south-1.amazonaws.com/images/aboutAgribazaar/ourStory-TL.webp",
    topRightImg:"https://abazaar.s3-ap-south-1.amazonaws.com/images/aboutAgribazaar/ourStory-TR.webp",
    firstParaImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/aboutAgribazaar/ourStory-firstPara.webp",
    secondParaImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/aboutAgribazaar/ourStory-secondPara.png"
};
