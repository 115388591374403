import React, { useState } from 'react';
import axios from "axios";
import appConfig from '../../config';

const crossIcon = (fillColor) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill={fillColor}>
            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
    )
}
const crossIcon2 = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="black">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
        </svg>
    )
}
export default function JobsCard({ jobObj }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedJobCardData, setselectedJobCardData] = useState({});

    const {
        jobId,
        jobTitle,
        positionType,
        jobLocation,
        jobType,
        jobPost,
        applyNowLink
    } = jobObj;

    const formatDateDaysAgo = (date1) => {
        const dt_date1 = new Date(date1);
        const dt_date2 = new Date();
        var date1 = dt_date1.getTime();
        var date2 = dt_date2.getTime();
        var calc;
        if (date1 > date2) {
            calc = new Date(date1 - date2);
        } else {
            calc = new Date(date2 - date1);
        }
        var calcFormatTmp = calc.getDate() + '-' + (calc.getMonth() + 1) + '-' + calc.getFullYear();
        var calcFormat = calcFormatTmp.split("-");
        var days_passed = parseInt(Math.abs(calcFormat[0]) - 1);
        var months_passed = parseInt(Math.abs(calcFormat[1]) - 1);
        var years_passed = parseInt(Math.abs(calcFormat[2] - 1970));
        const yrsTxt = ["year ", "years"];
        const mnthsTxt = ["month ", "months"];
        const daysTxt = ["day ", "days"];
        const result = ((years_passed === 1) ? years_passed + ' ' + yrsTxt[0] + ' ' : (years_passed > 1) ?
            years_passed + ' ' + yrsTxt[1] + ' ' : '') +
            ((months_passed === 1) ? months_passed + ' ' + mnthsTxt[0] : (months_passed > 1) ?
                months_passed + ' ' + mnthsTxt[1] + ' ' : '') +
            ((days_passed === 1) ? days_passed + ' ' + daysTxt[0] : (days_passed > 1) ?
                days_passed + ' ' + daysTxt[1] : '');
        return result === "" ? "Posted today" : `Posted ${result} ago`
    }

    const getJobDetails = async () => {
        setselectedJobCardData({});
        const res = await axios.get(
            `${appConfig.domain[appConfig.environment]["agribazaar"]}/agribazaarJobDetails.php?id=${jobId}`
        );
        if (res.data.status) {
            setselectedJobCardData(res.data?.data[0]);
            setIsModalOpen(true);
        }
        else setIsModalOpen(false);
    }

    const handleApplyNow = () => {
        if (jobObj) {
            localStorage.clear();
            localStorage.setItem("applyingFor", JSON.stringify(jobObj));
            window.open("/careers/career-opportunities/apply", "_blank");
        } else alert("some error occured, please try again later!");
    }

    const modalContent = () => {
        const {
            jobTitle: jobTitle_C,
            positionType: positionType_C,
            jobCategory: jobCategory_C,
            jobLocation: jobLocation_C,
            jobType: jobType_C,
            salaryRange: salaryRange_C,
            experience: experience_C,
            jobPost: jobPost_C,
            jobdescription: jobdescription_C,
            jobStatus: jobStatus_C
        } = selectedJobCardData;
        return (
            <div className='z-10 fixed'>
                <div className="sm:block">
                    <div className="p-8 pr-14 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="w-[100%] overflow-y-scroll sm:w-[70%]  2xl:pt-12 2xl:pb-12 h-fit pt-0 card card-side shadow-2xl bg-white text-[#3B3939]">
                            <div className="card-body p-6 px-8 h-[90vh] text-center sm:text-left">
                                <div className='relative card-actions flex justify-end pr-0 bg-white h-0'
                                    onClick={() => {
                                        setselectedJobCardData({});
                                        setIsModalOpen(false);
                                    }}>
                                    <button className="hidden sm:block btn btn-square btn-sm bg-transparent border-0 hover:bg-transparent hover:border-0">
                                        {crossIcon('black')}
                                    </button>
                                    <button className="sm:hidden btn btn-square btn-sm bg-transparent border-0 hover:bg-transparent hover:border-0">
                                        {crossIcon2()}
                                    </button>
                                </div>
                                <div className='text-[#3B3939]'>
                                    <div className='text-sm sm:text-lg font-semibold'>ABOUT US</div>
                                    <div className='pt-2 text-sm sm:text-sm font-normal'>
                                        <div className='text-[#4165b3] font-medium text-xs sm:text-md italic'>
                                            Empowering millions to feed billions
                                        </div>
                                        At Agribazaar, we’re transforming the agri value chain through technology. For small farmowners, we enable digital access to a wider marketplace. For buyers, we assure quality whilst mitigating counterparty risk and enabling secure payments.
                                    </div>
                                </div>
                                <div className='text-[#3B3939] pt-4'>
                                    <div className='text-sm sm:text-lg font-semibold'>ROLE DESCRIPTION</div>
                                    <div className='pt-2 text-sm font-normal grid sm:grid-cols-2'>
                                        <div className='pt-2 text-sm font-medium'>
                                            Job category : {jobCategory_C}
                                        </div>
                                        <div className='pt-2 text-sm font-medium'>
                                            Experience required : {experience_C}
                                        </div>
                                        <div className='pt-2 text-sm font-medium'>
                                            Salary range : {salaryRange_C}
                                        </div>
                                        <div className='pt-2 text-sm font-medium'>
                                            Job title : {jobTitle_C}
                                        </div>
                                        <div className='pt-2 text-sm font-medium'>
                                            Technology required : {positionType_C}
                                        </div>
                                        <div className='pt-2 text-sm font-medium'>
                                            Job Location : {jobLocation_C}
                                        </div>
                                        <div className='pt-2 text-sm font-medium'>
                                            Job Type : {jobType_C}
                                        </div>
                                    </div>
                                    <div className='pt-2 text-sm font-medium'>
                                       Posted on :  {new Date(jobPost_C).toLocaleDateString('en-us', { day: "numeric", month: "long", year: "numeric" })}
                                    </div>
                                </div>
                                <div className='text-[#3B3939] pt-4'>
                                    <div className='text-lg font-semibold'>JOB SUMMARY</div>
                                    <div className='pt-2 text-sm font-normal'>{jobdescription_C}</div>
                                </div>
                                <div className='text-[#3B3939] font-medium text-sm pt-4'>
                                    Job Status: {jobStatus_C}
                                </div>
                                <div className='pt-2 pb-8'>
                                    <button className="w-max max-w-min whitespace-nowrap inline-flex justify-start p-2 m-auto border border-transparent rounded-md shadow-sm text-xs sm:text-base text-white bg-[#ED1849]"
                                        onClick={handleApplyNow}>
                                        Apply Now
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </div>

            </div>
        )
    }

    return (
        <div className="w-full sm:px-4 pb-6 px-0 text-[#3B3939]">
            {isModalOpen && modalContent()}
            <div className="p-1 mx-auto rounded-md bg-[#FFFDF9] drop-shadow-md border-2 border-[#F7D39D]">
                <div className='w-full flex flex-row justify-between py-2 pl-4 pr-0 place-items-center gap-8'>
                    <div id="titles" className='tracking-wide text-sm sm:text-base'>
                        <div className='font-semibold leading-loose'>{jobTitle}</div>
                        <div className='text-[#3B3939]'>
                            {`${positionType && `(${positionType})`}`}
                            &nbsp;
                            {jobLocation}, {jobType}
                        </div>
                        {formatDateDaysAgo(new Date(jobPost).toLocaleDateString('en-us', { day: "numeric", month: "long", year: "numeric" }))}
                    </div>
                    <div id="refs" className='flex flex-col gap-2 justify-center pr-1 sm:pr-6 items-center'>
                        <button className='whitespace-nowrap flex items-center text-sm sm:text-base font-bold text-[#4165B3]'
                            onClick={() => getJobDetails()}>
                            SHOW MORE
                        </button>
                        <button className="w-max max-w-min whitespace-nowrap inline-flex justify-center p-2 m-auto border border-transparent rounded-md shadow-sm text-xs sm:text-base text-white bg-[#ED1849]"
                            onClick={handleApplyNow}>
                            Apply Now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

