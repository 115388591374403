import React from 'react';
import CareersTemp from '../../components/CareersTemplate/CareersTemp';
import ReadMoreReadLess from '../../components/ReusableGeneric/ReadMoreReadLess';
import { peoplephilosophyObj } from "./CareersConstants";

export default function PeoplePhilosophy() {
    const headerContent = () => {
        return (
            <div className='flex flex-col sm:flex-row gap-12 sm:gap-4 justify-between'>
                <div className="wrapper  antialiased text-[#3B3939] w-full sm:w-1/2">
                    <div>
                        <img src={peoplephilosophyObj.topLeftImg} alt="grow, thrive and succeed" className="bg-contain w-full h-56 sm:h-72 object-center  shadow-md" />
                        <div className="relative px-0 -mt-16  flex justify-center text-center sm:text-left">
                            <div className="bg-white p-4 w-80 sm:w-full mx-10 sm:h-60 shadow-lg border-4">
                                <h4 className=" text-2xl font-bold  leading-tight text-[#ed1849]">Where you can grow, <br /> thrive and succeed</h4>
                                <div className="hidden sm:block pt-2 text-sm italic text-[#696969]">
                                    <p>At Agribazaar, our people come together from across diverse walks of life, led by the belief that we can transform the lives of small farmowners with technology. We nurture high-caliber, energetic and forward-thinking individuals. People with a ‘can-do’ attitude thrive in our dynamic work environment.</p>
                                </div>
                                <div className="sm:hidden pt-2 text-sm italic text-[#696969]">
                                    <ReadMoreReadLess
                                        lessContent={<p>At Agribazaar, our people come together from across</p>}
                                        moreContent={<p>At Agribazaar, our people come together from across diverse walks of life, led by the belief that we can transform the lives of small farmowners with technology. We nurture high-caliber, energetic and forward-thinking individuals. People with a ‘can-do’ attitude thrive in our dynamic work environment.</p>}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper  antialiased text-[#3B3939] w-full sm:w-1/2">
                    <div>
                        <img src={peoplephilosophyObj.topRightImg} alt="people philosophy-HARVEST" className="bg-contain w-full h-56 sm:h-72  object-center shadow-md " />
                        <div className="relative px-0  -mt-16  flex justify-center text-center sm:text-left">
                            <div className="bg-white p-4 w-80 sm:w-full mx-10 sm:h-60 shadow-lg border-4 flex flex-col place-content-center">
                                <div className="pt-2 text-xl sm:text-2xl italic text-[#696969]">
                                    <p>Our people philosophy is centered around the principles of</p>
                                </div>
                                <h4 className="text-2xl font-bold  leading-tight truncate text-[#ed1849]">‘H - A - R - V - E - S - T’</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div id="body-data" className='w-full py-8'>
                <div className='max-w-full sm:flex flex-col justify-center items-center px-8 sm:px-0 pt-6 sm:pt-0'>
                    <img className='hidden sm:block' src={peoplephilosophyObj.bottomImg} alt="harvest" width="90%" />
                    <div className='flex flex-col sm:flex-row gap-4 sm:gap-4 text-[#d51a35] font-semibold text-xl text-center sm:px-20'>
                        {
                            peoplephilosophyObj.harvestSvgsList.map((item, index) => (
                                <div key={`_${item.heading}_${index}`} className='flex flex-col place-content-evenly items-center '>
                                    <div className='hidden sm:block w-14 h-16'><img src={item.svgUrl} alt={item.heading} width="100%" /></div>
                                    <h4 className='whitespace-nowrap sm:font-medium sm:text-sm sm:h-8'>{item.heading}</h4>
                                    <p className='text-[#696969] font-normal text-md sm:text-xs'>{item.content}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <CareersTemp currentPageTitle={peoplephilosophyObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} />
        </div>
    );
}

