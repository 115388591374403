import React from 'react';
import OurProductsTemp from '../../components/OurProductsTemplate/OurProductsTemp';
import { InputMarketplaceObj } from "./OurProductsConstants";
import CardArrowText from "../../components/ReusableGeneric/CardArrowText";

export default function InputMarketplace() {
    const headerContent = () => {
        return (
            <div id="top-data" style={{ backgroundImage: 'url(' + InputMarketplaceObj.topImgUrl + ')' }} className={`h-auto sm:h-64 w-full relative bg-cover bg-center`} >
                <div className='p-20 text-center flex items-center place-items-center sm:hidden' />
            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div id="body-data" className='px-2 sm:px-6 pb-4'>
                <div className='flex flex-col sm:flex-row justify-center items-start gap-6 sm:gap-10 pl-3 pr-3 text-[#3B3939]'>
                    <div id="left" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div className='font-bold text-3xl sm:text-4xl m-auto pt-2'>Input Marketplace</div>
                        <div className='pt-4 text-lg sm:text-xl font-medium'>500+ best quality farm inputs to support India grow better, faster and healthier!</div>
                        <div className='pt-4 sm:pr-32 text-lg sm:text-xl font-semibold'>Seed, Fertilizers, and so much more.</div>
                        <div className='pt-2 sm:pr-32 text-lg sm:text-lg font-medium'>Inputs that you need, all in one place.</div>
                        <div className='pt-2 sm:pr-2 text-md sm:text-md font-normal '>Input material at the beginning of farming cycle is the most important aspect that determines the quality and volume of the yield, & hence the income and livelihood of the farmer.</div>
                        <img className='hidden sm:block pt-10' src={InputMarketplaceObj.bottomLeftImg} alt="input Marketplace" />
                    </div>
                    <div id="right" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div>
                            <div className='sm:pt-4 text-lg sm:text-xl font-semibold'>At agribazaar, we channel energies.</div>
                            <div className='pt-2 text-md sm:text-md font-normal '>We direct our strengths to empower farmers with the latest tools at attractive prices.</div>
                        </div>
                        <div className='pl-0 pt-2 sm:pt-4 grid grid-cols-1 gap-4'>
                            <CardArrowText content="Our platform provides access to agricultural inputs, such as fertilizers, seeds, planting materials, and equipment at the touch of a button." />
                            <CardArrowText content="We strive to equip farmers with adequate information on the quality and best practices of the products they buy." />
                            <CardArrowText content="Our solution offers valuable insights into incorporating sustainable farming practices through organic inputs." />
                        </div>
                        <div className='pt-4'>
                            <div className='pt-4 text-lg sm:text-xl font-semibold'>Best quality products, just for you!</div>
                            <div className='pt-2 text-md sm:text-md font-normal '>Due to demand and supply mismatch, substandard locally manufactured products, duplication, adulteration, and black-marketing of critical products, some severe gaps in the agri-input value chain exist. We aim to provide the right kind & quality of agri input at the right time.</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <OurProductsTemp currentPageTitle={InputMarketplaceObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} />
        </div>
    );
}

