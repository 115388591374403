import React from 'react';
import OurProductsTemp from '../../components/OurProductsTemplate/OurProductsTemp';
import { AgripayObj } from "./OurProductsConstants";


export default function Agripay() {
    const headerContent = () => {
        return (
            <div id="top-data" style={{ backgroundImage: 'url(' + AgripayObj.topImgUrl + ')' }} className={`h-auto sm:h-64 w-full relative bg-cover bg-center`}  >
                <div className='p-20 text-center flex items-center place-items-center sm:hidden' />
            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div id="body-data" className='px-2 sm:px-4 pb-4'>
                <div className='flex flex-col sm:flex-row justify-center items-start gap-10 sm:pl-3 sm:pr-3 text-[#3B3939]'>
                    <div id="left" className='w-full sm:w-2/4 text-center sm:text-left'>
                    <div className='font-bold text-3xl sm:text-4xl m-auto pt-2'>Agripay</div>
                        <div className='pt-4 text-lg sm:text-xl font-medium sm:pr-52'>Transaction worth USD 1 billion!</div>
                        <div className='pt-0 text-lg sm:text-xl font-medium sm:pr-52'>Our award-winning payment mechanism.</div>
                        <div className='pt-4 text-md sm:text-md font-normal sm:pr-4'>Pay and receive payments via AgriPay with security and convenience throughout the agri value chain.</div>
                        <div>
                            <div className='pt-4 text-lg sm:text-xl font-semibold'>Payment for any business</div>
                            <div className='pt-2 text-md sm:text-md font-normal'>From buying and selling to purchasing agri inputs, we offer a complete stack for all your payments needs across the platform.</div>
                        </div>
                        <img className='hidden sm:block pt-12' src={AgripayObj.bottomLeftImg} alt="agripay" />
                    </div>
                    <div id="right" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div>
                            <div className='pt-4 text-xl font-semibold'>Get started within minutes</div>
                            <div className='pt-2 text-md sm:text-md font-normal'>Once you register on agribazaar, you get an AgriPay account within minutes and link multiple bank accounts to add or withdraw funds. We’ll deposit your funds directly into your bank account.</div>
                        </div>
                        <div>
                            <div className='pt-4 text-xl font-semibold'>Optimize your revenue with AgriPay</div>
                            <div className='pt-2 text-md sm:text-md font-normal'>With an error rate as low as 0.00008%, we protect you from fraud and increase authorization rates on every payment to ensure your data is always safe.</div>
                        </div>
                        <div>
                            <div className='pt-4 text-xl font-semibold'>We bring you security and compliance at the core</div>
                            <div className='pt-2 text-md sm:text-md font-normal'>AgriPay meets the highest certification standards to help reduce compliance burdens for your business and keep payments safe.</div>
                        </div>
                        <div>
                            <div className='pt-4 text-xl font-semibold'>We provide robust protection</div>
                            <div className='pt-2 text-md sm:text-md font-normal'>We’re the first B2B company in the agritech space, recognized by The Asset Triple A Awards, to have PCI-DSS Level 3 PCI Compliance certification.</div>
                        </div>
                        <div>
                            <div className='pt-4 text-xl font-semibold'>A completely isolated infrastructure</div>
                            <div className='pt-2 text-md sm:text-md font-normal'>AgriPay’s infrastructure for storing, decrypting, and transmitting card numbers runs in separate hosting infrastructure and doesn’t share any credentials with the primary services.</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <OurProductsTemp currentPageTitle={AgripayObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} />
        </div>
    );
}

