import React from 'react';
import AboutAgribazaarTemp from '../../components/AboutAgribazaarTemplate/AboutAgribazaarTemp';
import { foundersMessageObj } from "./AboutAgriConstants";
import CardSvgText from "../../components/ReusableGeneric/CardSvgText";
import CardTeamWCurvedBorder from "../../components/ReusableGeneric/CardTeamWCurvedBorder";
import ReadMoreReadLess from "../../components/ReusableGeneric/ReadMoreReadLess";


export default function FoundersMessage() {
    const amithAgarwal = foundersMessageObj.teamMembersData.filter(mem => mem.name === "Amith Agarwal")[0];
    const amitMundawala = foundersMessageObj.teamMembersData.filter(mem => mem.name === "Amit Goyal")[0];

    const headerContent = () => {
        return (
            <div id="top-data" style={{ backgroundImage: 'url(' + foundersMessageObj.topImgUrl + ')' }} className={`h-auto sm:h-96 w-full relative bg-cover bg-center`}>
                <div className='p-20 text-center flex items-center place-items-center text-4xl sm:text-5xl text-white font-bold m-auto'>
                    <p className='hidden sm:block'>{foundersMessageObj.topTitle}</p>
                </div>
            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div id="body-data" className='px-8'>
                <div className='text-center flex flex-col sm:flex-row justify-center items-center p-8'>
                    <div className='flex w-full sm:w-1/4'><CardTeamWCurvedBorder imgUrl={amithAgarwal.imgUrl} name={amithAgarwal.name} desig={amithAgarwal.desig} /> </div>
                    <div className='flex w-full sm:w-2/4 font-medium text-2xl text-gray-600 sm:px-8 py-10 sm:py-0'>We’re doing what others don’t, putting the power in small farmowners’ hands with technology.</div>
                    <div className='flex w-full sm:w-1/4'><CardTeamWCurvedBorder imgUrl={amitMundawala.imgUrl} name={amitMundawala.name} desig={amitMundawala.desig} /> </div>
                </div>
                <div style={{ backgroundImage: 'url(' + foundersMessageObj.firstParaImg + ')', backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
                    className='hidden sm:flex text-center sm:text-left flex-col sm:flex-row items-baseline gap-4 p-2 sm:p-6 text-[#3B3939]'>
                    <div className='flex w-full sm:w-2/4 font-normal text-md sm:text-sm'>At agribazaar, we believe new models of trading are required to meet the opportunities and challenges of modern agriculture. This is why our platform leverages cutting-edge AI for agri value chain optimization, resulting in increased yields and greater profit for small farmowners.</div>
                    <div className='flex w-full sm:w-2/4 font-normal text-md sm:text-sm'>Today, agriculture accounts for 18% of our economy’s output and 47% of our workforce. India is the second largest producer of fruits and vegetables in the world. Yet, according to the Food and Agriculture Organization (FAO) of the United Nations, 194 million Indians are undernourished, the largest number of hungry people in any single country.</div>
                    <div className='flex w-full sm:w-2/4 font-normal text-md sm:text-sm'>Addressing the problem requires improvements in the productivity and efficiency of the agriculture sector, particularly small farmowners. At agribazaar, we are bridging this fundamental gap, creating next generation agri-tech solutions for those facing the greatest need. This is why our business model is 100% aligned with small farmowners.</div>
                </div>
                <div style={{ backgroundImage: 'url(' + foundersMessageObj.firstParaImg + ')', backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
                    className='sm:hidden text-center sm:text-left flex flex-col sm:flex-row items-baseline gap-4 p-2 sm:p-6 text-[#707070]'>
                    <ReadMoreReadLess
                        lessContent={<div className='flex w-full sm:w-2/4 font-normal text-md sm:text-sm text-[#707070]'>At agribazaar, we believe new models of trading are required to meet the opportunities and challenges of modern agriculture. This is why our platform leverages cutting-edge AI for agri value chain optimization, resulting in increased yields and greater profit for small farmowners.</div>}
                        moreContent={<div className='text-[#707070]'>
                            <div className='flex w-full sm:w-2/4 font-normal text-md sm:text-sm'>At agribazaar, we believe new models of trading are required to meet the opportunities and challenges of modern agriculture. This is why our platform leverages cutting-edge AI for agri value chain optimization, resulting in increased yields and greater profit for small farmowners.</div>
                            <div className='flex w-full sm:w-2/4 font-normal text-md sm:text-sm'>Today, agriculture accounts for 18% of our economy’s output and 47% of our workforce. India is the second largest producer of fruits and vegetables in the world. Yet, according to the Food and Agriculture Organization (FAO) of the United Nations, 194 million Indians are undernourished, the largest number of hungry people in any single country.</div>
                            <div className='flex w-full sm:w-2/4 font-normal text-md sm:text-sm'>Addressing the problem requires improvements in the productivity and efficiency of the agriculture sector, particularly small farmowners. At agribazaar, we are bridging this fundamental gap, creating next generation agri-tech solutions for those facing the greatest need. This is why our business model is 100% aligned with small farmowners.</div>
                        </div>}
                    />
                </div>
                <div className='w-full font-bold text-xl sm:text-2xl px-0 sm:px-4 py-8 sm:py-8 text-center sm:text-left text-[#3B3939]'>
                    From our agri-business experience, we understand small farmowners face challenges disproportionate to their size and resources. Technology providers mainly focus on the needs of large enterprises. At agribazaar, we aim to level the playing field.
                </div>
                <div className='flex flex-col sm:flex-row justify-center items-baseline gap-10 px-0 sm:px-3'>
                    <div id="left" className='text-center sm:text-left w-full sm:w-2/4'>
                        <div className='text-xl sm:text-lg text-[#696969]'>We want to build www.agribazaar.com as a leading agri-tech start-up led by a passionate leadership team practising the highest standards of corporate governance. Being clean in every aspect of our conduct is a key goal in everything that we do.</div>
                        <div className='pt-8 sm:pt-4'>
                            <div className='text-[#4165b3] font-bold text-xl sm:text-lg'>
                                Our operating model comprises of a three-part pursuit:
                            </div>
                            <div className='pt-4 flex flex-col gap-6'>
                                {foundersMessageObj.svgCards.map(({ url, content }, index) =>
                                    <CardSvgText imgUrl={url} content={content} key={`_${index}`} />)
                                }
                            </div>
                        </div>
                    </div>
                    <div id="right" className='text-center sm:text-left w-full sm:w-2/4'>
                        <div className='text-[#4165b3] font-bold text-xl sm:text-lg'>
                            agribazaar started with the idea that a technology-driven platform could help the agri value chain operate with significantly less overheads and hidden inefficiencies. We digitized the agri value chain with proof of origin and authenticity, enabling traceability whilst bringing down agri-trade commissions from 2-3% to 0.5%.
                        </div>
                        <div className='hidden sm:block pt-4 italic text-[#696969] text-lg font-medium'>
                            agribazaar has set an aggressive timeline to demonstrate its commitment to this pursuit. AI, ML and big data aren’t just buzzwords at agribazaar. It’s how we’re defining technology and agriculture should work together. We are exploring a range of technologies such as remote sensors, drones and blockchain. Essentially, we’re doing what others don’t, putting the power in small farmowners’ hands with technology.
                        </div>
                        <div className='sm:hidden pt-4 italic text-[#696969] text-lg font-medium'>
                            <ReadMoreReadLess
                                lessContent={<p>agribazaar has set an aggressive timeline to demonstrate its commitment to this pursuit. AI, ML and big data aren’t just buzzwords at agribazaar.</p>}
                                moreContent={<p>agribazaar has set an aggressive timeline to demonstrate its commitment to this pursuit. AI, ML and big data aren’t just buzzwords at agribazaar. It’s how we’re defining technology and agriculture should work together. We are exploring a range of technologies such as remote sensors, drones and blockchain. Essentially, we’re doing what others don’t, putting the power in small farmowners’ hands with technology.</p>}
                            />
                        </div>
                        <div className='hidden sm:flex flex-row items-start gap-6 pt-4 font-bold text-2xl text-[#696969]'>
                            <div class="items-start text-start flex flex-col">
                                <p class="mb-2 ">{amithAgarwal.name}</p>
                                <p class="mb-3 font-normal text-sm">{amithAgarwal.desig}</p>
                            </div>
                            <div class="items-start text-start flex flex-col">
                                <p class="mb-2 ">{amitMundawala.name}</p>
                                <p class="mb-3 font-normal text-sm">{amitMundawala.desig}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    return (
        <div>
            <AboutAgribazaarTemp currentPageTitle={foundersMessageObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} />
        </div>
    );
}

