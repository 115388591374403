import React, { useState, Fragment } from 'react';
import SimpleImageSlider from "react-simple-image-slider";

import ReactPlayer from 'react-player';
import { backgroundSVGs, productList, servicesList, testimonials, bazaarStories, ourTeamLinks, ourTeamList, partners, theBazaarStoriesViewMoreBtnRoute } from "../components/Constants";
import MobBanner from '../components/MobBanner';
import Carousel from 'better-react-carousel'
import appConfig from '../config';

const corpBuying = "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/looking-corp-buying.svg";
const arrowGreen = "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/arrow-green.svg"
const banner1 = "https://abazaar.s3-ap-south-1.amazonaws.com/images/banner/banner1.svg";
const banner2 = "https://abazaar.s3-ap-south-1.amazonaws.com/images/banner/banner2.svg";
const banner3 = "https://abazaar.s3-ap-south-1.amazonaws.com/images/banner/banner3.svg";
const banner4 = "https://abazaar.s3-ap-south-1.amazonaws.com/images/banner/banner4.svg";
const banner5 = "https://abazaar.s3-ap-south-1.amazonaws.com/images/banner/banner5.svg";
const banner6 = "https://abazaar.s3-ap-south-1.amazonaws.com/images/banner/banner6.svg";

const rightArrow = (inputColor) => {
  return (<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill={inputColor}>
    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
  </svg>)
}
const crossIcon = () => { 
  return (
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="white">
      <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
    </svg>
  )
}
const linkedInIcon = () => {
  return (
    <svg
      className="w-6 h-6 text-blue-500 fill-current"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512">
      <path
        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
      ></path>
    </svg>
  )
}

export default function Home() {
  const [selectedProduct, setSelectedProduct] = useState("Input Marketplace");
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(
    [...Array(testimonials.length).keys()].map(() => false)
  )

  const device = navigator.userAgentData
  const handleClick = (idx, evt) => {
    console.log(idx, evt);
    if(idx === 5) {
      window.open(appConfig.nafed[appConfig.environment],"_blank")
    }
  }
  const RenderImageSliders = () => {
    return (
      <div className="w-[100%] h-[100%]">
        <div className='hidden sm:block md:flex lg:grid' >
          <SimpleImageSlider
            width={"100%"}
            height={504}
            images={[banner1, banner2, banner3, banner4, banner5, banner6]}
            onClick={handleClick}
            showBullets={true}
            autoPlay={true}
            slideDuration={2}
            autoPlayDelay={4}
          />
        </div>
        <div className='sm:hidden'>
          <SimpleImageSlider
            width={"100%"}
            height={204}
            images={[banner1, banner2, banner3, banner4, banner5, banner6]}
            showBullets={true}
            autoPlay={true}
            slideDuration={2}
            autoPlayDelay={4}
          />
        </div>
      </div>
    )
  }
  const RenderShowProducts = () => {
    // const changeHoverProps = (e, item, action) => {
    //   if (item) {
    //     const target = e.target.children[0]?.children[0];
    //     if (target) target.src = action === "enter" ? item.whiteImgSrc : item.imgSrc;
    //   }
    // }
    return (
      <div>
        <div id="container" className="hidden sm:flex w-[100%] items-center justify-center">
          <div id="wrapper" className="w-screen grid grid-cols-1 sm:grid-cols-8 gap-0 align-baseline">
            {
              productList.map((item, index) => (
                <div
                  key={`_${index}_${item.name}_`}
                  className={`bg-white grid p-5 cursor-pointer ${index !== productList.length - 1 ? ' border-r-2 border-r-[#F8D49A]' : 'border-r-0'} ${selectedProduct === item.name ? 'bg-[#ED1849] text-white' : 'hover:bg-[#ffc36547] dark:hover:bg-[#ffc365] dark:hover:bg-opacity-40'} `}
                  onClick={() => setSelectedProduct(item.name)}
                >
                  <div className="flex justify-center items-center h-[90%]">
                    <img
                      alt={selectedProduct}
                      src={selectedProduct === item.name ? item.whiteImgSrc : item.imgSrc}
                      // width={item.name === "Kisan Safalta Card"? "50%": "100%"}
                      className="transition duration-500 hover:scale-110"
                    />
                  </div>
                  <p className={`pt-2 text-sm font-medium text-center ${selectedProduct === item.name ? 'text-white' : 'text-[#3B3939]'}`}
                  >{item.name}</p>
                </div>
              ))
            }
          </div>
        </div>
        {RenderSelectedProductContent()}
      </div>
    )
  }
  const RenderSelectedProductContent = () => {
    return (
      <div id="container" className="hidden sm:flex w-[100%] h-[100%] items-center justify-center bg-[#f2ffe999] dark:bg-opacity-50 dark:bg-[#f2ffe9]">
        <div id="wrapper-left" className="w-6/12">
          <div id="selected-prod-img" className="max-w-[50rem] w-auto  p-3 flex justify-center items-center ">
            <div className="p-2 ">
              <img
                alt="product"
                width={selectedProduct === "Kisan Safalta Card"?"58%":"100%"}
                style={{marginLeft: (selectedProduct === "Kisan Safalta Card"?"20%":"")}}
                src={productList.filter(obj => obj.name === selectedProduct)[0].prodImgSrc}
              />
            </div>
          </div>
        </div>
        <div id="wrapper-right" className="w-6/12 max-w-[45rem]">
          <div className="flex justify-center items-start  w-auto">
            <div id="selected-prod-svg" className="flex w-[14%]">
              <img
                className='pt-6'
                src={productList.filter(obj => obj.name === selectedProduct)[0].blackSvg}
                alt="product"
              />
            </div>
            <div id="selected-prod-text" className="text-[#3B3939] m-5 pt-6 pr-2 pb-20 pl-1 flex flex-col">
              <p
                className="font-medium text-3xl"
              >{productList.filter(obj => obj.name === selectedProduct)[0].name}</p>
              <p
                className=" w-[90%] text-[#3B3939] text-xl"
              >{productList.filter(obj => obj.name === selectedProduct)[0].content}</p>
              <div className='pt-4 flex items-center'>
                <button className="w-2/6 btn bg-red-600 hover:bg-red-700 border-0 text-white text-sm"
                  onClick={() => window.open(productList.filter(obj => obj.name === selectedProduct)[0].route, "_self")}
                >
                  Explore More
                </button>
                {
                  (selectedProduct== 'Marketplace') &&


                  < button className="<w-2/5 btn bg-transparent hover:bg-transparent hover:text-black hover:border-black text-red-600 text-sm ml-2 border-[1px] border-red-600"
                    style={{width:'40%'}} onClick={() => window.open(productList.filter(obj => obj.name === selectedProduct)[0].pvtAgribazaarRoutes, "_target")}
                  >
                    Go to Marketplace
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const RenderSelectedProductContentMob = () => {
    const showPrev = () => {
      if (selectedProductIndex - 1 >= 0) {
        const newProd = productList[selectedProductIndex - 1].name;
        setSelectedProduct(newProd);
        setSelectedProductIndex(selectedProductIndex - 1);
      } else {
        setSelectedProduct(productList[selectedProductIndex].name);
      }
    }
    const showNext = () => {
      if (selectedProductIndex + 1 < productList.length) {
        const newProd = productList[selectedProductIndex + 1].name;
        setSelectedProduct(newProd);
        setSelectedProductIndex(selectedProductIndex + 1);
      } else {
        setSelectedProduct(productList[selectedProductIndex].name);
      }
    }
    return (
      <div className='bg-white'>
        <div id="container" className="flex w-[100%] h-[100%] items-center justify-center bg-[#f2ffe999] flex-col pb-3 sm:hidden">
          <div id="wrapper-top" className="w-5/12 h-4/12">
            <div className="flex flex-col justify-center items-center pt-3 max-h-[12rem] h-[12rem]">
              <div id="selected-prod-svg" className="flex w-[35%]">
                <img
                  alt="product"
                  className='pt-10 h-auto'
                  src={productList.filter(obj => obj.name === selectedProduct)[0].blackSvg}
                />
              </div>
              <div id="selected-prod-text" className="text-[#3B3939] text-center w-screen m-2 pt-0 pr-5 pb-0 pl-5 flex flex-col">
                <p
                  className="font-medium text-xl sm:text-2xl"
                >{productList.filter(obj => obj.name === selectedProduct)[0].name}</p>
                <p
                  className='text-[#3B3939] text-md sm:text-lg'
                >{productList.filter(obj => obj.name === selectedProduct)[0].content}</p>

              </div>
            </div>
          </div>
          <div id="wrapper-bottom" className="w-7/12 h-[20%]">
            <div className='flex  flex-col justify-center items-center pt-3'>
              <div id="selected-prod-img" className="w-screen p-2 flex grow max-h-[20rem] h-[20rem]">
                <div
                  className='m-0 basis-1/4 pl-0 flex-none w-1/4 grow-0  ml-0  self-center text-left shrink-0'
                >
                  <img
                    alt="arrow"
                    src={arrowGreen}
                    className="rotate-90 float-left  w-auto h-auto"
                    onClick={() => showPrev()}
                  />
                </div>
                <div className="m-0 p-0 w-10/12">
                  <img
                    alt="product"
                    className="block w-[100%] h-full"
                    src={productList.filter(obj => obj.name === selectedProduct)[0].prodImgSrc}
                  />
                </div>
                <div
                  className='m-0 basis-1/4 pr-0 flex-none w-1/4 grow-0  mr-0 self-center text-right shrink-0'
                >
                  <img
                    alt="arrow"
                    src={arrowGreen}
                    className="-rotate-90 float-right  w-auto h-auto"
                    onClick={() => showNext()}
                  />
                </div>
              </div>
              <div className='m-2 pt-2 items-center flex pb-6'>
                <button className="w-fit btn bg-red-600 hover:bg-red-700 border-0 text-white text-sm"
                  onClick={() => window.open(productList.filter(obj => obj.name === selectedProduct)[0].route, "_self")}>
                  Explore More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const RenderOurServices = () => {
    const [selectedServiceIndex, setSelectedServiceIndex] = useState(0);
    return (
      <div>
        <div style={{ backgroundImage: 'url(' + backgroundSVGs.ourServicesBG + ')', backgroundRepeat: 'no-repeat' }} className={` h-96 w-full relative bg-cover bg-center`}>
          <div
            id="services-title"
            className='pl-[22%] sm:pl-10 absolute text-4xl sm:text-5xl text-white font-bold  p-10'>Our Services
          </div>

          <div className="hidden sm:inherit grid-cols-1 sm:grid sm:grid-cols-5 gap-0 align-baseline h-full w-full">
            {servicesList.map((item, index) => (
              <a
                key={`__${index}`}
                href={item.route}
                className={`text-center sm:flex sm:text-left items-center justify-center text-white bg-gradient-to-t from-[#4CAF50] bg-opacity-70 hover:from-[#4caf5075] ${index !== servicesList.length - 1 && 'mr-[0.1rem]'} `}
              >
                <div
                  id="services-items"
                  className="h-[100%] pt-64">
                  <div className='contents flex-col justify-end align-baseline p-5 h-inherit'>
                    <div className='h-[60%]'>
                      <p className='items-start p-4 text-md font-medium'>{item.name}</p>
                    </div>
                    <div className='flex justify-center sm:pl-4 sm:justify-start'>
                      <button className='flex items-center text-xs'>READ MORE
                        {rightArrow("white")}
                      </button>
                    </div>
                  </div>
                </div>
              </a>
            ))}
          </div>

          <div className="flex inherit grid-cols-1  align-baseline h-full w-full sm:hidden bg-gradient-to-t from-[#4CAF50] bg-opacity-70" >
            <div id="selected-service" className="w-screen flex grow p-2">
              <div
                className='m-0 basis-1/12 pl-0 flex-none w-1/12 grow-0  ml-0  self-center text-left shrink-0'>
                <img
                  alt="arrow"
                  src={arrowGreen}
                  className="rotate-90 float-left  w-auto h-auto"
                  onClick={() => selectedServiceIndex - 1 >= 0 ? setSelectedServiceIndex(selectedServiceIndex - 1) : setSelectedServiceIndex(selectedServiceIndex)}
                />
              </div>

              <div id="curr-service" className='self-end text-center items-center m-0  w-10/12 text-white flex flex-col justify-end align-baseline pb-5 px-0 h-inherit '>
                <p className='block w-full h-full px-0 font-medium text-2xl pb-4'>{servicesList[selectedServiceIndex].name}</p>
                <div>
                  <button
                    onClick={() => window.open(servicesList[selectedServiceIndex].route, "_self")}
                    className='flex items-center text-xs'>READ MORE
                    {rightArrow("white")}
                  </button>
                </div>
              </div>

              <div
                className='m-0 basis-1/12 pr-0 flex-none w-1/12 grow-0  mr-0 self-center text-right shrink-0'
              >
                <img
                  alt="arrow"
                  src={arrowGreen}
                  className="-rotate-90 float-right  w-auto h-auto"
                  onClick={() => selectedServiceIndex + 1 < servicesList.length ? setSelectedServiceIndex(selectedServiceIndex + 1) : setSelectedServiceIndex(selectedServiceIndex)}
                />
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
  const RenderWhatPeopleSayAboutAgribazaar = () => {
    const [selectedTestimonialIndex, setselectedTestimonialIndex] = useState(0);
    const pauseAllOtherVideos = (playingIndex) => {
      let temp = [...isPlaying];
      temp.forEach((val, index) => {
        temp[index] = index === playingIndex ? true : false;
      });
      setIsPlaying(temp);
    }
    return (
      <div className='bg-white'>
        <div id="container" style={{ backgroundImage: 'url(' + backgroundSVGs.whatPeopleSayAboutAgribazaarBG + ')' }}
          className=" sm:flex w-[100%] sm:pt-8 -pb-40 h-full items-center justify-start">

          <div id="wrapper-left" className="sm:pl-10 text-center w-[100%] sm:text-left self-start pt-6 pl-0 pr-2 sm:w-4/12 h-full">
            <div className="tracking-widest contents max-w-[55rem] w-auto sm:flex justify-items-start font-bold">
              <p style={{lineHeight:"1.25"}} className=' text-[#3B3939] text-3xl sm:text-5xl leading-tight'>What <br /> People Say <br /> About <br /> <p className='text-red-600'> AGRIBAZAAR</p></p>
            </div>
          </div>
          <div id="wrapper-right" className="w-[100%] sm:w-8/12 sm:pl-8 sm:pr-16">
            <div className="hidden sm:flex justify-center items-start  w-auto">
              <div id="vids-layers" className="flex flex-row pt-6 pb-8">
                {testimonials.map((item, index) => {
                  return (
                    <div key={`${item.name}_${index}`} className='p-2 text-center sm:text-left'>
                      <ReactPlayer
                        url={item.vidUrl}
                        width="auto"
                        height="auto"
                        controls={true}
                        id={`video_${index}`}
                        playing={isPlaying[index]}
                        onPlay={() => pauseAllOtherVideos(index)}
                      />
                      <div className='text-[#3B3939] contents flex-col justify-end align-baseline h-inherit'>
                        <div className='h-auto'>
                          <p className='items-start pt-2 pb-2 text-md'>{item.subHead}</p>
                        </div>
                        <div className='flex flex-col justify-center sm:justify-start'>
                          <p className='text-md font-medium'>{item.name}</p>
                          <p className='font-bold text-sm'>{item.desig}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                }
                )}
              </div>
            </div>
            <div className="flex inherit grid-cols-1 align-baseline h-full w-full  sm:hidden " >
              <div className="w-screen px-2 flex grow max-h-full h-[100%]">
                <div className='self-baseline pt-[23%] m-0 basis-1/12 pl-0 flex-none w-1/4 grow-0  ml-0  text-left shrink-0' >
                  <img
                    alt="arrow"
                    src={arrowGreen}
                    className="rotate-90 float-left  w-auto h-auto"
                    onClick={() => selectedTestimonialIndex - 1 >= 0 ? setselectedTestimonialIndex(selectedTestimonialIndex - 1) : setselectedTestimonialIndex(selectedTestimonialIndex)}
                  />
                </div>
                <div className="flex flex-col justify-end align-baseline h-inherit items-center m-0 p-0 w-10/12">
                  <div className='block w-full h-full p-4'>
                    <div className='p-2 '>
                      <ReactPlayer
                        url={testimonials[selectedTestimonialIndex].vidUrl}
                        width="auto"
                        height="auto"
                        controls={true}
                      />
                      <div className=' contents flex-col justify-end align-baseline h-inherit text-[#3B3939]'>
                        <div className='h-auto'>
                          <p className='items-start pt-2 pb-4 text-lg h-32'>{testimonials[selectedTestimonialIndex].subHead}</p>
                        </div>
                        <div className='pt-6 flex flex-col justify-center text-center sm:justify-start'>
                          <p className='text-lg font-medium'>{testimonials[selectedTestimonialIndex].name}</p>
                          <p className='font-bold'>{testimonials[selectedTestimonialIndex].desig}
                          </p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='self-baseline pt-[23%] m-0 basis-1/12 pr-0 flex-none w-1/4 grow-0  mr-0  text-right shrink-0'>
                  <img
                    alt="arrow"
                    src={arrowGreen}
                    className="-rotate-90 float-right  w-auto h-auto"
                    onClick={() => selectedTestimonialIndex + 1 < testimonials.length ? setselectedTestimonialIndex(selectedTestimonialIndex + 1) : setselectedTestimonialIndex(selectedTestimonialIndex)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const RenderTheBazaarStories = () => {
    return (
      <Fragment>
        <div id="container" style={{ backgroundImage: 'url(' + backgroundSVGs.theBazaarStoriesBG + ')' }}
          className="bg-white sm:flex w-[100%] pt-4 sm:pt-8 pb-8 h-full justify-start bg-no-repeat bg-contain">
          <div id="wrapper-left" className="sm:pl-10 text-center w-[100%] sm:text-left self-start pt-8 sm:pt-6 pl-2 pr-2 sm:w-4/12 h-full">
            <div className="tracking-wide text-3xl sm:text-5xl contents max-w-[100%] w-auto sm:flex justify-items-start font-bold">
              <p className='text-[#3B3939] leading-tight'>The Bazaar <p className='text-red-600'> STORIES</p></p>

            </div>
            <div className='pt-0 pr-14 pl-14 sm:pr-2 sm:pl-0 text-gray-500 flex flex-col justify-start sm:pt-2'>
              <p className='hidden sm:block text-[#3B3939] text-md'>Read & explore our perspectives on agriculture & technology with the latest trends. Get all the current updates on innovations in agriculture.</p>
              <p className='font-semibold pt-2 text-md sm:text-md text-[#3B3939]'>Insights and trends from the agri-tech industry.</p>
            </div>
            <div className='hidden sm:block pt-4'>
              <button className="w-[50%] btn bg-red-600 hover:bg-red-700 border-0 text-white text-sm"
                onClick={() => window.open(theBazaarStoriesViewMoreBtnRoute, "_blank")} >
                View More
              </button>
            </div>
          </div>
          <div id="wrapper-right" className="item-center w-[100%] sm:w-8/12 sm:pl-8 sm:pr-16 bg-no-repeat">
            <div className=" sm:flex justify-center items-start  w-auto">
              <div id="indi-stories" className=" pb-2 pt-2 flex flex-row sm:pt-6 sm:pb-8">
                {bazaarStories.map((item, index) => {
                  return (
                    <div key={`${item.title}_${index}`} className={`${index !== 0 && 'hidden sm:block'} p-6 sm:p-2 w-full px-12`}>
                      <div id="head" className='bg-[#f9ead3] rounded-t-sm font-medium text-xl sm:text-base text-[#a76c10] text-center sm:text-left h-1/3 px-4 pt-2 pb-20 sm:pb-8'>
                      {item.title}
                        </div>
                      <div id="tail" className='text-center sm:text-left pt-2 sm:pt-0 px-4 py-4 pb-6 h-[70%] bg-[#fbf4e9] rounded-b-sm text-[#a76c10]'>
                        <div className='pt-2 pb-2 text-sm h-5/6'>{item.content}</div>
                        <div className='font-bold text-sm'>{item.date}</div>
                        <div className='inline-block'>
                          <button
                            onClick={() => window.open(item.route, "_blank")}
                            className='flex items-center text-sm pt-4 font-bold text-red-600'>READ MORE
                            {rightArrow("red")}
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                }
                )}
              </div>
            </div>
          </div>
          <div className='flex justify-center sm:hidden pt-0'>
            <button className="text-white w-[50%] btn bg-red-600 hover:bg-red-700 border-0 text-sm"
              onClick={() => window.open(theBazaarStoriesViewMoreBtnRoute, "_blank")} >
              View More
            </button>
          </div>
        </div>
      </Fragment>
    )
  }
  const RenderOurTeam = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTeamMember, setSelectedTeamMember] = useState({});

    const teamCard = (item, index) => {
      const { name, desig, imgUrl } = item;
      return (
        <Fragment>
          <div className={`hidden sm:block bg-white card-compact  shadow-md drop-shadow-sm ${index > 0 && 'hidden sm:block'}`} key={`_${name}_${index}`}>
            <figure className='grayscale hover:grayscale-0 hover:scale-[101%] cursor-pointer'
              onClick={() => {
                setSelectedTeamMember(item);
                setIsModalOpen(true);
                document.getElementsByTagName("BODY")[0].style.overflow = 'hidden';
              }}>
              <img src={imgUrl} alt="profile" width="100%" />
            </figure>
            <div className="card-body text-[#3B3939]">
              <h2 className="card-title text-lg">{name}</h2>
              <p className='font-medium text-md '>{desig}</p>
            </div>
          </div>
          <div className={`block sm:hidden  card-side  shadow-xl ${index > 0 && 'block sm:hidden'}`} key={`${name}_${index}`}>
            <figure className='cursor-pointer'><img src={imgUrl} alt="profile" /></figure>
            <div className="card-body items-center text-center">
              <h2 className="card-title text-[#3B3939] text-2xl">{name}</h2>
              <p className='text-[#3B3939] font-medium text-sm'>{desig}</p>
            </div>
          </div>
        </Fragment>
      )
    }
    const modalContent = () => {
      const { imgUrl, name, desig, text, linkedInUrl } = selectedTeamMember;
      return (
        <div>
          <div
            className="p-8 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="w-[80%] h-fit 2xl:pt-12 2xl:pb-12 pl-10 card card-side shadow-2xl bg-white text-[#3B3939] bg-opacity-95">
              <figure className='w-[30%]'><img src={imgUrl} alt="Movie" /></figure>
              <div className="card-body w-[70%] pt-0 opacity-90 ">
                <div className='relative card-actions flex justify-end pt-6 pr-0 bg-white bg-opacity-5'
                  onClick={() => {
                    setSelectedTeamMember({});
                    setIsModalOpen(false);
                    document.getElementsByTagName("BODY")[0].style.overflowY = 'scroll';
                  }}>
                  <button className="btn btn-square btn-sm">
                    {crossIcon()}
                  </button>
                </div>
                <div className='text-[#3B3939]'>
                  <h2 className="card-title text-2xl">{name}</h2>
                  <h3 className="font-semibold text-sm pt-1">{desig}</h3>
                  <p className='mr-4 text-justify pt-4 text-sm font-medium'>{text}</p>
                </div>
                <a href={linkedInUrl}>{linkedInIcon()}</a>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      )
    }
    return (
      <div className='bg-white'>
        <div id="container" className="w-[100%] pt-10 sm:pt-8 pb-8 h-full">
          <div id="top-url" className='flex flex-row  items-center justify-center'>
            <div id="container" className="sm:flex w-[100%] items-center justify-center">
              <div id="wrapper" className="hidden grid-cols-4 w-screen sm:grid sm:grid-cols-4 gap-0 align-baseline divide-x-2 px-6">
                {
                  ourTeamLinks.map((item, index) => (
                    <div
                      id="desktop"
                      key={`${index}_${item.name}`}
                      className={`flex justify-center h-auto p-4 cursor-pointer hover:bg-[#F8D49A]`}
                      onClick={() => window.open(item.href, item.target)}
                    >
                      <p className="text-center font-medium text-[#3B3939] text-xl"
                      >{item.title}</p>
                    </div>

                  ))
                }
              </div>
              <div id="wrapper" className=" grid-cols-2 w-screen grid sm:hidden gap-0 align-baseline  divide-x-2">
                {
                  ourTeamLinks.slice(0, 2).map((item, index) => (
                    <div
                      id="mobile"
                      key={`${index}_${item.name}`}
                      className={`flex justify-center h-20 p-5 cursor-pointer `}
                      onClick={() => window.open(item.href, item.target)}
                    >
                      <p className="text-center font-medium text-lg text-[#3B3939]"
                      >{item.title}</p>
                    </div>
                  ))
                }
              </div>
              <div className=' grid-cols-2 w-screen grid sm:hidden gap-0 align-baseline divide-x-2 pt-2'>
                {
                  ourTeamLinks.slice(2).map((item, index) => (
                    <div
                      id="mobile"
                      key={`${index}_${item.name}`}
                      className={`flex justify-center h-auto p-5 cursor-pointer`}
                      onClick={() => window.open(item.href, item.target)}
                    >
                      <p className="text-center font-medium text-lg text-[#3B3939]"
                      >{item.title}</p>
                    </div>
                  ))
                }
              </div>


            </div>
          </div>
          {/* <div id="team">
            <div className='p-10 grid-cols-1 sm:grid-cols-4 w-full grid gap-6 align-baseline'>
              {ourTeamList.map((item, index) =>
                teamCard(item, index)
              )}
            </div>
          </div> */}
          {/* <div id="team" style={{overflowX:"auto"}}>
                <div style={{width:"180vw"}} className='p-10 grid-cols-1 sm:grid-cols-5 grid gap-6 align-baseline'>
              {ourTeamList.map((item, index) =>
                teamCard(item, index)
              )}
            </div> 
            </div>*/}
          <div id="team">
            <Carousel cols={3} rows={1} gap={5} loop>
                {ourTeamList.map((item, index) =>
            <Carousel.Item>
              <div className='p-10'>
                {teamCard(item, index)}
              </div>
                </Carousel.Item>
              )}
              </Carousel>
          </div>
          {isModalOpen && modalContent()}
          {/* <div id="readMore-btn w-screen">
            <div className='justify-center flex sm:justify-end'>
              <button
                onClick={() => window.open("/our-team", "_self")}
                className='pr-0 sm:pr-8  flex items-center text-md font-bold text-red-600'>SHOW MORE
                {rightArrow("red")}
              </button>
            </div>
          </div> */}
        </div>
      </div>
    )
  }
  const RenderLookingForCorporateBuying = () => {
    return (
      <Fragment>
        <div id="container" className="bg-blue-50 bg-opacity-50 sm:flex w-[100%] pt-4 sm:pt-8 pb-8 h-full justify-start bg-no-repeat bg-contain">

          <div id="wrapper-left" className="sm:pl-10 text-center w-[100%] sm:text-left self-start pt-8 sm:pt-6 pl-2 pr-2 sm:w-5/12 h-full">
            <div className="tracking-wide text-3xl sm:text-5xl contents max-w-[100%] w-auto sm:flex justify-items-start font-bold">
              <p className='text-[#3B3939] leading-tight'>Looking
                for <p className='text-red-600'> Corporate</p>Buying?</p>
            </div>
            <div className='pt-0 pr-14 sm:pr-2 sm:pl-0 text-gray-500 flex flex-col justify-start sm:pt-2'>
              <p className='hidden sm:block text-[#3B3939] text-md'>Join us and get your own panel to create your trades, set your own terms, manage deliveries, invoices and settlements. You can also provide multiple login access as per your convenience and so much more.</p>
            </div>
            <div className='hidden sm:block pt-4'>
              <button className="w-[50%] btn bg-red-600 hover:bg-red-700 border-0 font-bold text-white text-lg"
                onClick={() => window.open("/contact-us", "_self")} >
                Contact Us
              </button>
            </div>
          </div>

          <div id="wrapper-right" className="sm:pl-10 w-[100%] sm:w-7/12 max-w-[50rem] pt-8 sm:pt-4">
            <div className='pl-10 pr-10 flex justify-center'>
              <img src={corpBuying} alt="corporate buying" />
            </div>
          </div>
        </div>
      </Fragment>
    )
  }


  const RenderOurPartners = () => {
    return (
      <Fragment>
        <div id="container" className="bg-white sm:flex w-[100%] pt-12 sm:pt-0 p-0 h-full items-center justify-start pb-0 sm:pb-8">
          <div id="wrapper-left" className="m-auto p-1 sm:p-4 sm:pl-10 text-center w-[100%] sm:text-left self-start sm:w-2/12 h-full pt-0">
            <div className="tracking-wide text-3xl sm:text-5xl contents  max-w-[100%] w-auto sm:flex justify-items-start font-bold">
              <p className='text-[#3B3939]  whitespace-nowrap leading-tight'>Meet Our <p className='text-red-600'> Partners</p></p>
            </div>
          </div>
          <div id="wrapper-right" className="hidden sm:pl-20 m-auto w-screen sm:grid sm:grid-cols-5 gap-0 items-center self-baseline">
            {
              partners.map((item, index) => (
                <div
                  key={index}
                  className={`w-40 h-30 p-5 place-self-center`}
                >
                  <img src={item} alt={item} />
                </div>
              ))
            }
          </div>
          <div className="grid-cols-3 w-screen grid sm:hidden gap-0 items-center  ">
            {
              partners.slice(0, 3).map((item, index) => (
                <div
                  key={`${index}`}
                  className={`flex justify-center h-auto p-8 `}
                >
                  <img src={item} alt={item} />
                </div>
              ))
            }
          </div>
          <div className=' grid-cols-2 w-screen grid sm:hidden gap-0 items-center '>
            {
              partners.slice(3).map((item, index) => (
                <div
                  key={`${index}`}
                  className={`flex justify-center h-auto p-8`}
                >
                  <img src={item} alt={item} />
                </div>
              ))
            }
          </div>
        </div>
      </Fragment>
    )
  }

  return (
    <div
      className='p-0 overflow-hidden pt-20 sm:pt-24 z-0 sm:max-w-[1900px] m-auto w-[100%] font-poppins'>
      {RenderImageSliders()}
      {RenderShowProducts()}
      {RenderSelectedProductContentMob()}
      {/* {device && device.platform === "Android" &&  */}
      {/* } */}
      {RenderOurServices()}
      {RenderWhatPeopleSayAboutAgribazaar()}
      {RenderTheBazaarStories()}
      {RenderOurTeam()}
      {RenderLookingForCorporateBuying()}
      {RenderOurPartners()}
      <MobBanner />
    </div>
  )
}
