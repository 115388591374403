import React from 'react';

export default function SimpleCardTextImg({ title, imgUrl }) {
    return (
        // <div className='w-72'>
        // <div class="card card-compact border-0 rounded-md">
        //     <div class="card-body bg-[#FAEBD3] h-min">
        //         <h2 class="card-title p-0 text-lg font-normal text-left">{title}</h2>
        //     </div>
        //     <figure>
        //         <img src={imgUrl} alt={title} />
        //     </figure>
        // </div>
        // </div>
        <div class="bg-[#FAEBD3] rounded-lg border-0 border-gray-200 shadow-md h-max">
            <div class="p-5">
                <h5 class="tracking-tight text-md font-medium text-left">{title}</h5>
            </div>
            <img className='w-full h-auto' src={imgUrl} alt={title.slice(0,10)} />
        </div>
    );
}

