import React from 'react';

export default function RightArrowShapedDiv({ step, svgUrl, head, content }) {
    return (
        <div className='w-full h-96'>
            <div class="skew-x-12 transform  p-4 -m-b-1 block border-x-2 border-t-2 border-[#F7D39D] h-1/2">
                <div className='-skew-x-12 items-center place-items-center w-full h-full flex flex-col place-content-evenly'>
                    <div className='font-bold text-4xl'>Step {step}</div>
                    <div className='w-16'><img src={svgUrl} alt={head} width="100%" /></div>
                </div>
            </div>
            <div class="-skew-x-12 transform  px-4 -m-t-1 block border-x-2 border-b-2 border-[#F7D39D] h-1/2">
                <div className='skew-x-12'>
                    <div className='text-[#ed1849] font-medium text-lg h-14'>{head}</div>
                    <div className='font-normal text-clip overflow-hidden text-sm px-2'>{content}</div>
                </div>
            </div>
        </div>
    );
}

