import React from 'react';
import OurServicesTemp from '../../components/OurServicesTemplate/OurServicesTemp';
import { FarmerScoreCardObj } from "./OurServicesConstants";
import SimpleCardTextText from "../../components/ReusableGeneric/SimpleCardTextText";
import CircleRectCard from "../../components/ReusableGeneric/CircleRectCard";
import DiscloseWText from "../../components/ReusableGeneric/DiscloseWText";
import { farmerScoreCardloginBtnRoute } from "../../components/Constants";

const Star = () => (
    <div className='pt-1 mr-2'>
        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z" fill="#6F9300" />
        </svg>
    </div>

)
export default function FarmerScoreCard() {
    const headerContent = () => {
        console.log(window.innerWidth)
        const isMobile = window.innerWidth < 640;
        const backgroundImageUrl = !isMobile
            ? FarmerScoreCardObj.topImgUrl
            : FarmerScoreCardObj.topImgUrlCropped;
        const divStyle = {
            backgroundImage: `url(${backgroundImageUrl})`,
        };

        return (
            <div id="top-data"
                style={divStyle}
                // style={{ backgroundImage: 'url(' + FarmerScoreCardObj.topImgUrl + ')' }}
                className={`w-full relative bg-contain bg-no-repeat bg-center`}>
                <div className='p-20 text-center flex items-center place-items-center sm:hidden' />
                <div className='py-20 px-6 text-start hidden sm:flex items-start text-white'>
                    <div className='flex flex-col gap-2 p-0'>
                        <p className='font-medium text-5xl text-[#15672B]'>Farmer <br /> Scorecard</p>
                        <div className='font-normal text-xl rounded text-[#000]'>Our comprehensive farmer scorecard is the best way to <br /> make informed decisions on rural lending.</div>
                    </div>
                </div>
            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div id="body-data" className='px-4 sm:px-8'>
                <div className='flex flex-col sm:flex-row justify-center items-start gap-6 sm:gap-10 sm:pl-3 sm:pr-3 text-[#3B3939] pt-0 sm:pt-4'>
                    <div id="left" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div className='grid grid-cols-1 gap-2 items-baseline'>
                            <div className='pt-0 sm:pt-4 text-xl sm:text-xl font-semibold  sm:h-14'>Agribazaar's Farmer Credibility Assessment Services</div>
                            <div className='pt-0 text-md sm:text-md font-normal sm:h-auto sm:w-[94%]'>
                                Revolutionizing the lending process for farmers & banks to optimize and bring in efficiency in rural lending.
                            </div>
                            <div className='pt-0' style={{ margin: "-42px" }}>
                                <img
                                    src={FarmerScoreCardObj.middleLeftImg}
                                    alt='features'
                                    className="w-auto h-50 sm:h-auto px-8 sm:px-0 py-4 sm:py-0"
                                />
                            </div>
                        </div>
                    </div>
                    <div id="right" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div className='grid grid-cols-1 gap-2 items-baseline px-4 pt-6 sm:pt-2'>
                            <div className='p-8 text-xl sm:text-xl h-auto border border-[#B4E4C1] flex flex-col gap-6'>
                                {FarmerScoreCardObj.starInfo.map((itm, index) => (
                                    <div class="font-bold" key={index}>
                                        {itm.title}
                                        <ul className='font-normal text-base list-disc px-4 list-none pl-0'>
                                            {itm.childs.map((itm2, index2) => (
                                                <li className='flex items-start pb-4 pt-2' key={index2}>
                                                    <Star />
                                                    {itm2}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col sm:flex-row justify-center items-start gap-6 sm:gap-10 sm:pl-3 sm:pr-3 text-[#3B3939] pt-12'>
                    <div id="left" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div className='grid grid-cols-1 gap-2 items-baseline'>
                            <div className='pt-4 text-xl sm:text-2xl font-bold  sm:h-14'>Why Choose Agribazaar's Data-Driven <br /> Farmer Scorecard?</div>
                            <div className='pt-8'>
                                <img
                                    src={FarmerScoreCardObj.middleLeftImg2}
                                    alt='features'
                                    className="w-auto h-50 sm:h-auto px-8 sm:px-0 py-4 sm:py-0"
                                />
                            </div>
                        </div>
                    </div>
                    <div id="left" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div className='grid grid-cols-1 gap-2 items-baseline'>
                            <div className='pt-4 text-xl sm:text-xl sm:h-14 contents'>
                                {FarmerScoreCardObj.cards.map((itm, index) => (
                                    <CircleRectCard
                                        key={index}
                                        icon={itm.icon}
                                        head={itm.head}
                                        description={itm.description}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const bottomContent = () => {
        const cardContent1 = () => {
            return (
                <div className='flex flex-col sm:flex-col justify-center items-start gap-6 sm:gap-10 sm:pl-3 sm:pr-3 pt-4 sm:pt-0 text-[#3B3939]'>
                    <div id="left" className='w-full text-center sm:text-left'>
                        <ul class="flex flex-col sm:flex-row gap-6 list-disc list-inside marker:text-[#4165B3] marker:text-xl leading-loose list-none">
                            <li class="sm:w-1/3">
                                <span class="font-bold">Farmer Scoreboard:</span>
                                <br /> Offers a quick at-a-glance view of the farmer's creditworthiness.
                            </li>
                            <li class="sm:w-1/3">
                                <span class="font-bold">Yearly Profit & Loss Estimates:</span>
                                <br /> Enables banks to gauge the financial stability and loan repayment ability of farmers by providing yearly profit and loss estimates based on historical data and market trends and the average seasonal farm income and expenditure on input and cultivation.
                            </li>
                            <li class="sm:w-1/3">
                                <span class="font-bold">Risk Profile and Weather Trends:</span>
                                <br /> Assesses potential risks associated with the farm's location and crops and analyzes weather trends, climate risks, and natural calamities.
                            </li>
                        </ul>
                    </div>
                    <div id="right" className='w-full text-center sm:text-left'>
                        <ul class="flex flex-col sm:flex-row gap-6 list-disc list-inside marker:text-[#4165B3] marker:text-xl leading-loose list-none">
                            <li class="sm:w-1/3">
                                <span class="font-bold">Farm Location and Crop History:</span>
                                <br /> Offers detailed information about the farmer's farmland, including precise location, size and historical crop yield data.
                            </li>
                            <li class="sm:w-1/3">
                                <span class="font-bold">Credit Score Translation Factors:</span>
                                <br /> Translates a farmer's credit score into easily understandable factors and thus, enhances banks' understanding of a farmer's creditworthiness.
                            </li>
                            <li class="sm:w-1/3"></li>
                        </ul>
                    </div>
                </div>
            )
        }
        return (
            <div id="bottom-data" className='px-2 sm:px-8 text-[#3B3939]'>
                <div className='hidden sm:block py-6 px-2 text-left justify-start'>
                    <SimpleCardTextText title="Farmer Scorecard Key Attributes" content={cardContent1()} bodyHeight={80} />
                </div>
                <div className='sm:hidden pt-4 px-4 mb-12'>
                    <DiscloseWText title="Farmer Scorecard Key Attributes" content={cardContent1()} />
                </div>
            </div>
        )
    }
    return (
        <div>
            <OurServicesTemp currentPageTitle={FarmerScoreCardObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} bottomContent={bottomContent()} />
            {/* <div id="login-btn" className='px-2 sm:px-8 text-[#3B3939] pt-0' style={{ marginTop: "-54px" }}>
                <div className='hidden sm:block py-6 px-2 text-left justify-start'>
                    <button
                        onClick={() => window.open(farmerScoreCardloginBtnRoute.link, farmerScoreCardloginBtnRoute.target)}
                        className="whitespace-nowrap inline-flex justify-center px-6 py-1 border border-transparent rounded-md shadow-sm text-lg text-white bg-red-600 hover:bg-red-700"
                    >
                        Login
                    </button>
                </div>
            </div> */}
        </div>
    );
}