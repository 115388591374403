import { ourProductsNavigation } from "../../components/Constants";

export const AgribhumiObj = {
    navSeq: ourProductsNavigation[0].name,
    topImgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/agribhumi-top.png",
    bottomLeftImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/agribhumi-BL.png",
};
export const InputMarketplaceObj = {
    navSeq: ourProductsNavigation[1].name,
    topImgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/inputMarketplace-top.png",
    bottomLeftImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/inputMarketplace-BL.png"
};
export const OnlineAuctionEngineObj = {
    navSeq: ourProductsNavigation[2].name,
    topImgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/onlineAuctionEngine-top.png",
    bottomLeftImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/onlineAuctionEngine-BL.png",
    disclosuresSVGsList: ["https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/auction-engine.svg", "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/security.svg", "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/checkbox.svg"]
};
export const EmandiObj = {
    navSeq: ourProductsNavigation[3].name,
    topImgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/eMandi-top.png",
    bottomLeftImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/eMandi-BL.png",
    disclosureSvg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/emandiDisclosure.svg"
};
export const AgriknowObj = {
    navSeq: ourProductsNavigation[4].name,
    topImgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/agriknow-top.png",
    bottomLeftImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/agriknow-BL.png"
};
export const AgripayObj = {
    navSeq: ourProductsNavigation[5].name,
    topImgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/agripay-top.png",
    bottomLeftImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/agripay-BL.png"
};
export const MarketplaceObj = {
    navSeq: ourProductsNavigation[6].name,
    topImgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/marketplace-top.png",
    bottomLeftImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/marketplace-BL.png",
    disclosuresSVGsList: ["https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/marketplace-buying.svg", "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/marketplace-selling.svg", "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/marketplace-GAS.svg"]
};
export const KisanCreditCardObj = {
    navSeq: ourProductsNavigation[7].name,
    topImgUrl: "https://abazaar.s3.ap-south-1.amazonaws.com/images/homepageProducts/Group+13801.png",
    bottomLeftImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/kisanCreditCard-BL.png",
    disclosuresSVGsList: ["https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/kcc-features.svg", "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/kcc-use.svg", "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourProducts/kcc-benefits.svg"]
};