import React from 'react';
import Breadcrumb from '../ReusableGeneric/Breadcrumb';
import { ourServicesNavigation } from "../Constants"

export default function OurServicesTemp({ currentPageTitle, headerContent, bodyContent, bottomContent }) {
    return (
        <div className='p-0 overflow-hidden  pt-20 sm:pt-32 z-0 bg-white max-w-screen-2xl m-auto w-[100%] font-poppins'>
            <Breadcrumb parent="Our Services" navSequenceArray={ourServicesNavigation} currentPage={currentPageTitle} />
            <div id="top-wrapper" className='w-full'>
                {headerContent}
            </div>

            <div id="body-wrapper" className="py-4 sm:flex flex-col gap-8 w-full h-full items-center justify-center">
                {bodyContent}
            </div>

            <div id="bottom-wrapper" className='py-0 sm:py-10 sm:flex flex-col gap-8 w-full h-full items-center justify-center'>
                {bottomContent}
            </div>
        </div>
    )
}