import React, { Fragment } from 'react';
import OurProductsTemp from '../../components/OurProductsTemplate/OurProductsTemp';
import { MarketplaceObj } from "./OurProductsConstants";
import CardArrowText from "../../components/ReusableGeneric/CardArrowText";
import DiscloseWSvg from "../../components/ReusableGeneric/DiscloseWSvg";
import ReadMoreReadLess from "../../components/ReusableGeneric/ReadMoreReadLess";

export default function Marketplace() {
    const headerContent = () => {
        return (
            <div id="top-data" style={{ backgroundImage: 'url(' + MarketplaceObj.topImgUrl + ')' }} className={`h-auto sm:h-64 w-full relative bg-cover bg-center`}  >
                <div className='p-20 text-center flex items-center place-items-center sm:hidden' />
            </div>
        )
    }
    const discloseContentBuying = () => {
        return (
            <div className='pt-0 px-2 sm:px-0'>
                <div className='pt-0 text-lg sm:text-lg font-semibold'>Enabling seamless purchases at fingertips</div>
                <div className='pt-2 text-md sm:text-md font-normal'>At agribazaar, we’re committed to delivering the best prices and services for commodity traders.</div>
            </div>
        )
    }
    const discloseContentSelling = () => {
        return (
            <div className='pt-0 px-2 sm:px-0'>
                <div className='pt-0 text-lg sm:text-lg font-semibold'>Boosting sales with transparency and technology</div>
                <div className='pt-2 text-md sm:text-md font-normal'>We are laser-focused on our vision to empower farmers by delivering efficiency, transparency, and traceability in the agri value chain.</div>
            </div>
        )
    }
    const discloseContentGAS = () => {
        return (
            <div className='pt-0 px-2 sm:px-0'>
                <div className='pt-0 text-lg sm:text-lg font-semibold'>Augmenting value chain efficiencies with transparent, secure settlements</div>
                <div className='pt-2 text-md sm:text-md font-normal'>At agribazaar, our platform offers transparent end-to-end settlement options for buyers and sellers.</div>
            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div id="body-data" className='px-2 sm:px-4 pb-4'>
                <div className='flex flex-col sm:flex-row justify-center items-start gap-10 sm:pl-3 sm:pr-3 text-[#3B3939]'>
                    <div id="left" className='w-full sm:w-2/4 text-center sm:text-left'>
                    <div className='font-bold text-3xl sm:text-4xl m-auto pt-2'>Marketplace</div>

                        <div className='pt-4 text-lg sm:text-xl font-medium sm:pr-52'>With 1,00,000+ onboard users,</div>
                        <div className='pt-0 text-lg sm:text-xl font-medium sm:pr-48'>we are India’s leading e-marketplace!</div>

                        <div className='pt-4 text-md font-normal'>We bridge the gap between Sellers & Buyers using advanced technologies</div>
                        <div className='hidden sm:block'>
                            <div className='pt-2 text-md sm:text-lg font-normal'>agribazaar drives agricultural transactions between buyer and seller, product quality check options, and end-to-end logistic services.</div>
                                <div className='pt-4 grid grid-rows-1 sm:grid-cols-2 gap-4'>
                                    <CardArrowText content="A user-friendly platform that generates market opportunities for farmers and industry buyers." />
                                    <CardArrowText content="Tailored product quality verification and logistic services." />
                                    <CardArrowText content="Unlimited access to a global market from anywhere, at anytime." />
                                    <CardArrowText content="A market with only verified buyers and sellers." />
                                </div>
                            <div className='pt-0 w-full sm:w-1/2'>
                                <div className='pt-4 grid grid-cols-1 sm:grid-rows-2 gap-4'>
                                    <CardArrowText content="Transparent and reliable market information, deal creation, and negotiation." />
                                    <CardArrowText content="Integrated and secure platform payment processes." />
                                </div>
                            </div>
                        </div>
                        <div className='sm:hidden'>
                            <ReadMoreReadLess
                                lessContent={<div className='pt-2 text-md sm:text-lg font-normal'>agribazaar drives agricultural transactions between buyer and seller, product quality check options, and end-to-end logistic services.</div>}
                                moreContent={<Fragment>
                                    <div className='pt-2 text-md sm:text-lg font-normal'>agribazaar drives agricultural transactions between buyer and seller, product quality check options, and end-to-end logistic services.</div>
                                    <div className='pt-0'>
                                        <div className='pt-4 grid grid-rows-1 sm:grid-cols-2 gap-4 px-4'>
                                            <CardArrowText content="A user-friendly platform that generates market opportunities for farmers and industry buyers." />
                                            <CardArrowText content="Tailored product quality verification and logistic services." />
                                            <CardArrowText content="Unlimited access to a global market from anywhere, at anytime." />
                                            <CardArrowText content="A market with only verified buyers and sellers." />
                                        </div>
                                    </div>
                                    <div className='pt-0 w-full sm:w-1/2'>
                                        <div className='pt-4 grid grid-cols-1 sm:grid-rows-2 gap-4 px-4'>
                                            <CardArrowText content="Transparent and reliable market information, deal creation, and negotiation." />
                                            <CardArrowText content="Integrated and secure platform payment processes." />
                                        </div>
                                    </div>
                                </Fragment>}
                            />
                        </div>
                        <img className='hidden sm:block pt-4' src={MarketplaceObj.bottomLeftImg} alt="marketplace" />
                    </div>
                    <div id="right" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div>
                            <div className='pt-4 text-xl font-semibold'>Customized contracts as per your requirements.</div>
                            <div className='pt-2 text-md sm:text-md font-normal'>Best-in-class, one-stop solution for farmers / traders / corporates to experience digitalization in agriculture ecosystem.</div>
                        </div>
                            <div className='pt-4 text-xl font-semibold'>Our value proposition is what you need.</div>
                            <div className='pt-2 text-md sm:text-md font-normal'>We offer you the ability to market your crop via our platform effortlessly. All intermediate supply chain stages are covered by agribazaar and our partnership network.</div>
                        <div>
                            <div className='pt-4 text-lg sm:text-xl font-semibold'>What do we offer?</div>
                            <div className='pt-4 px-2'>
                                <DiscloseWSvg svgUrl={MarketplaceObj.disclosuresSVGsList[0]} title="Buying" content={discloseContentBuying()} />
                                <DiscloseWSvg svgUrl={MarketplaceObj.disclosuresSVGsList[1]} title="Selling" content={discloseContentSelling()} />
                                <DiscloseWSvg svgUrl={MarketplaceObj.disclosuresSVGsList[2]} title="Goods & Settlement" content={discloseContentGAS()} />
                            </div>
                        </div>

                        <div className='pt-4 grid grid-cols-1 gap-4'>
                            <CardArrowText content="Farmers can create their trade terms and directly negotiate with buyers on our app. We also take care of transportation to drive higher efficiency in the agri value chain." />
                            <CardArrowText content="We leverage technology to help our buyers track goods at every step of the way." />
                            <CardArrowText content="Our easy-to-use platform is designed to allow buyers to directly negotiate with farmers and realize higher transparency in their supply chain." />
                            <CardArrowText content="Our smallholder farmers can achieve better profits without intermediaries in our transparent agri-trade ecosystem." />
                            <CardArrowText content="Our holistic approach to settlement ensures complete ownership of dispute handling, quick goods delivery, and quality assurance." />
                            <CardArrowText content="We help farmers get instant digital payments based on the quality and quantity of produce." />
                            <CardArrowText content="agribazaar brings secure payments and an integrated stock management mechanism to the fingertips of buyers." />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <OurProductsTemp currentPageTitle={MarketplaceObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} />
        </div>
    );
}

