import React from 'react';
import OurServicesTemp from '../../components/OurServicesTemplate/OurServicesTemp';
import { IOTFarmManagementObj } from "./OurServicesConstants";
import SimpleCardTextImg from "../../components/ReusableGeneric/SimpleCardTextImg";
import DiscloseWText from "../../components/ReusableGeneric/DiscloseWText";

export default function IOTFarmManagement() {
    const headerContent = () => {
        return (
            <div id="top-data" style={{ backgroundImage: 'url(' + IOTFarmManagementObj.topImgUrl + ')' }} className={`w-full relative bg-cover bg-center`}>
                <div className='p-20 text-center flex items-center place-items-center sm:hidden' />
                <div className='py-20 px-6 text-start hidden sm:flex items-start text-white'>
                    <div className='flex flex-col gap-2 p-0'>
                        <p className='font-medium text-5xl'>IoT Based <br /> Farm Management</p>
                        <div className='font-normal text-xl'>An easy-to-use farm management platform <br /> that assists agricultural stakeholders across the value chain.</div>
                    </div>
                </div>
            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div id="body-data" className='px-4 sm:px-8'>
                <div className='sm:hidden'>
                    <div className='text-center flex items-start text-[#3B3939]'>
                        <div className='flex flex-col gap-2 p-0'>
                            <p className='font-semibold text-2xl'>IoT Based Farm Management</p>
                            <div className='pt-2 font-normal text-lg'>An easy-to-use farm management platform that assists agricultural stakeholders across the value chain.</div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col sm:flex-row justify-center items-start gap-10 sm:pl-3 sm:pr-3 text-[#3B3939]'>
                    <div id="left" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div className='grid grid-cols-1 gap-2 items-baseline'>
                            <div className='pt-4 text-xl sm:text-xl font-semibold  sm:h-16'>Simplifying agriculture with intelligent technology</div>
                            <div className='pt-0 text-md sm:text-md font-normal sm:h-32 sm:w-[94%]'>With IoT, analytics, artificial intelligence, machine learning, and other technology offerings, agribazaar can easily streamline your agriculture management efforts. Our farm management software also offers precise weather forecasts and yield estimates.</div>
                            <img src={IOTFarmManagementObj.middleLeftImg} alt="Intelligent technology" className='hidden sm:block h-60 pt-2'/>
                        </div>
                    </div>
                    <div id="right" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div className='grid grid-cols-1 gap-2 items-baseline'>
                            <div className='pt-0 sm:pt-4 text-xl sm:text-xl font-semibold sm:h-16'>Next-gen farm management system</div>
                            <div className='pt-0 text-md sm:text-md font-normal sm:h-32 sm:w-[94%]'>By providing accurate insights into various parameters, agribazaar helps you make data-driven agribusiness decisions.</div>
                            <img src={IOTFarmManagementObj.middleRightImg} alt="Farm management system" className='hidden sm:block h-60 pt-2' />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const bottomContent = () => {
        return (
            <div id="bottom-data" className='px-2 sm:px-8   text-center sm:text-left text-[#3B3939]'>
                <p className='font-semibold text-xl sm:text-2xl px-2'>Features to grow your agribusiness Growth</p>
                <div className='hidden py-6 px-2 text-left sm:grid grid-cols-4 gap-8 items-baseline justify-start text-[#3B3939]'>
                    <SimpleCardTextImg title="Internet of agricultural things" imgUrl={IOTFarmManagementObj.bottomImages[0]} />
                    <SimpleCardTextImg title="Contract farming" imgUrl={IOTFarmManagementObj.bottomImages[1]} />
                    <SimpleCardTextImg title="Farm profile" imgUrl={IOTFarmManagementObj.bottomImages[2]} />
                    <SimpleCardTextImg title="Analytics" imgUrl={IOTFarmManagementObj.bottomImages[3]} />
                </div>
                <div className='hidden py-2 px-2 text-left sm:flex flex-row gap-8  items-baseline justify-start text-[#3B3939]'>
                    <div className='flex w-1/4 font-normal text-sm'>Get critical insights into farm conditions and make well-informed decisions with agribazaar’s IoT expertise. Make optimal use of resources and accelerate agricultural productivity effectively.</div>
                    <div className='flex w-1/4 font-normal text-sm'>Contract Farming deals with farmer registration, creating contracts, procurement, and recording goods issued or services provided to farmers. It includes contract farming companies with crops of their desired quality and ensures improved incomes for farmers.</div>
                    <div className='flex w-1/4 font-normal text-sm'>The farm profile module helps map the crop and land infrastructure of your farms. It allows you to maintain details of farmland, number of sites, plots, packhouses, and cold storages, along with geo coordinates.</div>
                    <div className='flex w-1/4 font-normal text-sm'>agribazaar provides customized reports and dashboards to power your agribusiness decisions with data-based insights. Ensure intelligent yield predictions and bring maintenance costs down to a minimum with our analytics offerings.</div>
                </div>
                <div className='sm:hidden pt-4 px-4'>
                    <DiscloseWText title="Internet of agricultural things" content="Get critical insights into farm conditions and make well-informed decisions with agribazaar’s IoT expertise. Make optimal use of resources and accelerate agricultural productivity effectively." />
                    <DiscloseWText title="Contract farming" content="Contract Farming deals with farmer registration, creating contracts, procurement, and recording goods issued or services provided to farmers. It includes contract farming companies with crops of their desired quality and ensures improved incomes for farmers." />
                    <DiscloseWText title="Farm profile" content="The farm profile module helps map the crop and land infrastructure of your farms. It allows you to maintain details of farmland, number of sites, plots, packhouses, and cold storages, along with geo coordinates." />
                    <DiscloseWText title="Analytics" content="agribazaar provides customized reports and dashboards to power your agribusiness decisions with data-based insights. Ensure intelligent yield predictions and bring maintenance costs down to a minimum with our analytics offerings." />
                </div>
            </div>
        )
    }
    return (
        <div>
            <OurServicesTemp currentPageTitle={IOTFarmManagementObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} bottomContent={bottomContent()} />
        </div>
    );
}