import { ourServicesNavigation } from "../../components/Constants";
import farmerScorecardBg from "../../static/images/homepage/farmerScorecardBG.png"
import farmerScorecardBGCropped from "../../static/images/homepage/farmerScorecardBGCropped.png";
import farmerScoreCardLeftImg from "../../static/images/homepage/farmerScoreCardLeftImg.png";
import farmerScoreCardLeftImg2 from "../../static/images/homepage/farmerScoreCardLeftImg2.png";
import cardimg1 from "../../static/images/homepage/FSicon1.svg";
import cardimg2 from "../../static/images/homepage/FSicon2.svg";
import cardimg3 from "../../static/images/homepage/FSicon3.svg";
import cardimg4 from "../../static/images/homepage/FSicon4.svg";

export const IOTFarmManagementObj = {
    navSeq: ourServicesNavigation[0].name,
    topImgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IotFarmManagement-top.png",
    middleLeftImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IotFarmManagement-ML.png",
    middleRightImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IotFarmManagement-MR.png",
    bottomImages: ["https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IOTFM-B1.png", "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IOTFM-B2.png", "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IOTFM-B3.png", "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IOTFM-B4.png"]
};
export const CropAssessmentObj = {
    navSeq: ourServicesNavigation[1].name,
    topImgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/cropAssessment-top.png",
    middleLeftImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/cropAssessment-ML.png",
    middleRightImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/cropAssessment-MR.png"
};
export const IntelGoodSettlementServicesObj = {
    navSeq: ourServicesNavigation[2].name,
    topImgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IntelliGAS-top.png",
    middleLeftImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IntelliGAS-ML.png",
    middleRightImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IntelliGAS-MR.png"
};
export const InputOutputMarketplaceObj = {
    navSeq: ourServicesNavigation[3].name,
    topImgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IaOMarketplace-top.png",
    middleLeftImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IaOMarketplace-ML.png",
    middleRightImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/IaOMarketplace-MR.png"
};
export const AgriFintechObj = {
    navSeq: ourServicesNavigation[4].name,
    topImgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/agriFintech-top.png",
    middleLeftImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/agriFintech-ML.png",
    middleRightImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/ourServices/agriFintech-MR.png"
};
export const FarmerScoreCardObj = {
    navSeq: ourServicesNavigation[5].name,
    topImgUrl: farmerScorecardBg,
    topImgUrlCropped: farmerScorecardBGCropped,
    middleLeftImg: farmerScoreCardLeftImg,
    middleLeftImg2: farmerScoreCardLeftImg2,
    starInfo: [
        {
            title: "Who’s it for?",
            childs: [
                "Direct utility - For all legal lending institutions to help them make informed decisions about extending loans to farmers",
                "Indirect benefit - For genuine farmers who need speedy funds."
            ]
        },
        {
            title: "What’s it for?",
            childs: [
                "Speedy loan grants to needy farmers",
                "Optimization of cost, time and effort expended by banks validating farmers."
            ]
        },
        {
            title: "How do we know if it will work?",
            childs: [
                "Testified with 4,33,843 geo-tagged farms which served as training data to our model.",
                "GIS-ML based data and predictions."
            ]
        }
    ],
    cards: [
        {
            icon: cardimg1,
            head: "Accuracy and Reliability",
            description: "Meticulously curated reports drawn from reliable data sources and advanced analytics using ML and GIS."
        },
        {
            icon: cardimg2,
            head: "Time and Cost Efficiency",
            description: "Streamlined reports reduce time and resources spent on traditional assessments."
        },
        {
            icon: cardimg3,
            head: "Empowering Financial Inclusion",
            description: "Provides valuable insights into the financial viability of farmers. Promotes financial inclusion and access to credit for the agricultural community."
        },
        {
            icon: cardimg4,
            head: "Data Security",
            description: "Treats all shared information with utmost confidentiality and complies with security protocols."
        }
    ]
    // topImgUrl: "https://abazaar.s3.ap-south-1.amazonaws.com/images/ourServices/farmerScoreCardBG.png"
}