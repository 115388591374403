import React, { Fragment } from 'react';

function DisplayCard({ imgUrl, imgAlt, hrefUrl, title, date, duration }) {
    return (
        <Fragment>
            <div className="block bg-white card-compact  shadow-xl text-center sm:text-left cursor-pointer drop-shadow-md w-80">
                <figure onClick={() => { window.open(hrefUrl, "_blank") }}><img src={imgUrl} alt={imgAlt} width="100%" className='object-cover h-60' /></figure>
                <div className="card-body text-[#3B3939]">
                    <h2 className="card-title text-xl">{title}</h2>
                    <div className='flex flex-row w-full'>
                        <p className='font-medium text-sm text-gray-600 text-left'>{date}</p>
                        <p className='font-medium text-sm text-gray-600 text-right'>{duration}</p>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default DisplayCard;