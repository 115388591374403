import React from 'react';
import OurProductsTemp from '../../components/OurProductsTemplate/OurProductsTemp';
import { EmandiObj } from "./OurProductsConstants";
import CardArrowText from "../../components/ReusableGeneric/CardArrowText";
import DiscloseWSvg from "../../components/ReusableGeneric/DiscloseWSvg";

export default function Emandi() {
    const headerContent = () => {
        return (
            <div id="top-data" style={{ backgroundImage: 'url(' + EmandiObj.topImgUrl + ')' }} className={`h-auto sm:h-64 w-full relative bg-cover bg-center`}  >
                <div className='p-20 text-center flex items-center place-items-center sm:hidden' />
            </div>
        )
    }
    const discloseContent = () => {
        return (
            <div className='py-2 grid grid-cols-1 gap-4 px-2  sm:px-0'>
                <p className='text-lg font-medium'>Because we have a simple & easy to understand e-Mandi model</p>
                <CardArrowText content="agribazaar e-Mandi enables real-time market prices for farmers and provides secure online payments, empowering them to connect with distantly located buyers directly." />
                <CardArrowText content="We provide a simple warehouse-based trading module: A farmer can upload the details of their produce on our online platform, which interested buyers across the country can access." />
                <CardArrowText content="We make post-harvest management practices more transparent, like the agricultural sales process, and reduce farmers’ efforts." />
                <CardArrowText content="We offer farmers access to trade beyond mandi(s) or states to increase consumer access and help get better prices for their agri-products." />
                <CardArrowText content="We ensure seamlessness of trading and payment operations to pull farmers out from cash crunches or payment delays." />
            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div id="body-data" className='px-2 sm:px-4 pb-4'>
                <div className='flex flex-col sm:flex-row justify-center items-start gap-10 sm:pl-3 sm:pr-3 text-[#3B3939]'>
                    <div id="left" className='w-full sm:w-2/4 max-w-2/4 text-center sm:text-left'>
                        <div>
                        <div className='font-bold text-3xl sm:text-4xl m-auto pt-2'>e-Mandi</div>
                            <div className='pt-4 text-lg font-medium sm:text-xl flex flex-col gap-0 sm:gap-2'>
                                <div>10,000+ happy farmers</div>
                                <div>getting the best prices for their produce.</div>
                            </div>
                            <div className='pt-3 sm:pr-32 text-lg sm:text-md font-normal'>Why visit a mandi anymore when mandi can come to you!</div>
                            <div className='pt-4 pl-4 pr-4 sm:pl-2 grid grid-cols-1 sm:gap-4 sm:pr-2'>
                                <CardArrowText content="e-Mandi by agribazaar gives you the best-ever prices for your stock." />
                                <CardArrowText content="Digitalizing traditional mandis, empowering smallholder Indian farmers in their quest for better and transparent prices." />
                                <CardArrowText content="Our e-Mandi platform will enable farmers to showcase their products through their nearby markets and facilitate traders from anywhere to quote prices." />
                            </div>
                            <img className='hidden sm:block pt-12' src={EmandiObj.bottomLeftImg} alt="e-Mandi" />
                        </div>
                    </div>
                    <div id="right" className='w-full sm:w-2/4 max-w-2/4 text-center sm:text-left'>
                        <div className='pt-0 px-2 sm:px-0'>
                            <DiscloseWSvg svgUrl={EmandiObj.disclosureSvg} title="Why should you choose us?" content={discloseContent()} />
                        </div>
                        <div className='sm:pl-4 px-2'>
                            <div className='pt-2 font-medium text-md sm:text-md sm:pr-2'>e-Mandi provides single window services for all APMC related services and information, including commodity arrivals, quality & prices, buy & sell offers & e-payment settlement directly into farmers' accounts, among other benefits.</div>
                            <div className='pt-4 font-medium text-md sm:text-md'>We intend to improve the marketing aspect of the agriculture sector with one license for the entire state and a single point levy, which will turn into a market, and the market fragmentation within the same state will get abolished. And it will improve the supply chain of commodities and reduce wastage.</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <OurProductsTemp currentPageTitle={EmandiObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} />
        </div>
    );
}

