import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';

export default function DiscloseWSvg({ svgUrl, title, content }) {
    return (
        <div className="w-12/12 sm:px-4 pb-6 text-[#3B3939]">
            <div className="p-2 mx-auto rounded-md bg-[#FFFDF9] drop-shadow-md shadow-inner border-2 sm:border-0 border-[#F7D39D]">
                {/* <div className="w-full max-w-md mx-auto bg-white rounded-2xl "> */}
                <Disclosure as="div" className="mt-2">
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="items-center flex justify-between w-full px-4 py-2 text-sm font-medium text-left rounded-lg  focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
                                <div className='flex flex-row items-center place-items-center text-start p-0 gap-4'>
                                    <span className='w-10'> <img src={svgUrl} alt={`${title.slice(0, 10)}`} width="100%" /></span>
                                    <span className='items-start pl-0 text-lg font-bold'>{title}</span>
                                </div>
                                <ChevronUpIcon
                                    className={`${!open ? 'transform rotate-180' : ''
                                        } w-5 h-5 `}
                                />
                            </Disclosure.Button>
                            <Disclosure.Panel className="w-full sm:px-4 pt-4 pb-2 text-sm ">
                                {content}
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
                {/* <Disclosure as="div" className="mt-2">
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="flex items-center justify-between px-4 py-3.5 border-2 border-[#CBCBCB] w-full text-sm font-medium text-left rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
                                <div className='flex flex-row items-center place-items-center text-start p-0 gap-4'>
                                    <span className='w-10'> <img src={svgUrl} alt={`${title.slice(0, 10)}`} width="100%" /></span>
                                    <span className='items-start pl-0 text-lg font-bold'>{title}</span>
                                </div>
                                <ChevronUpIcon
                                    className={`${!open ? 'transform rotate-180' : ''
                                        } w-5 h-5 `}
                                />
                                <Disclosure.Panel className="w-full px-4 pt-4 pb-2 text-md text-[#3B3939]">
                                    {content}
                                </Disclosure.Panel>
                            </Disclosure.Button>
                        </>
                    )}
                </Disclosure> */}
            </div>
        </div>
    );
}

