import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useEffect } from "react";
// import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

export default function MobBanner() {
  const [open, setOpen] = useState();

  const cancelButtonRef = useRef(null);

  const appPackageName = "com.agribazaarapp";
  const isApple = [
    "iPhone",
    "iPad",
    "iPod",
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
  ].includes(navigator.platform);

  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  useEffect(() => {
    const cookieValue = getCookie("open");
    if (cookieValue == "true") {
      setOpen(true);
    } else {
      setOpen(false);
    }
    if (cookieValue == "") {
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    if(open) {
      document.body.classList.add("new-class");
    }
    return(()=>{
      document.body.classList.remove("new-class");
    })
  }, [open])

  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const redirectToPlayStore = () => {
    if (isApple) {
      window.open(
        `https://apps.apple.com/in/app/agribazaar/id1589192238`,
        "_blank"
      );
    } else {
      window.open(
        `https://play.google.com/store/apps/details?id=${appPackageName}`,
        "_blank"
      );
    }
    // document.body.classList.remove("new-class");
    setOpen(false);
    setCookie("open", true, 1);
  };

  const remainInBrowser = () => {
    setOpen(false);
    setCookie("open", false, 1);
  };

  return (
    <div className="z-10 absolute bottom-0 right-0 left-0 ">
      {open && (
        <div className="sm:hidden absolute bottom-0 right-0 h-16 w-16 bg:blur w-full ">
          <div
            tabindex="-1"
            class=" text-[#3B3939] overflow-y-auto overflow-x-hidden fixed backdrop-blur-sm top-0 right-0 left-0 z-50 w-full h-full"
          >
            <div class="relative p-6 pt-16 w-full max-w-7xl h-full">
              <div class="absolute bg-white rounded-lg drop-shadow-2xl bottom-0 right-0 left-0">
                <div class="py-4 px-6 space-y-6 text-center">
                  <div className="text-[#3B3939] pb-1">
                    <h2 className="font-semibold text-xl">
                      Download the app to access all the features!!
                    </h2>
                  </div>
                  <hr className="h-px my-8 bg-gray-200 border-0 bg-gray-300"/>
                  <div className="px-6 py-3 sm:flex sm:flex-row-reverse sm:px-2">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => redirectToPlayStore()}
                    >
                      Download Agribazaar App
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => remainInBrowser()}
                    >
                      Continue in Browser
                    </button>
                  </div>
                </div>
                <div class="flex items-center p-2 space-x-2 rounded-b" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
