import React from 'react';
import OurProductsTemp from '../../components/OurProductsTemplate/OurProductsTemp';
import { KisanCreditCardObj } from "./OurProductsConstants";
import CardArrowText from "../../components/ReusableGeneric/CardArrowText";
import DiscloseWSvg from "../../components/ReusableGeneric/DiscloseWSvg";

export default function KisanCreditCard() {
    const headerContent = () => {
        return (
            <div id="top-data" style={{ backgroundImage: 'url(' + KisanCreditCardObj.topImgUrl + ')' }} className={`h-auto sm:h-64 w-full relative bg-cover bg-center`}  >
                <div className='p-20 text-center flex items-center place-items-center sm:hidden' />
            </div>
        )
    }
    const discloseContentFeatures = () => {
        return (
            <div className='px-2 sm:px-0 py-2 grid grid-cols-1 gap-4'>
                <CardArrowText content="Empower farmers with a personalized bank card suitable for personal or farm financial needs." />
                <CardArrowText content="The card can be universally used at POS or can be used to take out money at ATMs." />
                <CardArrowText content="The card can also be used outside the ecosystem of agribazaar as a standard open-loop card." />
                <CardArrowText content="Both physical and virtual cards for online and offline transactions." />
                <CardArrowText content="Get attractive cashback on using agribazaar Kisan Safalta Card." />
                <CardArrowText content="The disbursement process for the Kisan Safalta loan is hassle-free and quick." />
                <CardArrowText content="The PM KSC loan repayment procedure is flexible and can be done post the harvest season." />
            </div>
        )
    }
    const discloseContentUse = () => {
        const customTextString = (boldText, restString) => (<p><span className='font-bold'>{boldText}:&nbsp;</span>{restString}</p>)
        return (
            <div className='px-2 sm:px-0 py-2 grid grid-cols-1 gap-4'>
                <CardArrowText content={customTextString("For Lending", "A fund wallet that is managed by a financial line given by either bank or NBFC.")} />
                <CardArrowText content={customTextString("For Cashback", "A prepaid wallet where reward points are earned on transactions.")} />
                <CardArrowText content={customTextString("EMI Collection", "UPI-based EMI/loan-repayment collection. In this process, farmers load their EMIs in this wallet which further adds funds to the lender’s bank account.")} />
            </div>
        )
    }
    const discloseContentBenefits = () => {
        return (
            <div className='px-2 sm:px-0 py-2 grid grid-cols-1 gap-4'>
                <CardArrowText content="Farmers receive an ATM card that can be used to withdraw cash from ATMs and take loans as well." />
                <CardArrowText content="KSC comes with a 12 monthly repayment period, which offers adequate time to pay any debt."/>
                <CardArrowText content="The interest rate is similar to that of other advances from the agriculture sector." />
                <CardArrowText content="Crop yield determines the Safalta Card's limit, the finance scale, and maintenance expense." />
                <CardArrowText content="For marginal farmers, the limit is flexible from Rs. 10,000 to Rs. 50,000." />
                <CardArrowText content="The maximum limit of KSC can increase every year after an annual review. Farmers with good repayment histories will be incentivized to tackle inflation with a higher financial limit." />
                <CardArrowText content="It allows for conversion or rescheduling of repayment if the crops are damaged due to any natural calamity. Its repayment policy also allows clearing off the debt only after the harvest." />
                <CardArrowText content="A simplified application and documentation process that eliminates any hassle and helps save time." />

            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div id="body-data" className='px-2 sm:px-4 pb-4'>
                <div className='flex flex-col sm:flex-row justify-center items-start gap-10 sm:pl-3 sm:pr-3 text-[#3B3939]'>
                    <div id="left" className='w-full sm:w-2/4 max-w-2/4 text-center sm:text-left'>
                        <div>
                        <div className='font-bold text-3xl sm:text-4xl m-auto pt-2'>Kisan Safalta Card</div>
                            <div className='pt-4 text-lg sm:text-xl font-medium'>A simple way to make your farming easier and better with agribazaar Kisan Safalta Card.</div>
                            <div className='pt-2 text-md sm:text-lg font-normal'>Kisan Safalta Card (KSC) is a finance delivery mechanism to meet the financial needs of the farmers during the crop season in a timely and hassle-free manner by giving monetary support to them.</div>
                            <img className='hidden sm:block pt-12' src={KisanCreditCardObj.bottomLeftImg} alt="Kisan Safalta Card" />
                        </div>
                    </div>
                    <div id="right" className='w-full sm:w-2/4 max-w-2/4 text-center sm:text-left'>
                        <div className='pt-0 px-2 sm:px-0'>
                            <DiscloseWSvg svgUrl={KisanCreditCardObj.disclosuresSVGsList[0]} title="What are the features of KSC?" content={discloseContentFeatures()} />
                            <DiscloseWSvg svgUrl={KisanCreditCardObj.disclosuresSVGsList[1]} title="How can farmers use KSC?" content={discloseContentUse()} />
                            <DiscloseWSvg svgUrl={KisanCreditCardObj.disclosuresSVGsList[2]} title="What are the benefits?" content={discloseContentBenefits()} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <OurProductsTemp currentPageTitle={KisanCreditCardObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} />
        </div>
    );
}

