import {Link} from 'react-router-dom'
import ErrorImage from "../static/images/homepage/error-image.png"
export default function Error404() {
  return (
    <div className='p-0 overflow-hidden pt-20 sm:pt-40 py-10 px-2 item-center text-center z-0 sm:max-w-[1900px] m-auto w-[100%] font-poppins text-[#3B3939]'>
        <h1 class="font-medium leading-tight text-5xl mt-0 mb-2">{"404"}</h1>
        <h2 className="font-medium leading-tight text-4xl mt-0 mb-2">
          {"Sorry we couldn't find the page you are looking for"}
        </h2>
        <h3 className="font-medium leading-tight text-l mt-0 mb-2">
          {"You may have mis-typed the URL, so please check the spelling. It could also mean that the url<br>does not exist,or maybe it was moved! "}
          <a href="#" >
            <Link className='text-[#0000ff]' to={"/"}>{"Please try again"}</Link>
          </a>
        </h3>
        <div>
          <img src={ErrorImage} alt="Error 404" className="width-100"/>
        </div>
    </div>
  )
}
