import React, { useState } from 'react';
import DisplayCard from "./DisplayCard";

function PaginatedCards({ arrayList, title }) {
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(6);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

    const handleClick = (event) => {
        setcurrentPage(Number(event.target.id));
        window.scrollTo(0, 0);
    }
    const pages = [];
    for (let i = 1; i <= Math.ceil(arrayList.length / itemsPerPage); i++) {
        pages.push(i);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = arrayList.slice(indexOfFirstItem, indexOfLastItem);
    const renderPageNumbers = pages.map(number => {
        if (number <= maxPageNumberLimit && number > minPageNumberLimit) {
            return (
                <a key={number} id={number}
                    className={`px-4 cursor-pointer text-xl ${currentPage === number && 'font-extrabold'} text-blue-900`}
                    onClick={handleClick}
                >
                    {number}
                </a>
            )
        } else
            return null;
    })
    const handleNextBtn = () => {
        setcurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageNumberLimit) {
            setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
        // window.scrollTo(0, 0);
    }
    const handlePrevBtn = () => {
        setcurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit === 0) {
            setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
        window.scrollTo(0, 0);
    }
    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li className='px-4 cursor-pointer bg-transparent text-[#3B3939] text-xl font-semibold' onClick={handleNextBtn}>&hellip;</li>
    }
    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li className='px-4 cursor-pointer bg-transparent text-[#3B3939] text-xl font-semibold' onClick={handlePrevBtn}>&hellip;</li>
    }

    return (
        <div>
            <div className='sm:px-20 grid-rows-1 sm:grid-cols-3 w-12/12 grid gap-20 h-3/4 justify-items-center'>
                {
                    currentItems.map((item, index) => (
                        <DisplayCard key={`_${item.title}_${index}`} imgUrl={item.imgUrl} hrefUrl={item.href} title={item.title} date={item.date} duration={item.duration} imgAlt={item.imgAlt} />
                    ))
                }
            </div>
            <div className='flex justify-center m-auto pt-14 pb-0 h-1/4'>
                <ul className="list-none flex" >
                    <a className={`px-4 cursor-pointer text-[#3B3939] text-lg  ${currentPage === pages[0] && `hidden`}`}>
                        <button
                            className='focus:outline-none font-bold text-blue-800'
                            onClick={handlePrevBtn}
                        // disabled={currentPage === pages[0] ? true : false}
                        >
                            Prev
                        </button>
                    </a>
                    {/* {pageDecrementBtn} */}
                    {renderPageNumbers}
                    {/* {pageIncrementBtn} */}
                    <a className={`px-4 cursor-pointer text-[#3B3939] text-lg ${currentPage === pages[pages.length - 1] && `hidden`}`}>
                        <button
                            className='focus:outline-none font-bold text-blue-800'
                            onClick={handleNextBtn}
                        // disabled={currentPage === pages[pages.length - 1] ? true : false}
                        >
                            Next
                        </button>
                    </a>
                </ul>
            </div>

        </div>
    );
}

export default PaginatedCards;