import React, { useState, useEffect } from 'react';
import Breadcrumb from "../components/ReusableGeneric/Breadcrumb";
import { contactUsList } from "../components/Constants";
import axios from "axios";
import appConfig from '../config';

export default function ContactUs(props) {
    const [checked, setChecked] = useState(false);
    const [isDisableSubmit, setDisableSubmit] = useState(true);
    const [formValues, setFormValues] = useState({
        fname: "",
        lname: "",
        email: "",
        mobile: "",
        subject: "",
        message: ""
    })
    const handleChange = (e) => {
        if (e.target.id !== "agreeChecked") setFormValues({ ...formValues, [e.target.id]: e.target.value });
        formValidator();
    }
    const handleSubmit = () => {
        if (!checked) {
            alert("fill all values and check agreement checkbox before submitting");
        } else {
            submitContactForm();
        }
    }

    const submitContactForm = async () => {
        const loginFormData = new FormData();
        for (const key in formValues) {
            loginFormData.append(`${key}`, `${formValues[key]}`)
        }
        const res = await axios({
            method: "post",
            url: `${appConfig.domain[appConfig.environment]["agribazaar"]}/agribazaarContact.php`,
            data: loginFormData,
            headers: { "Content-Type": "multipart/form-data" }
        })
        if (res.data?.status === 1) {
            alert("submitted successfully");
            resetInputForm();
        } else alert("Error saving data, please try again later.")
    }
    const resetInputForm = () => {
        setFormValues({
            fname: "",
            lname: "",
            email: "",
            mobile: "",
            subject: "",
            message: ""
        });
        setDisableSubmit(true);
        setChecked(false);
    }
    const formValidator = () => {
        if (checked && formValues.fname !== "" && formValues.lname !== "" && formValues.email !== "" && formValues.mobile !== "" && formValues.subject !== "" && formValues.message !== "") setDisableSubmit(false);
        else setDisableSubmit(true);
    }
    useEffect(() => {
        formValidator();
    }, [checked, setChecked])
    const headerContent = () => {
        return (
            <div className='flex flex-col sm:flex-row gap-4 justify-between divide-y-2 sm:divide-y-0 text-black px-2 sm:px-0'>
                <div className="relative overflow-hidden w-full sm:w-1/2">
                    <img src={contactUsList.mumbaiOffcImg} alt="Mumbai office" className="hidden sm:block object-cover w-full h-full" />
                    <div className="sm:absolute w-full py-4 px-4 top-0 inset-x-0 text-[#3B3939] sm:text-white text-xl text-center sm:text-right">
                        <p className='font-medium text-3xl sm:hidden'>Mumbai Office Address</p>
                        <p className='hidden sm:block font-medium text-5xl'>Mumbai Office <br /> Address</p>
                        <div className='float-none sm:float-right pt-2 sm:w-85 font-normal text-lg sm:text-xl px-14 sm:px-0'>802, 8th Floor, Sumer Plaza,<br /> Marol Maroshi Road, Marol Naka, Andheri (E),<br /> Mumbai, Maharashtra, India – 400059</div>
                    </div>
                </div>

                <div className="relative overflow-hidden w-full sm:w-1/2">
                    <img src={contactUsList.delhiOfficeImg} alt="Noida office" className="hidden sm:block object-cover w-full h-full" />
                    <div className="sm:absolute w-full py-4 px-4 top-0 inset-x-0 text-[#3B3939] sm:text-white text-xl text-center sm:text-right leading-4">
                        <p className='font-medium text-3xl sm:hidden'>Noida Office Address</p>
                        <p className='hidden sm:block font-medium text-5xl'>Noida Office <br /> Address</p>
                        <div className='float-none sm:float-right pt-2 sm:w-85 font-normal text-lg sm:text-xl px-14 sm:px-0'>D-18, 4th Floor, Sector-3,<br />  Noida, Uttar Pradesh, India – 201301</div>
                    </div>
                </div>
            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div className="py-8 sm:flex gap-8 w-full h-full justify-around px-2 pr-0">
                <div className='flex  items-center justify-center bg-[#4165b3] w-[98%] rounded-lg px-6 sm:px-0'>
                    <div id="container" className="sm:flex w-[100%] items-center justify-center py-4">
                        <div id="wrapper" className="grid-rows-1 divide-y-2 sm:divide-y-0 sm:grid sm:grid-cols-3 gap-0 align-baseline sm:divide-x-2 text-white">
                            <div className={`place-items-center sm:place-items-start flex flex-col justify-center h-auto px-8 py-4 gap-1`}>
                                <p className='text-xl font-semibold text-left'>Contact Info:</p>
                                <div className="text-center sm:text-left font-medium text-md">
                                    Email : info@agribazaar.com <br /> Contact : +91-9090397777
                                </div>
                            </div>
                            <div className={`place-items-center sm:place-items-start flex flex-col justify-center h-auto px-8 py-4 gap-1`}>
                                <p className='text-xl font-semibold text-left'>General Queries</p>
                                <div className="text-center sm:text-left font-medium text-md">
                                    For further information, comments or suggestions please reach out to us at marketing@agribazaar.com
                                </div>
                            </div>
                            <div className={`place-items-center sm:place-items-start flex flex-col justify-center h-auto px-8 py-4 gap-1`}>
                                <p className='text-xl font-semibold text-left'>Technology</p>
                                <div className="text-center sm:text-left font-medium text-md">
                                    For further information, comments or suggestions please reach out to us at technology@agribazaar.com
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const inputForm = () => {
        return (
            <div className="sm:py-10 sm:px-10 sm:flex flex-col gap-8 w-full h-full items-start justify-center text-[#3B3939] bg-white">
                <div className=" sm:w-[80%] p-6 bg-[#fffdf9]">
                    <form className="sm:space-y-6" action="#" onChange={handleChange} onSubmit={handleSubmit}>
                        <h5 className="text-xl font-medium text-[#3B3939] mb-6 sm:mb-0">CONTACT US</h5>
                        <div className='flex flex-col sm:flex-row sm:gap-32'>
                            <div className="w-full md:w-1/2 sm:mb-6 md:mb-0">
                                <input value={formValues.fname} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-800" id="fname" type="text" placeholder="First Name*" pattern="[a-zA-Z]+" title="only alphabets allowed" />
                            </div>
                            <div className="w-full md:w-1/2">
                                <input value={formValues.lname} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="lname" type="text" placeholder="Last Name*" pattern="[a-zA-Z]+" title="only alphabets allowed" />
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row sm:gap-32'>
                            <div className="w-full md:w-1/2 sm:mb-6 md:mb-0">
                                <input value={formValues.email} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="email" type="email" pattern="^[a-zA-Z0-9_.]+[@]{1}[a-z0-9]+[\.][a-z]+$" title="Enter a valid email id" placeholder="E-mail*" />
                            </div>
                            <div className="w-full md:w-1/2">
                                <input value={formValues.mobile} maxLength="10" className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="mobile" type="tel" pattern="[6-9]{1}[0-9]{9}" title="Enter a valid mobile number" placeholder="Phone Number*" />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-3 sm:mb-6">
                            <div className="w-full px-3">
                                <input value={formValues.subject} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 sm:mb-3 leading-tight focus:outline-none focus:bg-white" id="subject" type="text" placeholder="Subject" />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full px-3">
                                <textarea value={formValues.message} rows="6" className="col-span-4 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="message" type="text" placeholder="Message" />
                            </div>
                        </div>
                        <div className="flex flex-wrap">
                            <div className="flex  font-bold">
                                <input id="agreeChecked" checked={checked} onClick={() => setChecked(!checked)} className="mr-3 leading-tight outline-none focus:outline-none focus:border-none bg-gray-200 border-black focus:ring-3 focus:ring-white ring-white" type="checkbox" />
                                <div className="text-md font-medium -mt-1">
                                    I agree to the storage of personal information provided above, and consent to agribazaar representative to contact me.
                                    <p className="pt-4 sm:pt-2 text-gray-800 text-xs font-light">By clicking on the button you consent to receive communications from agribazaar.</p>
                                </div>
                            </div>
                        </div>
                        <div className="w-1/2 sm:w-1/6 pt-8 sm:pt-0">
                            <input
                                className={`${isDisableSubmit && 'cursor-not-allowed'}  w-full shadow bg-red-600 hover:bg-red-700 focus:shadow-outline focus:outline-none text-white font-bold py-3 sm:py-2 px-4 rounded`}
                                type="submit"
                                disabled={isDisableSubmit}
                            />
                        </div>
                    </form>
                </div>

            </div>
        )
    }
    return (
        <div className='p-0 overflow-hidden pt-20 sm:pt-32 z-0 bg-white sm:max-w-[1900px] m-auto w-[100%] font-poppins'>
            <Breadcrumb parent="Contact us" />
            {headerContent()}
            {bodyContent()}
            {inputForm()}
        </div>
    );
}