import { careers } from "../../components/Constants";

export const whyagribazaarObj = {
    navSeq: careers[0].name,
    topLeftImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/careers/whyagribazaar-TL.png",
    topRightImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/careers/whyagribazaar-TR.png",
    bottomImg: ""
}
export const peoplephilosophyObj = {
    navSeq: careers[1].name,
    topLeftImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/careers/people-philosophy-TL.png",
    topRightImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/careers/people-philosophy-TR.png",
    bottomImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/careers/people-philosophy-bottomSVG.svg",
    harvestSvgsList: [
        {
            svgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/careers/people-philosophy-harvest-H.svg",
            heading: "H-onesty",
            content: "Being transparent and open in all our interactions and decision-making."
        },
        {
            svgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/careers/people-philosophy-harvest-A.svg",
            heading: "A-gility",
            content: "Leveraging technological advancements for increased efficiency, transparency and traceability in agriculture."
        },
        {
            svgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/careers/people-philosophy-harvest-R.svg",
            heading: "R-esponsibility",
            content: "Being responsible to our stakeholders and the environment."
        },
        {
            svgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/careers/people-philosophy-harvest-V.svg",
            heading: "V-ision",
            content: "Staying true to our vision of empowering farmers and transforming agriculture through technology."
        },
        {
            svgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/careers/people-philosophy-harvest-E.svg",
            heading: "E-nergy",
            content: "Being energetic and entrepreneurial about making a measurable impact through our work."
        },
        {
            svgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/careers/people-philosophy-harvest-S.svg",
            heading: "S-ynergy",
            content: "Working together in synergy so that the whole is greater than the sum of its parts."
        },
        {
            svgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/careers/people-philosophy-harvest-T.svg",
            heading: "T-rust",
            content: "Walking our talk, keeping our promises and aligning our behavior with our values."
        },
    ]
}
export const careerOpportunitiesObj = {
    navSeq: careers[2].name,
    topImgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/careers/careerOpportunitiesTop.png",
    topTitle: "Join our growing team and help shape the future of agriculture.",
    departmentsList: [
        "Engineering",
        "Sales & Marketing",
        "AI & Data Science",
        "Product Team",
        "Marketing",
        "Customer Success"
    ],
    LocationList: [
        "Andhra Pradesh",
        "Bihar",
        "Chattisgarh",
        "Delhi",
        "Gujarat",
        "Haryana",
        "Madhya Pradesh",
        "Maharashtra",
        "Orissa",
        "Punjab",
        "Rajasthan",
        "Tamil Nadu",
        "Telangana",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal",
        "Himachal Pradesh",
        "Puducherry",
        "Andaman And Nicobar",
        "Arunachal Pradesh",
        "Assam",
        "Chandigarh",
        "Dadra and Nagar Haveli and Daman and Diu",
        "Goa",
        "Jammu Kashmir",
        "Jharkhand",
        "Kerala",
        "Lakshadweep",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Sikkim",
        "Tripura",
        "Karnataka",
        "Ladakh",
        "UAT State"
    ],
    WorkTypeList: [
        "Full Time",
        "Contract",
        "Remote",
        "Work From Home"
    ]
}