import React, { useState } from 'react';

function ReadMoreReadLess({ lessContent, moreContent }) {
    const [isParaShort, setisParaShort] = useState(false);
    const linkName = isParaShort ? 'Show less' : 'Continue reading...'
    return (
        <div>
            {isParaShort ? moreContent : lessContent}
            <div className='pt-2'>
                <a className="text-[#4165b3] m-auto font-normal not-italic text-lg" onClick={() => { setisParaShort(!isParaShort) }}><h2>{linkName}</h2></a>
            </div>
        </div>
    );
}

export default ReadMoreReadLess;