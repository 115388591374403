import React, { useEffect, useState } from 'react';

export default function InputMarketplaceStoreHippo(props) {

    useEffect(() => {
        var header = document.getElementById("header");
        var footer = document.getElementById("footer");
        header.remove();
        footer.remove();
    }, [])

    return (
        <div id="iframe-content" className='p-0 overflow-hidden z-0 bg-white  sm:max-w-[1900px] m-auto w-[100%] font-poppins'>
            <div dangerouslySetInnerHTML={{
                __html:
                    "<iframe src='https://agribazaar.storehippo.com/'  style='overflow:hidden;height:100vh;width:100%' height='100%' width='100%' frameborder='0'/>"
            }} />
        </div>
    );
}
