import React from 'react';

const redArrowRight = () => {
    return (
        <svg width="23" height="10" viewBox="0 0 23 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2922 0.067362C15.7813 0.24688 15.5414 0.731853 15.7739 1.11531C15.8343 1.215 16.4723 1.73467 17.6358 2.63196C18.6093 3.38269 19.4058 4.00979 19.4058 4.02556C19.4058 4.0413 15.2065 4.05419 10.074 4.05419H0.742335L0.526228 4.14243C0.193882 4.27815 0.0226835 4.49449 0.00214168 4.80476C-0.018155 5.11132 0.105145 5.33012 0.400526 5.51195L0.588933 5.62792L9.99734 5.6377C15.172 5.64305 19.4058 5.66023 19.4058 5.67581C19.4058 5.6914 18.6003 6.32692 17.6158 7.08807C15.6838 8.58185 15.642 8.62254 15.6915 8.96228C15.7245 9.1887 15.935 9.45586 16.1785 9.5805C16.4524 9.72074 16.9051 9.74546 17.1791 9.63515C17.4228 9.53705 22.7958 5.37434 22.9143 5.19182C23.0349 5.00612 23.0272 4.68194 22.8976 4.49028C22.7477 4.26845 17.3756 0.130121 17.1399 0.054848C16.8968 -0.0228144 16.5335 -0.0174295 16.2922 0.067362Z" fill="#ED1849" />
        </svg>

    )
}
export default function CardArrowText({ content }) {
    return (
        <div class="card-body p-0 sm:p-2 flex flex-row items-start place-items-center text-left">
            <div className='mt-2'>{redArrowRight()}</div>
            <p className='items-start text-start text-[#3B3939] text-md font-normal'>{content}</p>
        </div>
    );
}