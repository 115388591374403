import React from 'react';
import AboutAgribazaarTemp from '../../components/AboutAgribazaarTemplate/AboutAgribazaarTemp';
import { ourStoryObj } from "./AboutAgriConstants";
import ReadMoreReadLess from "../../components/ReusableGeneric/ReadMoreReadLess";

export default function OurStory(props) {
    const headerContent = () => {
        return (
            <div className='flex flex-col sm:flex-row gap-12 sm:gap-4 justify-between'>
                <div className="wrapper antialiased text-gray-900 w-full sm:w-1/2">
                    <div>
                        <img src={ourStoryObj.topLeftImg} alt="Empowering millions to feed billions" className="bg-contain w-full h-56 sm:h-72 object-center  shadow-md" />
                        <div className="relative px-4 -mt-16  flex justify-center text-center sm:text-left">
                            <div className="bg-white p-4 w-96 sm:h-80 shadow-lg border-4">
                                <h4 className=" text-2xl font-bold uppercase leading-tight text-[#ed1849]">Empowering millions to feed billions</h4>
                                <div className="hidden font-normal sm:block pt-2 text-sm italic text-[#696969] space-y-4">
                                    <p>At Agribazaar, we’re transforming the agri value chain through technology. For small farmowners, we enable digital access to a wider marketplace. For buyers, we assure quality whilst mitigating counterparty risk and enabling secure payments.</p>
                                    <p >Across the value chain, through direct linkages we bring efficiency, transparency, and traceability like never before.</p>
                                </div>
                                <div className="sm:hidden font-normal pt-2 text-sm italic text-[#696969] space-y-4">
                                    <ReadMoreReadLess
                                        lessContent={<p>At Agribazaar, we’re transforming the agri value chain through technology.</p>}
                                        moreContent={<div>
                                            <p>At Agribazaar, we’re transforming the agri value chain through technology. For small farmowners, we enable digital access to a wider marketplace. For buyers, we assure quality whilst mitigating counterparty risk and enabling secure payments.</p>
                                            <p>Across the value chain, through direct linkages we bring efficiency, transparency, and traceability like never before.</p>
                                        </div>}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper  antialiased text-gray-900  w-full sm:w-1/2">
                    <div>
                        <img src={ourStoryObj.topRightImg} alt="feeding 9.8 billion people by 2050" className="bg-contain w-full h-56 sm:h-72  object-center shadow-md " />
                        <div className="relative px-4 -mt-16  flex justify-center text-center sm:text-left">
                            <div className="bg-white p-4 w-96 sm:h-80 shadow-lg border-4">

                                <h4 className=" text-2xl font-bold uppercase leading-tight  text-[#ed1849]">Feeding 9.8 billion people by 2050</h4>

                                <div className="pt-2 text-md italic text-[#696969]">
                                    <p>We’re on a mission to empower small farmowners and enhance livelihoods. This is the key to addressing the global food security challenge:</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div className='w-full px-0'>
                <div className="hidden sm:flex items-center w-full px-4 py-10 bg-cover card" style={{ backgroundImage: 'url(' + ourStoryObj.firstParaImg + ')', backgroundSize: "cover" }}>
                    <div className='flex flex-col items-center text-center py-4'>
                        <div className='text-[#696969] text-xl px-52'>
                            <p>With over 150,000 users, 37,000+ listed auctions and over INR 30,000 crore GMV as on June, 2024 on our digital platform, we’re disrupting conventional agri-trading. Artificial Intelligence (AI), Machine Learning (ML), Big Data, Internet of Things (IoT) and drones are the tools in our mission.</p>
                        </div>
                        <div className='py-10'>
                            <div className="card glass lg:card-side text-neutral-content rounded-none bg-contain">
                                <div style={{ backgroundImage: 'url(' + ourStoryObj.secondParaImg + ')', backgroundSize: "contain" }}
                                    className="bg-inherit  max-w-5xl h-fit px-10 card-body py-12" >
                                    <p className='px-12 w-fit font-normal text-[#3B3939] text-4xl text-center justify-center'>We aspire to become India’s largest E-Mandi <span className='text-[#ed1849] font-bold'>ONLINE AGRI-MARKETPLACE</span> and replicate it across global markets.</p>
                                </div>
                            </div>
                        </div>
                        <div className='text-[#707070] text-xl px-52'>
                            <p>In 2016, we started out by building an online platform to connect farmers, traders, banks, enterprises and governments. In 2017, we created an award-winning wallet - AgriPay - that has facilitated transactions of over $1 billion. Today, we’re leveraging AI-driven insights and analytics to offer customized credit-on-a-click, crop advisory and more.</p>
                        </div>
                    </div>
                </div>
                <div className="flex items-center w-full bg-cover card sm:hidden">
                    <div className='flex flex-col items-center text-center py-4'>
                        <div className='px-4 text-[#707070] text-lg  text-center'>
                            <ReadMoreReadLess
                                lessContent={<p>With over 1 lakh users, 33,000 listed auctions and over INR 14000 crore GMV as on June, 2020 on our digital platform, we’re disrupting conventional agri-trading.</p>}
                                moreContent={<p>With over 1 lakh users, 33,000 listed auctions and over INR 14000 crore GMV as on June, 2020 on our digital platform, we’re disrupting conventional agri-trading. Artificial Intelligence (AI), Machine Learning (ML), Big Data, Internet of Things (IoT) and drones are the tools in our mission.</p>}
                            />
                        </div>
                        <div className='py-6 px-4'>
                            <div className='py-0' style={{ backgroundImage: 'url(' + ourStoryObj.secondParaImg + ')', backgroundSize: "cover" }}>
                                <p className='px-12 py-4 w-full text-[#3B3939] text-md font-semibold text-center justify-center'>We aspire to become India’s largest E-Mandi <span className='text-[#ed1849] font-bold'>ONLINE AGRI-MARKETPLACE</span> and replicate it across global markets.</p>
                            </div>
                        </div>
                        <div className='px-4 text-[#707070] text-lg text-center'>
                            <ReadMoreReadLess
                                lessContent={<p>In 2016, we started out by building an online platform to connect farmers, traders, banks, enterprises and governments.</p>}
                                moreContent={<p>In 2016, we started out by building an online platform to connect farmers, traders, banks, enterprises and governments. In 2017, we created an award-winning wallet - AgriPay - that has facilitated transactions of over $1 billion. Today, we’re leveraging AI-driven insights and analytics to offer customized credit-on-a-click, crop advisory and more.</p>}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <AboutAgribazaarTemp currentPageTitle={ourStoryObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} />
    );
}
