import React, { Fragment } from 'react';
import OurProductsTemp from '../../components/OurProductsTemplate/OurProductsTemp';
import { AgribhumiObj } from "./OurProductsConstants";
import CardArrowText from "../../components/ReusableGeneric/CardArrowText";
import ReadMoreReadLess from "../../components/ReusableGeneric/ReadMoreReadLess";


export default function Agribhumi() {
    const headerContent = () => {
        return (
            <div id="top-data" style={{ backgroundImage: 'url(' + AgribhumiObj.topImgUrl + ')' }} className={`h-auto sm:h-64 w-full relative bg-cover bg-center`} >
                <div className='p-20 text-center flex items-center place-items-center sm:hidden' />
            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div id="body-data" className='px-2 sm:px-4 pb-4'>
                <div className='flex flex-col sm:flex-row justify-center items-start gap-6 sm:gap-10 pl-3 pr-3 text-[#3B3939]'>
                    <div id="left" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div className='font-bold text-3xl sm:text-4xl m-auto pt-2'>AgriBhumi</div>
                        <div className='pt-4 text-lg sm:text-xl font-medium'>Power of satellite imagery and machine learning taking Indian agriculture ecosystem to new frontiers!</div>
                        <div className='hidden sm:block'>
                            <div className='pt-2 text-md sm:text-md font-normal'>Our team has developed a crop yield modeling and forecasting methodology using machine learning techniques. Crop yield forecasting is required across regions, and farms, hence it is based on Earth observation satellite data.</div>
                            <div className='pt-2'>
                                <div className='pl-4 sm:pl-0 pt-0 sm:pt-4 grid grid-rows-1 sm:grid-cols-2 gap-4'>
                                    <CardArrowText content="Land cover classification" />
                                    <CardArrowText content="Fields boundaries delineation" />
                                    <CardArrowText content="Arable / non-arable land identification" />
                                    <CardArrowText content="Actual acreage estimation for each crop type" />
                                    <CardArrowText content="Crop type identification" />
                                    <CardArrowText content="Crop rotation historical data" />
                                </div>
                            </div>
                        </div>
                        <div className='sm:hidden'>
                            <ReadMoreReadLess
                                lessContent={<div className='pt-2 text-md sm:text-lg'>Our team has developed a crop yield modeling and forecasting methodology using machine learning techniques. Crop yield forecasting is required across regions, and farms, hence it is based on Earth observation satellite data.</div>}
                                moreContent={
                                    <Fragment>
                                        <div className='pt-2 text-md sm:text-lg'>Our team has developed a crop yield modeling and forecasting methodology using machine learning techniques. Crop yield forecasting is required across regions, and farms, hence it is based on Earth observation satellite data.</div>
                                        <div className='pt-4'>
                                            <div className='pl-4 sm:pl-0 pt-0 sm:pt-4 grid grid-rows-1 sm:grid-cols-2 gap-4'>
                                                <CardArrowText content="Land cover classification" />
                                                <CardArrowText content="Fields boundaries delineation" />
                                                <CardArrowText content="Arable / non-arable land identification" />
                                                <CardArrowText content="Actual acreage estimation for each crop type" />
                                                <CardArrowText content="Crop type identification" />
                                                <CardArrowText content="Crop rotation historical data" />
                                            </div>
                                        </div>
                                    </Fragment>
                                }
                            />
                        </div>
                        <img className='hidden sm:block pt-10' src={AgribhumiObj.bottomLeftImg} alt="agribhumi crop field" />
                    </div>
                    <div id="right" className='w-full sm:w-2/4 text-center sm:text-left'>
                        <div>
                            <div className='pt-4 text-xl font-semibold'>Monitor fields, reduce costs, improve yields.</div>
                            <div className='pt-2 text-md sm:text-md font-normal'>At agribazaar, we fuse space technologies with human determination for sustainability on Earth. Our focus is GIS precision farming as the shared strategy to save time and resources, cut costs and losses, make a profit and an impact.</div>
                        </div>
                        <div className='pt-3'>
                            <div className='pt-4 text-xl font-semibold'>Utilizing our agritech solutions will always keep you a step ahead.</div>
                            <div className='pt-2 text-md sm:text-md font-normal'>Precisely estimate crop performance and when the crop is ready to be harvested with our automatic stages of plant growth models.</div>
                        </div>
                        <div className='pt-3'>
                            <div className='pt-4 text-xl font-semibold'>Manage your farms effortlessly & efficiently.</div>
                            <div className='pt-2 text-md sm:text-md font-normal'>Monitor the fields remotely, reveal issues on the spot, and act timely with top-notch software for farming.</div>
                        </div>
                        <div className='pl-4 sm:pl-0 pt-4 grid grid-cols-1 gap-4'>
                            <CardArrowText content="Near real-time change detection displayed on our field leaderboard 24/7." />
                            <CardArrowText content="Timely data-driven decisions using remote problem area detection and precision scouting." />
                            <CardArrowText content="Efficient planning across all of your fields with an advanced field activity log." />
                            <CardArrowText content="In-depth field state analytics with the agriculture weather forecast, vegetation indices, stages of plant growth, among other key factors." />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <OurProductsTemp currentPageTitle={AgribhumiObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} />
        </div>
    );
}

