import React from 'react';
import AgriInsightsTemp from '../../components/AgriInsightsTemplate/AgriInsightsTemp';
import { agriInsightsObj } from "./AgriInsightsConstants";
import PaginatedCards from "../../components/ReusableGeneric/PaginatedCards";

export default function AgriInsights() {
    const headerContent = () => {
        return (
            <div className='text-[#3B3939] font-bold text-3xl sm:text-4xl text-center sm:text-left px-5 sm:px-24 pt-6 sm:pt-10 pb-0 sm:pb-4'>
                <p>Commodity Outlook</p>
            </div>
        )
    }
    const bodyContent = () => {
        return (
            <div id="body-data" className='w-full pt-8 pb-12'>
            <div id="wrapper" className='px-12 sm:px-8 items-center justify-center'>
                     <PaginatedCards arrayList={agriInsightsObj.cardsList} />
                </div>
            </div>
        )
    }
    return (
        <div>
            <AgriInsightsTemp currentPageTitle={agriInsightsObj.navSeq} headerContent={headerContent()} bodyContent={bodyContent()} />
        </div>
    );
}

